import type { GroupBase, Props } from "react-select";
import Creatable from "react-select/creatable";

export const SelectCreatable = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: Props<Option, IsMulti, Group>
) => {
  return (
    <Creatable
      classNames={{
        control: () =>
          "border !border-divider-main rounded-md h-12 whitespace-nowrap overflow-hidden"
      }}
      {...props}
    />
  );
};
