import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

import { getAuth } from "~/providers/store/auth";
import { client, getAuthHeader } from "~/api/client";
import { productsKeys } from "~/api/products/keys";

export const suppliersKeys = createQueryKeys("suppliers", {
  detail: null
});

export function useSupplier() {
  return useQuery({
    ...suppliersKeys.detail,
    queryFn: async () => {
      const { partnerId, schoolId, status } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const res = await client.v1.pos.suppliers.get({
        query: {
          partnerId,
          schoolId
        },
        headers: getAuthHeader()
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    }
  });
}

export function useSuppliersGenerateColors() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ hasProductColors }: { hasProductColors: boolean }) => {
      const { partnerId, schoolId, moduleId, status } = getAuth();
      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      if (hasProductColors) {
        const res = await client.v1.pos.suppliers.colors({
          body: {
            partnerId,
            schoolId,
            moduleId
          },
          headers: getAuthHeader()
        });

        if (res.status === 204) {
          return;
        }

        throw res;
      }
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: productsKeys.lists()
      });
    }
  });
}
