import { clsx } from "clsx";

type KanplaLogoProps = {
  onlyIcon?: boolean;
  className?: string;
  variant?: "light" | "dark";
};
export const KanplaLogoNew = ({
  onlyIcon = false,
  className,
  variant = "light"
}: KanplaLogoProps) => {
  return (
    <>
      <style>
        {`
  @keyframes reveal {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  @keyframes popReveal {
    0% {
      opacity: 0;
      transform: scale(.85)
    }
    70% {
      opacity: .8;
      transform: scale(1.05)
    }
    100% {
      opacity: 1;
      transform: scale(1)
    }
  }
  
  #logo_kanpla_letter_1 {
    opacity: 0;
    animation: reveal .5s 0s ease forwards;
  }
  
  #logo_kanpla_letter_2 {
    opacity: 0;
    animation: reveal .55s .05s ease forwards;
  }
  
  #logo_kanpla_letter_3 {
    opacity: 0;
    animation: reveal .6s .1s ease forwards;
  }
  
  #logo_kanpla_letter_4 {
    opacity: 0;
    animation: reveal .65s .15s ease forwards;
  }
  
  #logo_kanpla_letter_5 {
    opacity: 0;
    animation: reveal .7s .2s ease forwards;
  }
  
  #logo_kanpla_letter_6 {
    opacity: 0;
    animation: reveal .75s .25s ease forwards;
  }
  
  
  #logo_kanpla_fork {
    opacity: 0;
    animation: popReveal .4s 0s ease forwards;
    transform-origin: center left;
  }
  
  #logo_kanpla_knife {
    opacity: 0;
    animation: popReveal .5s 0s ease forwards;
    transform-origin: center left;
  }
  
  @media print {
    #logo_kanpla_letter_1,
    #logo_kanpla_letter_2,
    #logo_kanpla_letter_3,
    #logo_kanpla_letter_4,
    #logo_kanpla_letter_5,
    #logo_kanpla_letter_6,
    #logo_kanpla_fork,
    #logo_kanpla_knife {
      opacity: 1;
      animation: none;
    }
  }
  `}
      </style>
      <svg
        className={clsx(
          "h-auto max-h-full w-full max-w-full overflow-visible object-contain",
          className
        )}
        viewBox={onlyIcon ? "0 0 307 263" : "0 0 590 136"}
        xmlns="http://www.w3.org/2000/svg"
        fill={variant === "light" ? "white" : "#082D1D"}
      >
        {!onlyIcon && (
          <>
            <path
              className="primary"
              d="M169.268 103.291V3.04664C169.268 2.20015 169.957 1.50757 170.8 1.50757H188.959C189.802 1.50757 190.492 2.20015 190.492 3.04664V61.0952H191.743L213.605 34.0845C213.886 33.7254 214.32 33.5202 214.78 33.5202H236.106C237.409 33.5202 238.124 35.0593 237.281 36.0597L210.311 67.7902L238.098 102.317C238.915 103.317 238.2 104.805 236.898 104.805H215.214C214.729 104.805 214.295 104.574 213.988 104.189L191.717 74.5877H190.466V103.266C190.466 104.112 189.776 104.805 188.934 104.805H170.775C169.932 104.805 169.242 104.112 169.242 103.266L169.268 103.291Z"
              id="logo_kanpla_letter_1"
            />
            <path
              className="primary"
              d="M266.039 106.523C258.735 106.523 252.835 104.702 248.365 101.034C243.896 97.366 241.648 92.3897 241.648 86.0539C241.648 79.3589 244.432 73.9978 250.026 69.8936C255.619 65.8151 263.996 63.0704 275.183 61.6853L290.021 60.0179V56.9654C290.021 53.5282 288.923 50.8092 286.752 48.8084C284.581 46.8076 281.542 45.8072 277.66 45.8072C273.778 45.8072 270.841 46.7819 268.568 48.7314C266.627 50.3988 265.554 52.6304 265.35 55.3751C265.299 56.1703 264.634 56.7859 263.817 56.7859H246.373C245.505 56.7859 244.79 56.042 244.841 55.1699C245.096 50.7322 246.45 46.8076 248.876 43.3703C251.609 39.5227 255.44 36.5215 260.395 34.3924C265.35 32.2634 271.147 31.186 277.788 31.186C284.428 31.186 290.277 32.289 295.334 34.5207C300.391 36.7523 304.247 39.8305 306.98 43.7808C309.713 47.731 311.066 52.3482 311.066 57.6324V103.163C311.066 104.01 310.377 104.702 309.534 104.702H296.994C293.112 104.702 289.996 101.547 289.996 97.6738V95.673H288.617C286.497 99.2129 283.458 101.906 279.524 103.753C275.591 105.6 271.096 106.549 266.014 106.549L266.039 106.523ZM273.242 92.7232C276.485 92.7232 279.371 92.0819 281.9 90.7737C284.428 89.4655 286.42 87.6442 287.85 85.2843C289.281 82.9244 289.996 80.308 289.996 77.4094V72.5357L275.847 74.3569C271.122 74.9213 267.648 76.0756 265.375 77.8455C263.102 79.6154 261.978 81.7445 261.978 84.2583C261.978 86.7721 263 88.9268 265.018 90.4659C267.061 92.0049 269.768 92.7745 273.19 92.7745L273.242 92.7232Z"
              id="logo_kanpla_letter_2"
            />
            <path
              className="primary"
              d="M322.738 103.291V35.085C322.738 34.2385 323.428 33.5459 324.271 33.5459H336.658C340.54 33.5459 343.656 36.701 343.656 40.5744V43.5499H345.188C347.768 39.4714 350.781 36.4445 354.204 34.495C357.626 32.5455 361.457 31.5708 365.722 31.5708C370.907 31.5708 375.53 32.8277 379.591 35.3415C383.651 37.8553 386.793 41.3182 389.015 45.7815C391.237 50.2448 392.335 55.2981 392.335 60.967V103.291C392.335 104.138 391.646 104.831 390.803 104.831H372.797C371.954 104.831 371.265 104.138 371.265 103.291V64.0195C371.265 59.7614 370.115 56.2472 367.791 53.5025C365.467 50.7579 362.3 49.3984 358.214 49.3984C355.634 49.3984 353.208 50.0653 350.935 51.4248C348.662 52.7587 346.899 54.6055 345.597 56.9141C344.294 59.2484 343.656 61.8392 343.656 64.7121V103.266C343.656 104.112 342.966 104.805 342.123 104.805H324.245C323.402 104.805 322.713 104.112 322.713 103.266L322.738 103.291Z"
              id="logo_kanpla_letter_3"
            />
            <path
              className="primary"
              d="M448.625 31.4426C455.087 31.4426 460.859 33.033 465.89 36.1881C470.922 39.3432 474.855 43.7552 477.664 49.4241C480.474 55.093 481.904 61.6853 481.904 69.2011C481.904 76.7169 480.474 83.2067 477.613 88.9012C474.753 94.6214 470.794 99.0591 465.762 102.265C460.731 105.472 455.01 107.062 448.651 107.062C443.747 107.062 439.431 106.113 435.676 104.215C431.922 102.317 428.806 99.5977 426.329 96.0579H424.949V135.33C424.949 136.176 424.26 136.869 423.417 136.869H405.411C404.568 136.869 403.879 136.176 403.879 135.33V35.0851C403.879 34.2386 404.568 33.546 405.411 33.546H417.951C421.834 33.546 424.949 36.7011 424.949 40.5744V42.447H426.329C428.832 38.9071 431.922 36.2137 435.625 34.2899C439.328 32.3917 443.67 31.4426 448.651 31.4426H448.625ZM442.368 49.2702C438.945 49.2702 435.804 50.1423 432.943 51.9122C430.083 53.6822 427.887 56.0677 426.354 59.0946C424.822 62.1214 424.056 65.4817 424.056 69.2011C424.056 72.9206 424.822 76.1526 426.354 79.1538C427.887 82.1806 430.083 84.5662 432.943 86.3361C435.804 88.106 438.945 88.9782 442.368 88.9782C445.79 88.9782 449.034 88.106 451.869 86.3361C454.704 84.5662 456.875 82.1806 458.458 79.1538C460.041 76.1269 460.808 72.8179 460.808 69.2011C460.808 65.5843 459.99 62.1214 458.381 59.0946C456.772 56.0677 454.576 53.6822 451.792 51.9122C449.008 50.1423 445.867 49.2702 442.368 49.2702Z"
              id="logo_kanpla_letter_4"
            />
            <path
              className="primary"
              d="M491.303 103.291V3.04664C491.303 2.20015 491.992 1.50757 492.835 1.50757H510.841C511.684 1.50757 512.373 2.20015 512.373 3.04664V103.291C512.373 104.138 511.684 104.83 510.841 104.83H492.835C491.992 104.83 491.303 104.138 491.303 103.291Z"
              id="logo_kanpla_letter_5"
            />
            <path
              className="primary"
              d="M544.961 106.523C537.657 106.523 531.757 104.702 527.287 101.034C522.792 97.366 520.57 92.3897 520.57 86.0539C520.57 79.3589 523.354 73.9978 528.947 69.8936C534.541 65.8151 542.918 63.0704 554.104 61.6853L568.943 60.0179V56.9654C568.943 53.5282 567.845 50.8092 565.674 48.8084C563.503 46.8076 560.464 45.8072 556.582 45.8072C552.7 45.8072 549.763 46.7819 547.49 48.7314C545.548 50.3988 544.476 52.6304 544.271 55.3751C544.22 56.1703 543.556 56.7859 542.739 56.7859H525.295C524.427 56.7859 523.712 56.042 523.763 55.1699C524.018 50.7322 525.372 46.8076 527.798 43.3703C530.531 39.5227 534.362 36.5215 539.317 34.3924C544.271 32.2634 550.069 31.186 556.709 31.186C563.35 31.186 569.199 32.289 574.255 34.5207C579.312 36.7523 583.169 39.8305 585.902 43.7808C588.635 47.731 589.988 52.3482 589.988 57.6324V103.163C589.988 104.01 589.299 104.702 588.456 104.702H575.916C572.034 104.702 568.918 101.547 568.918 97.6738V95.673H567.538C565.419 99.2129 562.379 101.906 558.446 103.753C554.513 105.6 550.018 106.549 544.936 106.549L544.961 106.523ZM552.163 92.7232C555.407 92.7232 558.293 92.0819 560.821 90.7737C563.35 89.4655 565.342 87.6442 566.772 85.2843C568.203 82.9244 568.918 80.308 568.918 77.4094V72.5357L554.768 74.3569C550.044 74.9213 546.57 76.0756 544.297 77.8455C542.024 79.6154 540.9 81.7445 540.9 84.2583C540.9 86.7721 541.922 88.9268 543.939 90.4659C545.983 92.0049 548.69 92.7745 552.112 92.7745L552.163 92.7232Z"
              id="logo_kanpla_letter_6"
            />
          </>
        )}

        <path
          className="primary"
          d="M60.0547 106.879C58.9102 93.8752 56.7849 81.6611 58.8568 55.5362C57.3284 44.2482 54.7493 26.2448 52.6508 13.7419C51.0384 1.23905 44.6371 0.395854 41.3557 1.25407C40.9585 1.35786 38.8644 1.55885 38.8644 3.51112C39.1814 16.6882 42.067 39.0879 44.0437 57.4706C44.1778 57.9239 43.333 59.8989 41.8727 59.9953C38.8089 60.1977 36.9368 59.0834 36.4267 55.1405C35.0377 40.6465 33.6542 30.9541 31.7229 11.8946C30.9247 4.01747 28.4133 0.296698 22.3367 1.2542C20.5135 1.54148 20.0594 2.55783 20.1489 4.73131C20.9808 24.9261 22.9251 41.3562 24.5508 59.3316C24.6682 59.7275 24.5277 61.8149 22.8428 61.9864C20.2858 62.2467 17.6361 61.0125 17.2689 57.4955C15.7222 42.6824 14.238 27.7359 12.7741 12.8993C11.7754 2.89953 7.95706 1.5163 3.13484 1.41947C0.347042 1.64511 0.364765 4.13869 0.346357 4.48728C-0.249254 15.7662 0.103995 23.2007 0.103985 35.1212L0.29116 42.9515C1.61958 83.0605 12.7741 106.879 58.5608 108.471C59.4568 108.575 60.1344 107.785 60.0547 106.879Z"
          id="logo_kanpla_fork"
        />
        <path
          className="primary"
          d="M108.427 0.480469C93.21 0.480469 80.9439 8.01296 73.1683 23.3705C65.8055 37.9126 64.1018 56.1619 64.1018 71.9192C64.1018 87.6765 65.2648 95.8048 66.3745 106.604C66.4986 107.635 67.3994 108.388 68.4259 108.327C118.731 106.665 128.424 75.9249 125.54 21.7737C124.417 0.693537 123.644 0.480469 108.427 0.480469Z"
          id="logo_kanpla_knife"
        />
      </svg>
    </>
  );
};
