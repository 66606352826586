import { faCircleXmark } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T } from "@repo/transifex";
import { useCountDownState } from "~/providers/app";

import { Button } from "../Button";
import { Dialog } from "../Dialog";

type DialogPaymentErrorProps = {
  status: "error" | "cancelled";
  isAttended: boolean;
  onClose: () => void;
};

export const DialogPaymentError = ({
  status,
  isAttended,
  onClose
}: DialogPaymentErrorProps) => {
  const { count } = useCountDownState();

  return (
    <Dialog backdropVariant="error" onOutsideClick={onClose} size="md">
      <div className="flex flex-col gap-y-10">
        <div className="flex flex-col gap-y-10 px-5 pt-8">
          <div className="flex flex-col items-center gap-y-5 text-danger-main">
            <FontAwesomeIcon className="h-14" icon={faCircleXmark} />

            <span className="text-3xl font-semibold">
              {status === "cancelled" ? (
                <T _str="Payment cancelled" />
              ) : (
                <T _str="Something went wrong" />
              )}
            </span>
          </div>

          {status === "error" && (
            <span className="text-center text-xl">
              <T _str="Your payment was interrupted" />
            </span>
          )}
        </div>

        <div className="px-5 pb-5">
          <Button
            className="w-full flex-1 text-lg"
            onClick={onClose}
            size="large"
            variant="primary"
          >
            {isAttended ? (
              <T _str="Close" />
            ) : (
              <T _str="Close ({count})" count={count} />
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
