import { clsx } from "clsx";
import { type PropsWithChildren } from "react";

type ProductsGridProps = PropsWithChildren & {
  id?: string;
  category: string;
  productsCount: number;
  className?: string;
};

export const ProductsGrid = ({
  id,
  category,
  productsCount,
  children,
  className
}: ProductsGridProps) => {
  return (
    <section id={id} className={clsx("flex flex-col gap-10 px-8", className)}>
      <p className="text-4xl">
        <span className="font-bold text-kiosk-text-primary">{category}</span>
        <span className="ml-2 text-kiosk-text-secondary">{productsCount}</span>
      </p>

      <div className="grid grid-cols-3 gap-8">{children}</div>
    </section>
  );
};
