/* eslint-disable @tanstack/query/exhaustive-deps -- doesn't work with the key factory*/
import { useQuery } from "@tanstack/react-query";

import { useAuth } from "~/providers/store/auth";

import { getTills } from "../requests";
import { tillsKeys } from "../keys";

export function useTills() {
  const { partnerId, schoolId, moduleId, tillId } = useAuth();

  return useQuery({
    queryKey: tillsKeys.list({
      partnerId: partnerId ?? "unknown",
      filters: { assigned: true }
    }),
    queryFn: () =>
      getTills({
        partnerId: partnerId ?? undefined,
        schoolId: schoolId ?? undefined,
        moduleId: moduleId ?? undefined,
        assigned: true
      }),
    select: (tills) => {
      if (!tillId) {
        return tills;
      }

      // Move the current till to the beginning of the array
      return [
        ...tills.filter((till) => till.id === tillId),
        ...tills.filter((till) => till.id !== tillId)
      ];
    },
    enabled: Boolean(partnerId)
  });
}

export function useTillsUnassigned() {
  const { partnerId } = useAuth();

  return useQuery({
    queryKey: tillsKeys.list({
      partnerId: partnerId ?? "unknown",
      filters: { assigned: false }
    }),
    queryFn: () =>
      getTills({
        partnerId: partnerId ?? undefined,
        assigned: false
      }),
    enabled: Boolean(partnerId)
  });
}
