import { useParams } from "react-router-dom";
import { useAtom } from "jotai";

import { T, useT } from "@repo/transifex";
import { type Till } from "~/api/tills/types";
import { useTill } from "~/api/tills/hooks/use-till";
import { useBasketProducts } from "~/providers/app";
import {
  DialogError,
  DialogLoading,
  Report,
  SettingsLayout,
  TillStatusButton
} from "~/components";

import {
  HeaderButtonOpenSession,
  startedCashBalanceAtom
} from "./HeaderButtonOpenSession";

type LayoutProps = {
  children: React.ReactNode;
  subtitle?: string;
  tillId?: Till["id"];
};

const Layout = ({ children, subtitle, tillId }: LayoutProps) => {
  const t = useT();

  return (
    <SettingsLayout
      action={<HeaderButtonOpenSession />}
      backUrl={`/settings/tills/${tillId}`}
      headerChildren={<TillStatusButton status="closed" />}
      subtitle={subtitle}
      title={t("Register Management")}
    >
      {children}
    </SettingsLayout>
  );
};

const TillSessionOpenPage = () => {
  const { tillId } = useParams();
  const t = useT();

  const {
    meta: { currency }
  } = useBasketProducts();

  const { data: till, status } = useTill(tillId);

  const [startedCashBalance, setStartedCashBalance] = useAtom(
    startedCashBalanceAtom
  );

  if (status === "pending")
    return (
      <Layout>
        <DialogLoading />
      </Layout>
    );
  if (status === "error")
    return (
      <Layout>
        <DialogError />
      </Layout>
    );

  return (
    <Layout subtitle={till.name} tillId={tillId}>
      {till.paymentProviders.includes("cash") ? (
        <Report
          cashBalance={startedCashBalance}
          currency={currency}
          hideDifference
          hideExpected
          setCashBalance={setStartedCashBalance}
          title={t("Cash drawer deposit")}
        />
      ) : (
        <T _str="Open register" />
      )}
    </Layout>
  );
};

export { TillSessionOpenPage };
