import { useMemo } from "react";

import { useProducts } from "~/api/products/hooks/use-products";
import { type Currency } from "~/api/products/types";

import { useKioskSession } from "../use-kiosk-session";

import {
  type KioskDisplayProductBasket,
  type KioskDisplayProductList
} from "./types";
import { parseDisplayProductList } from "./parse-display-product-list";
import { parseDisplayProductBasket } from "./parse-display-product-basket";
import { calculateAllowance } from "./calculate-allowance";

type UseKioskProductsReturn = {
  productsList: KioskDisplayProductList[];
  productsBasket: KioskDisplayProductBasket[];
  quantitySum: number;
  unitGrossPriceTotal: number;
  status: "pending" | "success" | "error";
  currency: Currency;
};

export function useKioskProducts(): UseKioskProductsReturn {
  const { data, status } = useProducts({});

  const basket = useKioskSession((session) => session?.basket ?? []);

  return useMemo(() => {
    if (status !== "success") {
      return {
        productsList: [],
        productsBasket: [],
        quantitySum: 0,
        unitGrossPriceTotal: 0,
        status,
        currency: "DKK"
      };
    }

    // in case we need to export it
    const allowances = calculateAllowance({
      allowances: data.allowances,
      discounts: data.discounts,
      basket,
      products: data.products
    });

    const productsList: KioskDisplayProductList[] = [];
    const productsBasket: KioskDisplayProductBasket[] = [];

    for (const product of data.products) {
      // TO-DO: handle redeeming stamp cards [POS-1867]

      productsList.push(
        parseDisplayProductList({
          product,
          discounts: data.discounts,
          allowances,
          variants: data.variants
        })
      );

      const productsInBasket = basket.filter(
        (item) => item.productId === product.id
      );
      if (productsInBasket.length > 0) {
        for (const productInBasket of productsInBasket) {
          productsBasket.push(
            parseDisplayProductBasket({
              product,
              productInBasket,
              variants: data.variants,
              discounts: data.discounts
            })
          );
        }
      }
    }

    return {
      productsList,
      productsBasket,
      quantitySum: productsBasket.length,
      unitGrossPriceTotal: productsBasket.reduce(
        (acc, item) => acc + item.unitGrossPrice,
        0
      ),
      status,
      currency: data.meta.currency
    };
  }, [data, basket, status]);
}
