const {
  VITE_LOG_LEVEL,
  VITE_LOGTAIL_TOKEN,
  VITE_KANPLA_API_URL,
  VITE_WEB_DEVICE_ID,
  VITE_WEB_TILL_ID,
  VITE_PWA_ENV,
  VITE_INTERCOM_APP_ID
} = import.meta.env;

if (!VITE_KANPLA_API_URL || typeof VITE_KANPLA_API_URL !== "string") {
  throw new Error("VITE_KANPLA_API_URL missing in env vars");
}

if (!VITE_WEB_DEVICE_ID || typeof VITE_WEB_DEVICE_ID !== "string") {
  throw new Error("VITE_WEB_DEVICE_ID missing in env vars");
}

if (!VITE_WEB_TILL_ID || typeof VITE_WEB_TILL_ID !== "string") {
  throw new Error("VITE_WEB_TILL_ID missing in env vars");
}

if (!VITE_PWA_ENV || typeof VITE_PWA_ENV !== "string") {
  throw new Error("VITE_PWA_ENV missing in env vars");
}

if (!VITE_LOGTAIL_TOKEN || typeof VITE_LOGTAIL_TOKEN !== "string") {
  throw new Error("LOGTAIL_TOKEN missing in env vars");
}

const KANPLA_API_URL = VITE_KANPLA_API_URL;
const WEB_DEVICE_ID = VITE_WEB_DEVICE_ID;
const WEB_TILL_ID = VITE_WEB_TILL_ID;
const PWA_ENV = VITE_PWA_ENV;
const INTERCOM_APP_ID = VITE_INTERCOM_APP_ID;
const LOG_LEVEL = VITE_LOG_LEVEL;
const LOGTAIL_TOKEN = VITE_LOGTAIL_TOKEN;

export {
  KANPLA_API_URL,
  WEB_DEVICE_ID,
  WEB_TILL_ID,
  PWA_ENV,
  INTERCOM_APP_ID,
  LOG_LEVEL,
  LOGTAIL_TOKEN
};
