import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useSendWebEvent } from "~/hooks";
import { authActions } from "~/providers/store/auth";
import { useAppDispatch } from "~/providers/app/hooks";

import { tillsKeys } from "../keys";
import { updateTill, type UpdateTillRequest } from "../requests";

export function useAssignTill() {
  const dispatch = useAppDispatch();
  const sendWebEvent = useSendWebEvent();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async ({
      tillId,
      deviceId
    }: Pick<UpdateTillRequest["body"], "deviceId"> & {
      tillId: string;
    }) => updateTill(tillId, { deviceId }),
    onSuccess: (till) => {
      void queryClient.invalidateQueries({
        queryKey: tillsKeys.detail(till.id)
      });

      dispatch({
        type: "DEVICE_ASSIGN_TILL",
        payload: till.id
      });

      sendWebEvent({
        type: "DEVICE_DATA_SET_REQUEST",
        payload: { tillId: till.id }
      });

      authActions.assignTill({
        moduleId: till.moduleId,
        userId: till.moduleId,
        schoolId: till.schoolId,
        tillId: till.id,
        language: till.language
      });

      navigate("/profiles");
    }
  });
}
