import { faX } from "@fortawesome/pro-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";

import { priceFormatter } from "@repo/system";
import { ProductDescription } from "~/components/kiosk/product-detail/product-description";
import { Modal } from "~/components/kiosk/ui/modals/shared/modal";
import { ProductImage } from "~/components/kiosk/ui/product-image/product-image";
import { RoundButton } from "~/components/kiosk/ui/round-button/round-button";
import { kioskActions } from "~/stores/kiosk-session/actions";
import { AddToOrderButton } from "~/components/kiosk/product-detail/add-to-order-button";
import { ModalErrorGeneric } from "~/components/kiosk/ui/modals/error/modal-error-generic";
import { useKioskProducts } from "~/stores/kiosk-session/hooks/use-kiosk-products";
import { useKioskSessionLanguage } from "~/stores/kiosk-session/hooks/use-kiosk-session";
import { QuantityInputControl } from "~/components/kiosk/ui/quantity-input-control/quantity-input-control";

const FOOTER_HEIGHT = 144;

export const KioskProductDetailsPage = () => {
  const navigate = useNavigate();

  const { productId } = useParams();

  const { productsList, status, currency } = useKioskProducts();

  const language = useKioskSessionLanguage();

  // TODO: replace with form stata when it will be implemented with variants [POS-1866]
  const [quantity, setQuantity] = useState(1);

  if (status === "pending") {
    //  TODO: replace with loading modal
    return <div>Login</div>;
  }

  if (status === "error") {
    return (
      <ModalErrorGeneric
        onClose={() => {
          navigate(-1);
        }}
      />
    );
  }

  const item = productsList.find((item) => item.product.id === productId);

  if (!item) {
    return (
      <ModalErrorGeneric
        onClose={() => {
          navigate(-1);
        }}
      />
    );
  }

  const displayTotalPrice = priceFormatter({
    value: item.unitGrossPrice * quantity,
    currency,
    locale: language
  });

  return (
    <Modal backdropVariant="idle">
      <div className="h-[1320px] w-[720px] overflow-hidden rounded-3xl bg-white">
        <div
          style={{ height: `calc(100% - ${FOOTER_HEIGHT}px)` }}
          className="h-full overflow-y-auto"
        >
          <ProductImage image={item.product.imageUrl} className="h-[480px]">
            <div className="flex flex-col">
              <div className="flex flex-1 items-end justify-end p-8">
                <RoundButton
                  icon={faX}
                  onClick={() => {
                    navigate(-1);
                  }}
                />
              </div>

              {/* TODO: tags [POS-1086] */}
              <div className="flex-1" />
            </div>
          </ProductImage>

          <div className="flex flex-col items-start gap-4 border-b px-10 py-9">
            <p className="line-clamp-2 text-5xl font-bold">
              {item.product.name}
            </p>

            {/* TODO: Allergens [POS-1864] */}

            {item.product.description ? (
              <ProductDescription description={item.product.description} />
            ) : null}
          </div>

          {/* TODO: variant [POS-1866] */}
        </div>

        <div
          style={{ height: FOOTER_HEIGHT }}
          className="sticky bottom-0 flex w-full items-center justify-center gap-4 border-t bg-white px-5"
        >
          <QuantityInputControl
            quantity={quantity}
            onIncrementClick={() => {
              setQuantity(quantity + 1);
            }}
            onDecrementClick={() => {
              if (quantity === 1) return;
              setQuantity(quantity - 1);
            }}
            size="lg"
          />

          <AddToOrderButton
            price={displayTotalPrice}
            onClick={() => {
              kioskActions.basket.add({
                productId: item.product.id,
                quantity,
                type: "regular"
              });

              navigate(-1);
            }}
          />
        </div>
      </div>
    </Modal>
  );
};
