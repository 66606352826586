import { clsx } from "clsx";

import { T } from "@repo/transifex";

type PriceProps = {
  title: string;
  value: string;
  column?: boolean;
  type: "primary" | "secondary" | "tertiary";
  hidden?: boolean;
};

export const Price = ({ title, value, column, type, hidden }: PriceProps) => {
  if (hidden) return null;

  return (
    <div
      className={clsx("flex items-baseline justify-between", {
        "flex-col !items-end": column
      })}
    >
      <span
        className={clsx("text-lg font-medium text-text-secondary", {
          "!text-text-disabled": type === "tertiary"
        })}
      >
        <T _str={title} />
      </span>

      <span
        className={clsx({
          "text-5xl text-primary-dark": type === "primary",
          "text-lg font-medium text-text-secondary": type === "secondary",
          "text-lg font-medium text-text-disabled": type === "tertiary"
        })}
      >
        {value}
      </span>
    </div>
  );
};
