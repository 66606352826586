import { clsx } from "clsx";

type CategoryBreadcrumbsProps = {
  current: string;
  categories: string[];
  onCategoryClick: (category: string) => void;
};

export const CategoryBreadcrumbs = ({
  current,
  categories,
  onCategoryClick
}: CategoryBreadcrumbsProps) => {
  const currentFormatted = formatCategoryUrl(current);

  return (
    <div className="flex w-full gap-12 overflow-scroll border-b border-gray-200 px-8 text-4xl scrollbar-hide">
      {categories.map((category) => (
        <button
          type="button"
          key={category}
          onClick={() => {
            onCategoryClick(formatCategoryUrl(category));
          }}
          className={clsx("text-nowrap pb-4 text-kiosk-text-secondary", {
            "font-bold border-b-2 border-kiosk-background-reversed !text-kiosk-text-primary":
              category === currentFormatted
          })}
        >
          <p>{category}</p>
        </button>
      ))}
    </div>
  );
};

const formatCategoryUrl = (category: string) => {
  if (category.includes("%2F")) {
    return category.replaceAll("%2F", "/");
  }

  if (category.includes("%20")) {
    return category.replaceAll("%20", " ");
  }

  return category;
};
