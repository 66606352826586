import { type ClientInferRequest } from "@ts-rest/core";

import { client, getAuthHeader } from "../client";
import { type apiContract } from "../contract";

export async function getTill(id: string) {
  const response = await client.v1.pos.tills.getById({
    params: { tillId: id },
    headers: getAuthHeader()
  });

  if (response.status === 200) {
    return response.body;
  }

  throw response;
}

type GetTillsRequest = ClientInferRequest<typeof apiContract.v1.pos.tills.get>;

export async function getTills(query: GetTillsRequest["query"]) {
  const response = await client.v1.pos.tills.get({
    query,
    headers: getAuthHeader()
  });

  if (response.status === 200) {
    return response.body;
  }

  throw response;
}

export type UpdateTillRequest = ClientInferRequest<
  typeof apiContract.v1.pos.tills.update
>;

export async function updateTill(id: string, body: UpdateTillRequest["body"]) {
  const response = await client.v1.pos.tills.update({
    params: { tillId: id },
    body,
    headers: getAuthHeader()
  });

  if (response.status === 200) {
    return response.body;
  }

  throw response;
}
