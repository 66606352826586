import { useState } from "react";
import { faLock } from "@fortawesome/pro-solid-svg-icons";

import { T } from "@repo/transifex";
import { useAppDispatch } from "~/providers/app";
import { basketActions } from "~/providers/store/basket";

import { Button } from "./Button";
import { Dialog } from "./Dialog";
import { ProfileLogout } from "./ProfileLogout";

export const UnattendedLogoutButton = () => {
  const dispatch = useAppDispatch();

  const [modal, setModal] = useState<"confirm" | "profiles" | null>(null);

  const handleClose = () => {
    dispatch({ type: "COUNTDOWN_START" });
    setModal(null);
  };

  return (
    <>
      <Button
        className="shadow"
        icon={faLock}
        onClick={() => {
          dispatch({ type: "COUNTDOWN_RESET" });
          dispatch({ type: "COUNTDOWN_STOP" });
          setModal("confirm");
        }}
        variant="info-inverted"
      />

      {modal === "confirm" ? (
        <DialogConfirm
          onClose={handleClose}
          onConfirm={() => {
            basketActions.reset();
            setModal("profiles");
          }}
        />
      ) : null}

      {modal === "profiles" ? <DialogProfiles onClose={handleClose} /> : null}
    </>
  );
};

type DialogConfirmProps = {
  onClose: () => void;
  onConfirm: () => void;
};
const DialogConfirm = ({ onClose, onConfirm }: DialogConfirmProps) => {
  return (
    <Dialog onOutsideClick={onClose} size="md">
      <div className="flex flex-col gap-y-10">
        <div className="flex flex-col items-center justify-center gap-y-8 px-5 pt-8">
          <p className="text-3xl font-semibold">
            <T _str="You are about to leave" />
          </p>
          <p>
            <T _str="Are you sure you wish to continue?" />
          </p>
        </div>

        <div className="px-5 pb-5">
          <div className="flex gap-x-5">
            <Button
              className="flex-1"
              onClick={onClose}
              size="large"
              variant="danger"
            >
              <T _str="Cancel" />
            </Button>

            <Button
              className="flex-1"
              onClick={onConfirm}
              size="large"
              variant="primary"
            >
              <T _str="Continue" />
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

type DialogProfilesProps = {
  onClose: () => void;
};
const DialogProfiles = ({ onClose }: DialogProfilesProps) => {
  return (
    <Dialog className="max-h-[80%]" onOutsideClick={onClose} size="lg">
      <ProfileLogout />
    </Dialog>
  );
};
