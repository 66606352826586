import { useNavigate } from "react-router-dom";
import { type ReactNode } from "react";
import { clsx } from "clsx";
import moment from "moment-timezone";

import { T } from "@repo/transifex";
import { type TillSession } from "~/hooks/queries/sessions";

type SessionCardProps = {
  id: TillSession["id"];
  startedAt: TillSession["startedAt"];
  endedAt: TillSession["endedAt"];
  status: "ok" | "diff" | "active" | "invalid";
};

export const SessionCard = ({
  id,
  startedAt,
  endedAt,
  status
}: SessionCardProps) => {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(id);
      }}
    >
      <SessionCardLayout>
        <div>
          <p>
            {moment.unix(startedAt).format("D MMMM YY HH:mm")}
            {endedAt ? moment.unix(endedAt).format(" - D MMMM YY HH:mm") : null}
          </p>
        </div>
        <div className="flex items-center gap-x-6">
          <p
            className={clsx("font-semibold", {
              "text-primary-dark": status === "ok",
              "text-danger-dark": status === "diff" || status === "invalid",
              "text-info-dark": status === "active"
            })}
          >
            {status === "ok" && <T _str="OK" />}
            {status === "diff" && <T _str="Difference" />}
            {status === "active" && <T _str="Active" />}
            {status === "invalid" && <T _str="Invalid" />}
          </p>
        </div>
      </SessionCardLayout>
    </div>
  );
};

export const SessionCardSkeleton = () => (
  <SessionCardLayout className="animate-pulse">
    <div className="h-6" />
  </SessionCardLayout>
);

type SessionCardLayoutProps = {
  children?: ReactNode;
  className?: string;
};
const SessionCardLayout = ({ children, className }: SessionCardLayoutProps) => {
  return (
    <div
      className={clsx(
        "flex items-center justify-between rounded-lg bg-gray-200 px-5 py-4",
        className
      )}
    >
      {children}
    </div>
  );
};
