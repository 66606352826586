import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";

import { useKioskSessionLanguage } from "~/stores/kiosk-session/hooks/use-kiosk-session";
import { CategoryBreadcrumbs } from "~/components/kiosk/products/category-breadcrumbs";
import { ProductPageHeader } from "~/components/kiosk/products/product-page-header";
import { UserInfo } from "~/components/kiosk/products/user-info";
import { ProductCard } from "~/components/kiosk/products/product-card";
import { ProductsGrid } from "~/components/kiosk/products/products-grid";
import { Skeleton } from "~/components/kiosk/products/skeleton";
import { kioskActions } from "~/stores/kiosk-session/actions";
import { useKioskProducts } from "~/stores/kiosk-session/hooks/use-kiosk-products";
import { type KioskDisplayProductList } from "~/stores/kiosk-session/hooks/use-kiosk-products/types";
import { priceFormatter } from "@repo/system";

const HEADER_HEIGHT = 144;
const CATEGORY_BREADCRUMBS_HEIGHT = 61;

const TOP_PAGE_RESERVED_HEIGHT = HEADER_HEIGHT + CATEGORY_BREADCRUMBS_HEIGHT;

export const KioskProductsPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { productsList, productsBasket, status, currency } = useKioskProducts();

  const language = useKioskSessionLanguage();

  const logoClickRef = useRef(0);
  const logoClickTimerRef = useRef<NodeJS.Timeout | null>(null);

  const handleLogoClick = () => {
    logoClickRef.current += 1;

    if (logoClickRef.current === 3) {
      logoClickRef.current = 0;
      navigate("admin");
      if (logoClickTimerRef.current) clearTimeout(logoClickTimerRef.current);
    } else {
      if (logoClickTimerRef.current) clearTimeout(logoClickTimerRef.current);
      logoClickTimerRef.current = setTimeout(
        () => (logoClickRef.current = 0),
        1000
      );
    }
  };

  if (status === "pending") {
    return <Skeleton />;
  }

  if (status === "error") {
    return <div>Error</div>;
  }

  // TODO: replace this, just for demo
  const user = {
    name: "John Doe",
    saldo: 1234
  };

  // TODO: replace this, just for demo
  type ProductsByCategory = Record<string, KioskDisplayProductList[]>;
  const productsByCategory = productsList.reduce<ProductsByCategory>(
    (acc, displayProduct) => {
      if (!displayProduct.product.category) return acc;

      if (!acc[displayProduct.product.category]) {
        acc[displayProduct.product.category] = [];
      }
      acc[displayProduct.product.category].push(displayProduct);
      return acc;
    },
    {}
  );

  // TODO: replace this, just for demo
  const categories = Object.keys(productsByCategory);

  const selectedCategory = location.hash.replace("#", "") || categories[0];

  return (
    <>
      <div className="fixed top-0 z-10 w-full bg-white">
        <ProductPageHeader onLogoClick={handleLogoClick}>
          <UserInfo
            userName={user.name}
            balance={priceFormatter({
              value: user.saldo,
              currency,
              locale: language
            })}
          />

          {/* TODO: search bar */}

          {/* TODO: Language selector */}
        </ProductPageHeader>

        <CategoryBreadcrumbs
          current={selectedCategory}
          categories={categories}
          onCategoryClick={(category) => {
            navigate(`#${category}`);

            const section = document.getElementById(category);
            const scrollableContainer =
              document.getElementById("products-container");

            if (section && scrollableContainer) {
              scrollableContainer.scrollTo({
                top: section.offsetTop,
                behavior: "smooth"
              });
            }
          }}
        />
      </div>

      <div
        id="products-container"
        className="absolute inset-0 overflow-y-scroll"
        style={{
          top: `${TOP_PAGE_RESERVED_HEIGHT}px`,
          height: `calc(100vh - ${TOP_PAGE_RESERVED_HEIGHT}px)`
        }}
      >
        {Object.entries(productsByCategory).map(([category, products]) => (
          <ProductsGrid
            id={category}
            key={category}
            category={category}
            productsCount={products.length}
            className="py-12"
          >
            {products.map(({ product, unitListPrice, unitGrossPrice }) => (
              <ProductCard
                key={product.id}
                id={product.id}
                name={product.name}
                displayPrice={priceFormatter({
                  value: unitGrossPrice,
                  currency,
                  locale: language
                })}
                displayPriceOriginal={
                  unitListPrice
                    ? priceFormatter({
                        value: unitListPrice,
                        currency,
                        locale: language
                      })
                    : undefined
                }
                tags={[]}
                image={product.imageUrl}
                // TODO: replace after basket implementation [POS-1834]
                quantity={
                  productsBasket.find((item) => item.product.id === product.id)
                    ?.quantity || 0
                }
                onItemClick={() => {
                  kioskActions.basket.add({
                    productId: product.id,
                    quantity: 1,
                    type: "regular"
                  });
                }}
                onIncrementClick={() => {
                  const basketItem = productsBasket.find(
                    (item) => item.product.id === product.id
                  );

                  if (basketItem) {
                    kioskActions.basket.increment(basketItem.basketItemId);
                  }
                }}
                onDecrementClick={() => {
                  const basketItem = productsBasket.find(
                    (item) => item.product.id === product.id
                  );

                  if (basketItem) {
                    kioskActions.basket.decrement(basketItem.basketItemId);
                  }
                }}
              />
            ))}
          </ProductsGrid>
        ))}
      </div>

      <Outlet />
    </>
  );
};
