import { useNavigate } from "react-router-dom";

import { Modal } from "~/components/kiosk/ui/modals/shared/modal";
import { ProfileLogout } from "~/components/ProfileLogout";
import { kioskActions } from "~/stores/kiosk-session/actions";

export const KioskProductsAdminPage = () => {
  const navigate = useNavigate();

  return (
    <Modal
      backdropVariant="idle"
      onOutsideClick={() => {
        navigate(-1);
      }}
      className="w-4/5 rounded-lg bg-white p-6"
    >
      <ProfileLogout
        onPinSuccess={() => {
          kioskActions.session.reset();
        }}
      />
    </Modal>
  );
};
