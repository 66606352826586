import type { StepVariant } from "@repo/types";

import { INITIAL_STATE, modalVariantsStore } from "./store";

function reset() {
  modalVariantsStore.setState(INITIAL_STATE);
}

type InitArgs = {
  productId: string;
  options: string[];
};

function init({ productId, options }: InitArgs) {
  modalVariantsStore.setState((state) => {
    state.productId = productId;
    state.variants = options.map((option) => ({
      id: option,
      choices: [],
      completed: false
    }));
    state.amount = 1;
  });
}

type InitEditArgs = {
  productId: string;
  options: StepVariant[];
  amount: number;
};

function initEdit({ productId, options, amount }: InitEditArgs) {
  modalVariantsStore.setState((state) => {
    state.productId = productId;
    state.variants = options.map((option) => ({
      id: option.id,
      choices: option.choices.filter((c) => c.amount > 0),
      completed: true
    }));
    state.amount = amount;
  });
}

function increment() {
  modalVariantsStore.setState((state) => {
    state.amount += 1;
  });
}

function decrement() {
  modalVariantsStore.setState((state) => {
    if (state.amount > 1) {
      state.amount -= 1;
    }
  });
}

function toggleChoice({
  variantId,
  choiceId
}: {
  variantId: string;
  choiceId: string;
}) {
  modalVariantsStore.setState((state) => {
    const variant = state.variants.find((v) => v.id === variantId);

    if (variant) {
      if (variant.choices.some((c) => c.id === choiceId)) {
        variant.choices = [];
      } else {
        variant.choices = [];

        variant.choices.push({ id: choiceId, amount: 1 });
      }
    }
  });
}

function incrementChoice({
  variantId,
  choiceId
}: {
  variantId: string;
  choiceId: string;
}) {
  modalVariantsStore.setState((state) => {
    const variant = state.variants.find((v) => v.id === variantId);

    if (variant) {
      const choice = variant.choices.find((c) => c.id === choiceId);

      if (choice) {
        choice.amount += 1;
      } else {
        variant.choices.push({ id: choiceId, amount: 1 });
      }
    }
  });
}

function decrementChoice({
  variantId,
  choiceId
}: {
  variantId: string;
  choiceId: string;
}) {
  modalVariantsStore.setState((state) => {
    const variant = state.variants.find((v) => v.id === variantId);

    if (variant) {
      const choice = variant.choices.find((c) => c.id === choiceId);

      if (choice) {
        if (choice.amount > 1) {
          choice.amount -= 1;
        } else {
          variant.choices = variant.choices.filter((c) => c.id !== choiceId);
        }
      }
    }
  });
}

function completeStep(id: string) {
  modalVariantsStore.setState((state) => {
    const variant = state.variants.find((v) => v.id === id);
    if (variant) {
      variant.completed = true;
    }
  });
}

const modalVariantsActions = {
  init,
  initEdit,
  reset,
  increment,
  decrement,
  completeStep,
  toggleChoice,
  incrementChoice,
  decrementChoice
};

export { modalVariantsActions };
