import { useSpring, animated } from "@react-spring/web";
import { faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type PropsWithChildren, useEffect } from "react";

import { T } from "@repo/transifex";

import { Button } from "../Button";

const ANIMATION_DURATION = 1500;

type ScreenSuccessProps = PropsWithChildren<{
  onClose: () => void;
}>;

export const ScreenSuccess = ({ onClose, children }: ScreenSuccessProps) => {
  const animationProps = useSpring({
    from: { width: "0%" },
    to: { width: "100%" },
    config: {
      duration: ANIMATION_DURATION,
      friction: 120,
      tension: 120
    },
    onRest: onClose
  });

  useEffect(() => {
    const audioPlayer = new Audio("/audio/ticket-validation-success.wav");
    audioPlayer.play();
  }, []);

  return (
    <div className="flex h-full flex-col items-center justify-center bg-primary-dark">
      <FontAwesomeIcon
        icon={faCheckCircle}
        fontSize={250}
        className="text-white"
      />

      <div className="flex flex-col items-center justify-center gap-4 pb-12 pt-6">
        {children}
      </div>

      <Button
        className="relative overflow-hidden !rounded-xl !bg-white/10 !px-32"
        variant="primary"
        size="large"
        onClick={onClose}
      >
        <animated.div
          className="absolute inset-y-0 left-0 h-full bg-white/30"
          style={{ ...animationProps }}
        />
        <div className="relative">
          <T _str="Close" />
        </div>
      </Button>
    </div>
  );
};

export const ScreenSuccessTitle = ({ children }: PropsWithChildren) => {
  return <p className="text-7xl font-semibold text-white">{children}</p>;
};

export const ScreenSuccessText = ({ children }: PropsWithChildren) => {
  return <p className="text-2xl text-white">{children}</p>;
};
