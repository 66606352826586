import type { OrderWithLines } from "@kanpla/api-contract";
import { type OrderLine as ExternalOrderLine } from "@kanpla/api-contract/dist/libs/types/src";
import { v4 as uuidv4 } from "uuid";

import type { Currency, OrderLine, OrderOptions } from "@repo/types";
import { priceFormatter } from "@repo/system";

export type FormattedOrderLine = {
  name: string;
  orderLineId?: string;
  id: string;
  formattedPrice: string;
  refunded: boolean;
};

export function formatRefundOrderLines(
  order: OrderWithLines,
  currency: Currency,
  locale: string | null
): FormattedOrderLine[] {
  if (!order.orderLines) return [];

  return order.orderLines.reduce(
    (acc: FormattedOrderLine[], externalLine: ExternalOrderLine) => {
      // There is a mismatch between the external kanpla repo and internal types, so this is just to match it until we update all types
      const line: OrderLine = {
        ...externalLine,
        options: Object.keys(externalLine.options).reduce<
          Record<string, OrderOptions>
        >((acc, key) => {
          acc[key] = {
            ...externalLine.options[key],
            id: externalLine.options[key].id // Ensuring id is a number
          };
          return acc;
        }, {})
      };

      if (!line.amount) return acc;

      const discount = order.discounts?.find(
        (discount) => discount.items[0].orderLineId === line.orderLineId
      );
      const unitDiscountedAmount = discount?.unitDiscountedAmount ?? 0;

      if (line.amount === 1) {
        const alreadyRefunded = Boolean(
          order.refunds?.some((refund) =>
            refund.orderLines.some(
              (refundLine) =>
                refundLine.orderLineId === line.orderLineId &&
                refundLine.amount === -1
            )
          )
        );
        return [
          ...acc,
          {
            name: line.name,
            orderLineId: line.orderLineId,
            id: uuidv4(),
            formattedPrice: priceFormatter({
              value:
                line.unitPrice * (1 + line.vatRate) -
                unitDiscountedAmount / line.amount,
              currency,
              locale
            }),
            refunded: alreadyRefunded
          }
        ];
      }

      const alreadyRefunded =
        order.refunds?.reduce((acc, refund) => {
          const refundLines = refund.orderLines.filter(
            (refundLine) => refundLine.orderLineId === line.orderLineId
          );

          if (!refundLines) return acc;

          return (
            acc +
            refundLines.reduce((acc, refundLine) => {
              if (!refundLine.amount) return acc;

              return acc + -refundLine.amount;
            }, 0)
          );
        }, 0) ?? 0;

      const lines = Array.from({ length: line.amount }, (_, index) => ({
        name: line.name,
        orderLineId: line.orderLineId,
        id: uuidv4(),
        formattedPrice: priceFormatter({
          value:
            line.unitPrice * (1 + line.vatRate) -
            unitDiscountedAmount / line.amount,
          currency: order.currency,
          locale
        }),
        refunded: alreadyRefunded > index
      }));

      return [...acc, ...lines];
    },
    []
  );
}
