import { type ClientInferRequest } from "@ts-rest/core";

import { client, getAuthHeader } from "~/api/client";

import { type apiContract } from "../contract";

type GetProductsRequest = ClientInferRequest<
  typeof apiContract.v2.pos.products.get
>;

export async function getProducts(query: GetProductsRequest["query"]) {
  const response = await client.v2.pos.products.get({
    query,
    headers: getAuthHeader()
  });

  if (response.status === 200) {
    return response.body;
  }

  throw response;
}
