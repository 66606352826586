import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import { Button } from "./Button";

type CollapseProps = {
  label: string | React.ReactNode;
  className?: string;
  children: React.ReactNode;
  stepId?: string;
};

export const Collapse = ({
  label,
  className,
  children,
  stepId
}: CollapseProps) => {
  const [show, setShow] = useState(false);
  const [searchParams] = useSearchParams();
  const stepFromParams = searchParams.get("step");

  const toggleShow = () => {
    setShow(!show);
  };

  useEffect(() => {
    if (stepId && stepFromParams) {
      setShow(stepFromParams === stepId);
    }
  }, [stepFromParams, stepId]);

  return (
    <div>
      <Button
        className={clsx("w-full !p-0", className)}
        onClick={toggleShow}
        variant="transparent"
      >
        <div className="flex w-full items-center justify-between gap-3">
          {label}
          <FontAwesomeIcon icon={show ? faChevronUp : faChevronDown} />
        </div>
      </Button>
      <div
        className={clsx({
          hidden: !show
        })}
      >
        {children}
      </div>
    </div>
  );
};
