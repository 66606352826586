import { type PropsWithChildren } from "react";
import { faShoppingBasket } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T } from "@repo/transifex";

export const Basket = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex flex-col gap-y-24 bg-white px-16 pb-80 pt-16 text-kiosk-text-primary">
      {children}
    </div>
  );
};

export const BasketHeader = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex items-center justify-between">
      <span className="text-left text-8xl font-extrabold">
        <T _str="Basket" />
      </span>

      <FontAwesomeIcon
        icon={faShoppingBasket}
        fontSize={240}
        className="rotate-[25deg] text-kiosk-background-secondary"
      />

      {children}
    </div>
  );
};

export const BasketBody = ({ children }: PropsWithChildren) => {
  return <div className="flex flex-col">{children}</div>;
};

export const BasketProducts = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex flex-col divide-y-2 divide-kiosk-border-secondary *:py-6 first:*:pt-0">
      {children}
    </div>
  );
};

type BasketSumUpProps = {
  numberOfProducts: number;
  totalAmount: string;
};

export const BasketSumUp = ({
  numberOfProducts,
  totalAmount
}: BasketSumUpProps) => {
  return (
    <div className="flex flex-row items-center justify-between border-t-2 border-t-kiosk-background-reversed pt-8">
      <div className="flex gap-x-3">
        <span className="text-4xl font-extrabold">
          <T _str="Total" />
        </span>

        <span className="text-4xl text-kiosk-text-secondary">
          <T _str="{quantity} items" quantity={numberOfProducts} />
        </span>
      </div>

      <div>
        <span className="text-5xl font-extrabold">{totalAmount}</span>
      </div>
    </div>
  );
};

export const OrderInstructions = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex flex-col gap-y-10">
      <span className="text-5xl font-extrabold">
        <T _str="Order details" />
      </span>

      <div className="flex flex-col gap-y-2">{children}</div>
    </div>
  );
};

export const BasketFooter = ({ children }: { children: React.ReactNode }) => {
  return <div className="fixed inset-x-0 bottom-0">{children}</div>;
};
