import { KanplaLogoNew } from "../KanplaLogoNew";

export const ScreenLoaderLogo = () => {
  return (
    <div className="flex h-full flex-1 flex-col justify-center bg-kiosk-background-reversed p-16 pt-32 text-center">
      <div className="flex h-16 justify-center">
        <KanplaLogoNew />
      </div>
    </div>
  );
};
