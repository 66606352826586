import { faSpinner } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet } from "react-router-dom";
import { clsx } from "clsx";

import { T, useT } from "@repo/transifex";
import {
  DialogError,
  DialogLoading,
  SelectCreatable,
  SettingsLayout,
  Switch
} from "~/components";
import { useSendWebEvent } from "~/hooks";
import { useAppContext } from "~/providers/app";
import { useAvailableLanguages } from "~/utils/transifex";
import { Scale } from "~/routes/__app.settings.devices/Scale.tsx";
import { authActions } from "~/providers/store/auth";
import { useCurrentTill } from "~/api/tills/hooks/use-till";
import { useUpdateTill } from "~/api/tills/hooks/use-update-till";

import { ConnectionGuide } from "./ConnectionGuide";
import { Device, DeviceWrapper } from "./Device";
import { ProductColors } from "./ProductColors";

const DevicesPage = () => {
  const {
    hardware: {
      printer,
      scales: { scale }
    }
  } = useAppContext();

  const t = useT();

  const { data: till, status: tillStatus } = useCurrentTill();

  const sendWebEvent = useSendWebEvent();

  const { mutate: updateTillLanguage, status: updateTillLanguageStatus } =
    useUpdateTill();
  const { mutate: updateTillColors, status: updateTillColorsStatus } =
    useUpdateTill();
  const { mutate: updateTillTerminal, status: updateTillTerminalStatus } =
    useUpdateTill();
  const { mutate: updateTillCardReader, status: updateTillCardReaderlStatus } =
    useUpdateTill();

  const isTillUpdating =
    updateTillLanguageStatus === "pending" ||
    updateTillColorsStatus === "pending" ||
    updateTillTerminalStatus === "pending" ||
    updateTillCardReaderlStatus === "pending";

  const { data: languages, status: languagesStatus } = useAvailableLanguages();

  if (tillStatus === "pending") {
    return (
      <SettingsLayout title={t("Device settings")}>
        <DialogLoading />
      </SettingsLayout>
    );
  }

  if (tillStatus === "error") {
    return (
      <SettingsLayout title={t("Device settings")}>
        <DialogError backUrl=".." />
      </SettingsLayout>
    );
  }

  return (
    <SettingsLayout
      className={clsx({ "pointer-events-none": isTillUpdating })}
      title={t("Device settings")}
    >
      <div className="flex w-full flex-col gap-10">
        <div>
          <p className="mb-2.5 text-2xl font-bold">
            <T _str="Language selector" />
          </p>
          <DeviceWrapper>
            {(languagesStatus === "pending" ||
              updateTillLanguageStatus === "pending") && (
              <div className="flex w-full items-center justify-center gap-x-4">
                <FontAwesomeIcon
                  className="text-5xl text-text-secondary"
                  icon={faSpinner}
                  spinPulse
                />
                <span className="text-text-secondary">
                  {languagesStatus === "pending" && (
                    <T _str="Languages are being loaded..." />
                  )}
                  {updateTillLanguageStatus === "pending" && (
                    <T _str="Language is being updated..." />
                  )}
                </span>
              </div>
            )}

            {languagesStatus === "success" &&
              updateTillLanguageStatus !== "pending" && (
                <>
                  <p className="text-text-disabled">
                    <T _str="You can change the language of the system here" />
                  </p>
                  <div>
                    <SelectCreatable
                      className="!text-base"
                      onChange={(selected) => {
                        if (selected) {
                          updateTillLanguage(
                            { language: selected.value },
                            {
                              onSuccess: () => {
                                authActions.updateLanguage(selected.value);
                              }
                            }
                          );
                        }
                      }}
                      options={languages.map((language) => ({
                        value: language.code,
                        label: language.name
                      }))}
                      placeholder={t("Choose a language")}
                      value={
                        languages.find((lang) => lang.code === till.language)
                          ? {
                              value: till.language,
                              label: languages.find(
                                (lang) => lang.code === till.language
                              )?.name
                            }
                          : null
                      }
                    />
                  </div>
                </>
              )}
          </DeviceWrapper>
        </div>

        {till.type === "attended" || till.type === "self-service" ? (
          <ProductColors
            hasProductColors={Boolean(till.config?.hasProductColors)}
            updateTill={updateTillColors}
            updateTillStatus={updateTillColorsStatus}
          />
        ) : null}

        {till.type === "attended" ||
        till.type === "self-service" ||
        till.type === "kiosk" ? (
          <div>
            <p className="mb-2.5 text-2xl font-bold">
              <T _str="Payment terminal" />
            </p>

            {till.terminalId ? (
              <Device
                device={{ id: till.terminalId }}
                identifier={till.terminalId}
                onDisconnect={() => {
                  updateTillTerminal({ terminalId: null });
                }}
              />
            ) : (
              <Device connectRoute="/settings/devices/terminals" />
            )}
          </div>
        ) : null}

        {till.type !== "ticket-validation" ? (
          <div>
            <p className="mb-2.5 text-2xl font-bold">
              <T _str="Cash drawer | Printer" />
            </p>
            {!printer && (
              <div className="mb-5">
                <ConnectionGuide />
              </div>
            )}
            {printer ? (
              <Device
                device={printer}
                identifier={`${printer.interfaceType} - ${printer.identifier} - ${printer.model}`}
                onDisconnect={() => {
                  sendWebEvent({
                    type: "PRINTER_DISCONNECT_REQUEST",
                    payload: printer
                  });
                }}
              />
            ) : (
              <Device
                connectRoute="/settings/devices/printers"
                onConnect={() => {
                  sendWebEvent({ type: "PRINTER_DISCOVER_REQUEST" });
                }}
              />
            )}
          </div>
        ) : null}

        {till.type === "attended" || till.type === "self-service" ? (
          <div>
            <p className="mb-2.5 text-2xl font-bold">
              <T _str="Scale" />
            </p>
            <Scale
              isConnected={Boolean(scale)}
              onConnect={() => {
                sendWebEvent({
                  type: "SCALE_DISCOVER_REQUEST"
                });
              }}
              onDisconnect={() => {
                sendWebEvent({
                  type: "SCALE_REMOVE"
                });
              }}
            />
          </div>
        ) : null}

        {till.type === "self-service" ? (
          <div>
            <p className="mb-2.5 text-2xl font-bold">
              <T _str="Card reader / Scanner" />
            </p>
            <DeviceWrapper>
              <>
                <p>
                  <T _str="Employee card registration" />
                </p>
                <Switch
                  checked={till.config.hasEmployeeCardReader}
                  handleChange={(enabled) => {
                    updateTillCardReader({
                      employeeCardReader: enabled
                    });
                  }}
                />
              </>
            </DeviceWrapper>
          </div>
        ) : null}
      </div>

      <Outlet />
    </SettingsLayout>
  );
};

export { DevicesPage };
