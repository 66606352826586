import { faPrint } from "@fortawesome/pro-solid-svg-icons";

import { T } from "@repo/transifex";
import { useSalesReport } from "~/hooks/queries/reports";
import { useAppContext } from "~/providers/app";
import { Button } from "~/components";
import { useSendWebEvent } from "~/hooks";

export const PrintReportButton = ({ sessionId }: { sessionId: string }) => {
  const {
    hardware: { printer, printing }
  } = useAppContext();
  const sendWebEvent = useSendWebEvent();

  const { data: salesReport, status } = useSalesReport(sessionId);

  const handlePrint = () => {
    if (!salesReport?.receipt) throw new Error("No receipt to print");
    sendWebEvent({
      type: "PRINTER_PRINT_REPORT_REQUEST",
      payload: salesReport.receipt
    });
  };

  if (!printer) return null;

  if (printing.status === "error") {
    return (
      <Button
        icon={faPrint}
        onClick={handlePrint}
        size="large"
        variant="danger"
      >
        <T _str="Try again" />
      </Button>
    );
  }

  return (
    <Button
      disabled={!sessionId || status !== "success"}
      icon={faPrint}
      loading={status === "pending" || printing.status === "loading"}
      onClick={handlePrint}
      size="large"
      variant="info-inverted"
    >
      <T _str="Print report" />
    </Button>
  );
};
