import { faArrowsRotate } from "@fortawesome/pro-solid-svg-icons";
import * as Sentry from "@sentry/react";
import { useRegisterSW } from "virtual:pwa-register/react";

import { T } from "@repo/transifex";
import { Button } from "~/components";

export const UpdatePWA = () => {
  const {
    needRefresh: [needRefresh],
    updateServiceWorker
  } = useRegisterSW({
    onRegisteredSW(_, r) {
      setInterval(() => {
        void r?.update();
      }, 30000);
    },
    onRegisterError(error) {
      Sentry.captureException(error);
    }
  });

  if (needRefresh) {
    return (
      <Button
        icon={faArrowsRotate}
        onClick={() => void updateServiceWorker(true)}
        variant="info"
      >
        <T _str="New version available, click to reload and update" />
      </Button>
    );
  }

  return null;
};
