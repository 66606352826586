import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { calculateOrderVatBreakdown, slugify } from "@repo/system";
import { T, useT } from "@repo/transifex";
import { Button, Dialog } from "~/components";
import type { ProductCustom } from "~/providers/store/basket";
import { basketActions } from "~/providers/store/basket";
import { useProductsPOS } from "~/api/products/hooks/use-products";

import { CustomProduct } from "./CustomProduct";

type CustomProductData = {
  amount: number;
  name: string;
  category:
    | {
        value: string;
        label: string;
      }
    | undefined;
  price: number | undefined;
  vatCalculation: "excl" | "incl";
  vatRate: number;
  customVatRate?: number;
};

export type CustomProductForm = {
  customProduct: CustomProductData[];
};

const CustomProductHeader = () => (
  <div className="relative mb-8 flex w-full items-center justify-center border-b border-divider-main py-2 pb-5">
    <Link className="absolute left-0" to="..">
      <Button className="text-danger-dark" variant="transparent">
        <T _str="Cancel" />
      </Button>
    </Link>
    <p className="font-bold text-text-primary">
      <T _str="Create custom order" />
    </p>
  </div>
);

export const CustomProducts = () => {
  const t = useT();
  const navigate = useNavigate();

  const { data: productsData } = useProductsPOS();

  const {
    taxRate: vatRate = 0.25,
    taxRateId = null,
    taxRateName = null
  } = productsData?.defaultTaxData ?? {};

  const DEFAULT_CUSTOM_PRODUCT: CustomProductData = {
    amount: 1,
    name: "",
    category: {
      value: t("Custom product"),
      label: t("Custom product")
    },
    price: undefined,
    vatCalculation: "incl",
    vatRate: vatRate * 100,
    customVatRate: undefined
  };

  const methods = useForm<CustomProductForm>({
    defaultValues: {
      customProduct: [DEFAULT_CUSTOM_PRODUCT]
    }
  });
  const { control, handleSubmit } = methods;

  const { fields, remove, append } = useFieldArray({
    control,
    name: "customProduct"
  });

  return (
    <Dialog backUrl=".." className="p-5" size="lg">
      <CustomProductHeader />

      <FormProvider {...methods}>
        <form
          className="flex flex-col gap-8"
          onSubmit={handleSubmit((data) => {
            const customProducts = data.customProduct.map<
              Omit<ProductCustom, "type" | "id">
            >((product) => {
              const exclVatUnitPrice = product.price ?? 0;

              const name = product.name || t("Diverse");

              const category = product.category?.value ?? t("Custom product");

              const id = `custom-${slugify(name)}-${slugify(
                category
              )}-${uuidv4()}`;

              const amount = product.amount ?? 1;

              const customProduct = {
                vatRate: (product.customVatRate ?? product.vatRate) / 100,
                unitPrice: exclVatUnitPrice,
                options: {},
                amount: 1,
                productId: id
              };

              const unitPrice = calculateOrderVatBreakdown([
                customProduct
              ]).withVat;

              return {
                vatCalculation: product.vatCalculation,
                count: amount,
                rawUnitPrice: exclVatUnitPrice,
                vatRate: product.vatRate / 100,
                customVatRate: product.customVatRate
                  ? product.customVatRate / 100
                  : undefined,
                options: {},
                productId: id,
                isHybridBilled: false,
                name,
                category,
                unitPrice,
                taxRateId,
                taxRateName
              };
            });

            basketActions.addCustomProducts(customProducts);
            navigate("..");
          })}
        >
          {fields.map(({ id }, index) => (
            <CustomProduct
              control={control}
              index={index}
              key={id}
              remove={remove}
            />
          ))}

          <Button
            className="w-full"
            icon={faPlus}
            onClick={() => {
              append(DEFAULT_CUSTOM_PRODUCT);
            }}
            variant="secondary"
          >
            <T _str="Add product" />
          </Button>

          <Button className="w-full" size="large" type="submit">
            <T _str="Add to basket" />
          </Button>
        </form>
      </FormProvider>
    </Dialog>
  );
};
