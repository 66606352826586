import { faWarning } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate } from "react-router-dom";

import { T } from "@repo/transifex";
import { Dialog } from "~/components";
import { useAuth } from "~/providers/store/auth";

const OfflinePage = () => {
  const { isOnline } = useAuth();

  if (isOnline) {
    return <Navigate replace to="/pos" />;
  }

  return (
    <Dialog backdropVariant="error" size="md">
      <div className="m-7 flex flex-col items-center text-center">
        <FontAwesomeIcon
          className="text-danger-main"
          icon={faWarning}
          size="2xl"
        />
        <h2 className="mb-6 mt-3 text-3xl font-bold">
          <T _str="The device is currently offline" />
        </h2>

        <p className="font-semibold">
          <T _str="Please contact the staff" />
        </p>
      </div>
    </Dialog>
  );
};

export { OfflinePage };
