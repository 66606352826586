import { useQueryClient, useMutation } from "@tanstack/react-query";

import { getAuth } from "~/providers/store/auth/getters";

import { tillsKeys } from "../keys";
import { updateTill, type UpdateTillRequest } from "../requests";

export function useUpdateTill() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (body: UpdateTillRequest["body"]) => {
      const { tillId, status } = getAuth();
      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      return updateTill(tillId, body);
    },
    onSuccess: async (till) => {
      await queryClient.invalidateQueries({
        queryKey: tillsKeys.detail(till.id)
      });
    }
  });
}
