import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams
} from "react-router-dom";
import { clsx } from "clsx";

import { priceFormatter } from "@repo/system";
import { T } from "@repo/transifex";
import { Button, InputAmount } from "~/components";
import { useBasketProducts } from "~/providers/app";
import { useAuth } from "~/providers/store/auth";
import { basketActions } from "~/providers/store/basket";
import {
  modalVariantsActions,
  useVariantAmount,
  useVariantModalSteps
} from "~/providers/store/modal-variants";
import { calculateVariantTotal } from "~/utils/calculateVariantTotal";

import { Variant } from "./Variant";

export const VariantsPage = () => {
  const { productId } = useParams();
  const [searchParams] = useSearchParams();
  const editId = searchParams.get("edit");

  const navigate = useNavigate();

  const { locale } = useAuth();
  const { products, productsInBasket, meta } = useBasketProducts();

  const { steps, currentStep, nextStep, isSubmitDisabled } =
    useVariantModalSteps(productId);
  const amount = useVariantAmount();

  const product = products.find((p) => p.id === productId);

  if (!product) return <Navigate replace to="/pos" />;

  const productInBasket = productsInBasket.find((p) => p.basketId === editId);
  if (editId && !productInBasket) return <Navigate replace to="/pos" />;

  if (steps.length === 0) return <Navigate replace to="/pos" />;

  const isNextStepDisabled = !currentStep?.isValid;

  const variantPrice = calculateVariantTotal(steps, product.vatRate);

  function calculateTotalDisplayPrice() {
    if (!product) {
      return priceFormatter({
        value: 0,
        currency: meta.currency,
        locale
      });
    }

    const basePrice = (product.unitPrice + variantPrice) * amount;

    const discountAmount = product.discount?.percentage
      ? basePrice * (product.discount.percentage / 100)
      : product.discount?.amount ?? 0;

    return priceFormatter({
      value: basePrice - discountAmount,
      currency: meta.currency,
      locale
    });
  }

  return (
    <div className="flex size-full flex-col items-center">
      <div className="flex w-[calc(100%-(theme(spacing.6))*2)] items-center justify-between gap-5 border-b border-divider-main py-6">
        <Button
          className="w-72 text-danger-dark"
          onClick={() => {
            navigate(-1);
          }}
          textClassName="!text-left !block"
          variant="transparent"
        >
          <T _str="Cancel" />
        </Button>

        <div className="flex flex-col items-center gap-2">
          <p className="text-sm font-bold uppercase text-primary-dark">
            <T _str="Add product" />
          </p>

          <p className="text-center text-3xl font-bold text-text-primary">
            {product.name}
          </p>
        </div>

        <div className="flex w-72 items-center justify-end gap-10">
          <div className="flex flex-col items-center gap-2">
            <p className="font-bold uppercase text-text-disabled">
              <T _str="Price" />
            </p>
            <div className="flex gap-2">
              {product.discount ? (
                <p className="font-bold text-text-secondary line-through">
                  {priceFormatter({
                    value: (product.unitPrice + variantPrice) * amount,
                    currency: meta.currency,
                    locale
                  })}
                </p>
              ) : null}
              <p className="font-bold text-text-primary">
                {calculateTotalDisplayPrice()}
              </p>
            </div>
          </div>
          <InputAmount
            name="amount"
            onChange={() => null}
            onDecrement={modalVariantsActions.decrement}
            onIncrement={modalVariantsActions.increment}
            size="medium"
            value={amount}
          />
        </div>
      </div>

      <div className="w-4/5 flex-1 overflow-auto py-12 scrollbar-hide">
        {steps.map((variant) => (
          <Variant
            isOnlyOneStep={steps.length === 1}
            key={variant.id}
            variant={variant}
          />
        ))}
      </div>

      <div className="flex w-full">
        <Button
          className={clsx("w-full flex-[3] !rounded-none", {
            hidden: !nextStep
          })}
          disabled={isNextStepDisabled}
          onClick={() => {
            if (!currentStep) {
              throw new Error("Current step is not defined");
            }
            modalVariantsActions.completeStep(currentStep.id);
          }}
          size="xl"
          variant={isNextStepDisabled ? "secondary" : "primary-light"}
        >
          <T _str="Next: Choose {step}" step={nextStep?.name ?? ""} />
        </Button>

        <Button
          className={clsx("w-full flex-1 !rounded-none", {
            hidden: nextStep && isSubmitDisabled
          })}
          disabled={isSubmitDisabled}
          onClick={() => {
            if (editId) {
              if (!productInBasket) {
                throw new Error("Product in basket not found");
              }

              basketActions.editVariants(
                productInBasket.basketId,
                steps,
                amount
              );
            } else {
              basketActions.addVariants(
                product.id,
                steps,
                amount,
                !product.discount?.isAllowanceOver
              );
            }

            modalVariantsActions.reset();
          }}
          size="xl"
          variant={isSubmitDisabled ? "secondary" : "primary"}
        >
          {editId ? <T _str="Save" /> : <T _str="Add to basket" />}
        </Button>
      </div>
    </div>
  );
};
