import { useSearchParams } from "react-router-dom";

import { T } from "@repo/transifex";
import { useOrder } from "~/hooks/queries/orders";
import { DialogLoading, DialogPayment } from "~/components";

const PosBillingPage = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const { data: order, status } = useOrder(orderId);

  if (status === "pending") {
    return <DialogLoading title={<T _str="Preparing payment" />} />;
  }

  if (
    status === "error" ||
    order.status === "failed" ||
    order.status === "cancelled"
  ) {
    return <DialogPayment status="error" />;
  }

  if (order.status === "paid") {
    return <DialogPayment orderId={order.id} status="success" />;
  }

  return <DialogLoading title={<T _str="Preparing payment" />} />;
};

export { PosBillingPage };
