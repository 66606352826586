/* eslint-disable no-console -- console.log is needed only in this file */
import { captureException } from "@sentry/react";
import Pino from "pino";
import { Logtail } from "@logtail/browser";

import { LOGTAIL_TOKEN, LOG_LEVEL, PWA_ENV } from "~/utils/constants";
import { getAuth } from "~/providers/store/auth";

const DEFAULT_LOG_LEVEL: Pino.Level = "error";
const MESSAGE_KEY = "message";

type LogProperties = Partial<{
  till_id: string | null;
  device_id: string | null;
}> &
  Record<string, unknown>;
type ErrorTags = Record<string, string | boolean | number>;
type LogObject = {
  level: Pino.Level;
  [MESSAGE_KEY]: string;
} & LogProperties;

/**
 * Logger class to handle logging with different levels and outputs.
 * It uses Pino for logging and Logtail for log aggregation.
 */
class Logger {
  private logLevel: Pino.Level;
  private logtail: Logtail;
  private pinoLogger: Pino.Logger;

  /**
   * Creates an instance of Logger.
   * @param level - The log level to use. Defaults to `DEFAULT_LOG_LEVEL`.
   */
  constructor(level?: Pino.Level) {
    this.logLevel = level || DEFAULT_LOG_LEVEL;

    this.logtail = new Logtail(LOGTAIL_TOKEN);

    this.pinoLogger = Pino({
      level: this.logLevel,
      messageKey: MESSAGE_KEY,
      browser: {
        formatters: {
          level(label) {
            return { level: label };
          }
        },
        asObject: true,
        write: (logObject) => {
          const { level, [MESSAGE_KEY]: message } = logObject as LogObject;
          const { tillId } = getAuth();

          const enrichedLogObject: LogObject = {
            ...(logObject as LogObject),
            env: PWA_ENV,
            till_id: tillId
          };

          this.consoleLog({ ...enrichedLogObject }); // Log to console
          if (PWA_ENV !== "development") {
            // Log to Logtail
            this.logtail.log<LogProperties>(message, level, {
              ...enrichedLogObject
            });
          }
        }
      }
    });
  }

  /**
   * Logs a message to the console based on the log level.
   * @param logObject - The log object containing the log level and message.
   */
  private consoleLog(logObject: LogObject): void {
    const logMessage = `[${logObject.level}] ${logObject[MESSAGE_KEY]}`;

    if (this.shouldLogToConsole(logObject.level)) {
      switch (logObject.level) {
        case "debug":
          console.log(logMessage, logObject);
          break;
        case "info":
          console.info(logMessage, logObject);
          break;
        case "warn":
          console.warn(logMessage, logObject);
          break;
        case "error":
          console.error(logMessage, logObject);
          break;
        default:
          console.log(logMessage, logObject);
      }
    }
  }

  /**
   * Determines if the log should be output to the console based on the environment and log level.
   * @param level - The log level.
   * @returns `true` if the log should be output to the console, otherwise `false`.
   */
  private shouldLogToConsole(level: Pino.Level): boolean {
    return PWA_ENV !== "production" || ["error", "warn"].includes(level);
  }

  /**
   * Logs a debug message.
   * @param message - The message to log.
   * @param properties - Additional properties to log with the message.
   */
  debug(message: string, properties: LogProperties = {}): void {
    this.pinoLogger.debug(properties, message);
  }

  /**
   * Logs an info message.
   * @param message - The message to log.
   * @param properties - Additional properties to log with the message.
   */
  info(message: string, properties: LogProperties = {}): void {
    this.pinoLogger.info(properties, message);
  }

  /**
   * Logs a warning message.
   * @param message - The message to log.
   * @param properties - Additional properties to log with the message.
   */
  warn(message: string, properties: LogProperties = {}): void {
    this.pinoLogger.warn(properties, message);
  }

  /**
   * Logs an error message.
   * @param err - The error to log.
   * @param message - An optional message to log with the error.
   * @param properties - Additional properties to log with the error.
   * @param tags - Optional tags to associate with the error.
   */
  error(
    err: Error,
    message?: string,
    properties: LogProperties = {},
    tags?: ErrorTags
  ): void {
    this.pinoLogger.error({ err, ...properties }, message || err.message);
    if (PWA_ENV === "production") {
      captureException(err, { tags, extra: properties });
    }
  }

  /**
   * Flushes the logtail buffer, ensuring all logs are sent.
   */
  async flush(): Promise<void> {
    await this.logtail.flush();
  }
}

export const logger = new Logger(LOG_LEVEL);
