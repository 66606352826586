import { faBan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { type PropsWithChildren, useEffect } from "react";
import { useSpring, animated } from "@react-spring/web";

import { Button } from "~/components";
import { T } from "@repo/transifex";

const ANIMATION_DURATION = 3000;

type ScreenErrorProps = PropsWithChildren<{
  onClose: () => void;
  withAnimation?: boolean;
}>;

export const ScreenError = ({
  onClose,
  children,
  withAnimation = true
}: ScreenErrorProps) => {
  const props = useSpring({
    from: { width: withAnimation ? "0%" : "100%" },
    to: { width: "100%" },
    config: {
      duration: withAnimation ? ANIMATION_DURATION : 0,
      friction: 120,
      tension: 120
    },
    onRest: withAnimation ? onClose : undefined
  });

  useEffect(() => {
    const audioPlayer = new Audio("/audio/ticket-validation-fail.wav");
    audioPlayer.play();
  }, []);

  return (
    <div className="flex h-full flex-col items-center justify-center bg-danger-main">
      <FontAwesomeIcon icon={faBan} fontSize={250} className="text-white" />

      <div className="flex flex-col items-center justify-center gap-4 pb-12 pt-6">
        {children}
      </div>

      <Button
        className="relative overflow-hidden !rounded-xl !bg-white/10 !px-32 shadow-md"
        variant="danger"
        size="large"
        onClick={onClose}
      >
        <animated.div
          className="absolute inset-y-0 left-0 h-full bg-white/30"
          style={{ ...props }}
        />
        <div className="relative">
          <T _str="Close" />
        </div>
      </Button>
    </div>
  );
};

export const ScreenErrorTitle = ({ children }: PropsWithChildren) => {
  return <p className="text-7xl font-semibold text-white">{children}</p>;
};

export const ScreenErrorText = ({ children }: PropsWithChildren) => {
  return <p className="text-2xl text-secondary-light">{children}</p>;
};
