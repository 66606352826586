import { useParams } from "react-router-dom";

import { useT } from "@repo/transifex";
import { ReportStatus } from "~/components";
import { useSalesReportCombined } from "~/hooks/queries/reportsCombined";

import {
  InfoText,
  InfoTextWithPriceFormat,
  SubtitleText,
  TitleText,
  mapNestedValues
} from "./ReportList";

export const ReportCombinedList = () => {
  const t = useT();
  const { sessionId: startDateSeconds } = useParams();

  const { data: reportCombined, status: reportCombinedStatus } =
    useSalesReportCombined(Number(startDateSeconds ?? 0));

  if (reportCombinedStatus !== "success") {
    return <ReportStatus status={reportCombinedStatus} />;
  }

  const receiptReportCombined = reportCombined.receipt;

  if (!receiptReportCombined) {
    return <ReportStatus status="empty" />;
  }

  return (
    <div className="mb-5">
      <TitleText title={t("Combined Sales overview")} />

      {receiptReportCombined.totalSales.values.map(({ currency, value }) => (
        <InfoTextWithPriceFormat
          currency={currency}
          key={`${currency}-sale`}
          title={t("Total sales")}
          value={value}
        />
      ))}

      {receiptReportCombined.averageSales.values.map(({ currency, value }) => (
        <InfoTextWithPriceFormat
          currency={currency}
          key={`${currency}-avg`}
          title={t("Average")}
          value={value}
        />
      ))}

      {receiptReportCombined.standardVAT.values.map(
        ({ currency, values: vats }) =>
          vats.map(({ percentage, value: vat }) => (
            <InfoTextWithPriceFormat
              currency={currency}
              key={`${currency}-${percentage}-vat`}
              percentage={percentage}
              title={t("VAT")}
              value={vat}
            />
          ))
      )}

      <InfoText
        title={receiptReportCombined.unitSold.label}
        value={receiptReportCombined.unitSold.value}
      />

      {receiptReportCombined.totalProductsSales.values.map(
        ({ currency, value }) => (
          <InfoTextWithPriceFormat
            currency={currency}
            key={`${currency}-products-sales`}
            title={receiptReportCombined.totalProductsSales.label}
            value={value}
          />
        )
      )}

      {Object.values(receiptReportCombined.sections.tills).map((c) => {
        if (typeof c === "string") return null;
        return c.values.map((v) => (
          <div className="mb-2" key={`${v.currency}-avg`}>
            <SubtitleText
              className="mt-3"
              title={t("{tillName} - sales ({currency})", {
                tillName: c.label,
                currency: v.currency ?? ""
              })}
            />

            <InfoTextWithPriceFormat
              currency={v.currency}
              title={t("Total sales")}
              value={v.value}
            />
            {v.unitSold ? (
              <InfoText title={t("Units sold")} value={v.unitSold} />
            ) : null}
          </div>
        ));
      })}

      <TitleText className="mt-3" title={t("Transaction")} />
      <InfoText
        title={t("Nr. of transactions")}
        value={receiptReportCombined.totalOrders.value}
      />

      {receiptReportCombined.refund.values.map(({ currency, value }) => (
        <InfoTextWithPriceFormat
          currency={currency}
          key={`${currency}-refund`}
          title={t("Refunds")}
          value={value}
        />
      ))}

      <InfoText
        title={t("Nr. of refunds")}
        value={receiptReportCombined.refundNumber.value}
      />

      {receiptReportCombined.cancelled.values.map(({ currency, value }) => (
        <InfoTextWithPriceFormat
          currency={currency}
          key={`${currency}-cancelled`}
          title={t("Cancelled orders")}
          value={value}
        />
      ))}

      <InfoText
        title={t("Nr. of cancelled orders")}
        value={receiptReportCombined.cancelledNumber.value}
      />

      {receiptReportCombined.discounts?.values.map(({ currency, value }) => (
        <InfoTextWithPriceFormat
          currency={currency}
          key={`${currency}-discount`}
          title={t("Discounts")}
          value={value}
        />
      ))}

      <InfoText
        title={t("Nr. of discounts")}
        value={receiptReportCombined.discountNumber?.value}
      />

      <TitleText className="mt-3" title={t("Payment methods")} />

      {mapNestedValues(receiptReportCombined.sections, [
        "paymentsMethods",
        "total"
      ])}

      {mapNestedValues(receiptReportCombined.sections, [
        "paymentsMethods",
        "card"
      ])}

      {mapNestedValues(receiptReportCombined.sections, [
        "paymentsMethods",
        "mobilepay"
      ])}

      {mapNestedValues(receiptReportCombined.sections, [
        "paymentsMethods",
        "swish"
      ])}

      {mapNestedValues(receiptReportCombined.sections, [
        "paymentsMethods",
        "cash"
      ])}

      {mapNestedValues(receiptReportCombined.sections, [
        "paymentsMethods",
        "billing"
      ])}

      <TitleText className="mt-3" title={t("Sales by categories")} />
      {Object.values(receiptReportCombined.sections.categories).map((c) => {
        if (typeof c === "string") return null;
        return c.values.map((v) => (
          <div className="mb-2" key={`${v.currency}-avg`}>
            <SubtitleText title={`${c.label} (${v.currency ?? ""})`} />

            <InfoTextWithPriceFormat
              currency={v.currency}
              title={t("Total sales")}
              value={v.value}
            />
            {v.unitSold ? (
              <InfoText title={t("Units sold")} value={v.unitSold} />
            ) : null}
          </div>
        ));
      })}

      <TitleText className="mt-3" title={t("Sales per users")} />
      {Object.values(receiptReportCombined.sections.profiles).map((c) => {
        if (typeof c === "string") return null;
        return c.values.map((v) => (
          <div className="mb-2" key={`${v.currency}-avg`}>
            <SubtitleText title={`${c.label} (${v.currency ?? ""})`} />

            <InfoTextWithPriceFormat
              currency={v.currency}
              title={t("Total sales")}
              value={v.value}
            />
            {v.unitSold ? (
              <InfoText title={t("Units sold")} value={v.unitSold} />
            ) : null}
          </div>
        ));
      })}
    </div>
  );
};
