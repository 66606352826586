import { devtools, persist } from "zustand/middleware";
import { createStore } from "zustand/vanilla";

import type { AuthStore } from "./store.types";

const INITIAL_STATE: AuthStore = {
  status: "none",
  token: null,
  partnerId: null,
  tillId: null,
  moduleId: null,
  schoolId: null,
  userId: null,
  profileId: null,
  childId: null,
  language: null,
  locale: null,
  country: null,
  isKanplaAdmin: false,
  deviceTillId: null,
  isOnline: true
};

const authStore = createStore<AuthStore>()(
  devtools(persist(() => INITIAL_STATE, { name: "auth-store" }))
);

export { INITIAL_STATE, authStore };
