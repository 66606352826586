import {
  FontAwesomeIcon,
  type FontAwesomeIconProps
} from "@fortawesome/react-fontawesome";

type HelpItemProps = {
  index: number;
  title: string;
  description: string;
  icon: FontAwesomeIconProps["icon"];
};

export const HelpItem = ({
  index,
  title,
  description,
  icon
}: HelpItemProps) => {
  return (
    <div className="flex w-3/4 flex-col items-center justify-center md:w-1/5">
      <div className="relative flex h-60 w-full items-center justify-center rounded-2xl bg-green-200">
        <span className="absolute left-3 top-3 size-9 rounded-full bg-white text-center text-2xl font-bold drop-shadow-lg">
          {index}
        </span>
        <FontAwesomeIcon icon={icon} size="5x" />
      </div>
      <div className="mt-6 max-w-full text-center">
        <p className="text-3xl font-bold">{title}</p>
        <p className="font-light">{description}</p>
      </div>
    </div>
  );
};
