import { faInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { OrderWithLines } from "@kanpla/api-contract";
import { useEffect, useState } from "react";

import { T, useT } from "@repo/transifex";
import { refundActions } from "~/providers/store/refund";
import type { FormattedOrderLine } from "~/utils/refund-line-formatter.ts";

import { Button } from "../../Button";
import { CheckboxButton } from "../../CheckboxButton";
import type { DialogProps } from "../../Dialog";
import { Dialog } from "../../Dialog";

type DialogChooseOrderLinesProps = {
  order: OrderWithLines;
  formattedOrderLines: FormattedOrderLine[];
  onConfirm: (orderLines?: FormattedOrderLine[]) => void;
  isHybridBilled?: boolean;
} & Partial<DialogProps>;

export const DialogChooseOrderLines = ({
  order,
  formattedOrderLines,
  onConfirm,
  isHybridBilled,
  ...dialogProps
}: DialogChooseOrderLinesProps) => {
  const t = useT();

  const [selectedOrderLines, setSelectedOrderLines] = useState<string[]>([]);

  const paymentMethods = (() => {
    if (order.payments) {
      return order.payments
        .map((payment) => {
          switch (payment.paymentProvider) {
            case "mobilepay_own":
              return "MobilePay";
            case "swish":
              return "Swish";
            case "card":
              return t("Card");
            case "billing":
              return t("Billing");
            case "cash":
              return t("Cash");
            case "credit":
              return t("Credit");
            default:
              return "";
          }
        })
        .join(", ");
    }
    return "";
  })();

  useEffect(() => {
    // If the order contains some hybrid billing, skip order lines selection
    if (formattedOrderLines.length === 1 || isHybridBilled) {
      onConfirm(formattedOrderLines);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- to avoid infinite re-renders
  }, []);

  const isBilling =
    order.payments?.some((payment) => payment.paymentProvider === "billing") ||
    false;

  return (
    <Dialog size="md" {...dialogProps}>
      <div className="flex flex-col items-center justify-center text-center">
        <div className="mb-8 flex w-full flex-col gap-3 px-8 py-5">
          <h2 className="text-xl font-bold text-text-primary">
            <T _str="Refund" />
          </h2>
          <p>
            <T _str="Choose which items to refund or refund whole order" />
          </p>
          <Button
            className="w-fit"
            onClick={() => {
              setSelectedOrderLines(formattedOrderLines.map((o) => o.id));
            }}
            variant="secondary"
          >
            <T _str="Choose all" />
          </Button>
          <div className="border-b border-divider-main">
            {formattedOrderLines.map((o) => {
              return (
                <div
                  className="flex items-center justify-between gap-3 border-t border-divider-main py-4"
                  key={o.id}
                >
                  {o.refunded ? (
                    <div className="flex items-center gap-3">
                      <div className="flex items-center gap-2 font-semibold text-danger-dark">
                        <FontAwesomeIcon icon={faInfoCircle} />
                        <p>
                          <T _str="Refunded" />
                        </p>
                      </div>
                      <p className="text-text-disabled">{o.name}</p>
                    </div>
                  ) : (
                    <CheckboxButton
                      checked={selectedOrderLines.includes(o.id)}
                      className="w-full"
                      label={o.name}
                      onClick={(checked) => {
                        setSelectedOrderLines((prev) =>
                          checked
                            ? [...prev, o.id]
                            : prev.filter((id) => id !== o.id)
                        );
                      }}
                    />
                  )}

                  <p>{o.formattedPrice}</p>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex w-full flex-col gap-5 bg-background-secondary px-8 py-5">
          <div className="flex items-center justify-between">
            <p>
              <T _str="Payment method" />
            </p>
            <p>{paymentMethods}</p>
          </div>

          <div className="flex gap-3">
            <Button
              onClick={() => dialogProps.onOutsideClick?.()}
              variant="danger"
            >
              <T _str="Cancel" />
            </Button>

            <Button
              className="flex-1"
              onClick={() => {
                refundActions.setOrderLines({
                  order,
                  selectedOrderLines,
                  formattedOrderLines
                });

                onConfirm();
              }}
            >
              {isBilling ? (
                <T _str="Confirm" />
              ) : (
                <T _str="Choose refund method" />
              )}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
