/**
 * Used to uniquely identify a timeout
 * @private
 */
const TIMEOUT = Symbol("TIMEOUT");

/**
 * Attach a timeout to any promise, if the timeout resolves first ignore the
 * original promise and throw an error
 * @param promise The promise to attach a timeout to
 * @param options The options to use
 * @param options.ms The number of milliseconds to wait before timing out
 * @param options.controller An AbortController to abort the original promise
 * @returns The result of the promise
 * @throws TimeoutError If the timeout resolves first
 * @example
 * try {
 *   let result = await timeout(
 *     fetch("https://example.com"),
 *     { ms: 100 }
 *   );
 * } catch (error) {
 *   if (error instanceof TimeoutError) {
 *     // Handle timeout
 *   }
 * }
 * @example
 * try {
 *   let controller = new AbortController();
 *   let result = await timeout(
 *     fetch("https://example.com", { signal: controller.signal }),
 *     { ms: 100, controller }
 *   );
 * } catch (error) {
 *   if (error instanceof TimeoutError) {
 *     // Handle timeout
 *   }
 * }
 */
export function timeout<Value>(
  promise: Promise<Value>,
  options: { controller?: AbortController; ms: number }
): Promise<Value> {
  return new Promise((resolve, reject) => {
    let timer: ReturnType<typeof setTimeout> | undefined;

    Promise.race([
      promise,
      new Promise((resolve) => {
        timer = setTimeout(() => {
          resolve(TIMEOUT);
        }, options.ms);
      })
    ])
      .then((res) => {
        if (timer) clearTimeout(timer);

        if (res === TIMEOUT) {
          if (options.controller) options.controller.abort();
          reject(
            new TimeoutError(`Timed out after ${options.ms.toString()}ms`)
          );
          return;
        }

        resolve(res as Awaited<Value>);
      })
      .catch((error: unknown) => {
        if (timer) clearTimeout(timer);

        reject(error instanceof Error ? error : new Error(String(error)));
      });
  });
}

/**
 * An error thrown when a timeout occurs
 * @example
 * try {
 *   let result = await timeout(fetch("https://example.com"), { ms: 100 });
 * } catch (error) {
 *   if (error instanceof TimeoutError) {
 *    // Handle timeout
 *   }
 * }
 */
export class TimeoutError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "TimeoutError";
  }
}
