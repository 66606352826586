import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  createContext,
  type PropsWithChildren,
  useContext,
  useState
} from "react";
import { createStore, useStore, type StoreApi } from "zustand";

import { useCurrentTill } from "~/api/tills/hooks/use-till";
import { type Till } from "~/api/tills/types";
import { useKioskSessionStatus } from "~/stores/kiosk-session/hooks/use-kiosk-session";
import { ScreenLoaderLogo } from "~/components/screen/screen-loader-logo";

type KioskStore = {
  till: Extract<Till, { type: "kiosk" }>;
};

const KioskStoreContext = createContext<StoreApi<KioskStore> | null>(null);

export const KioskLayoutPage = () => {
  const { pathname } = useLocation();
  const { data: till, status } = useCurrentTill();
  const kioskSessionStatus = useKioskSessionStatus();

  if (status === "pending") {
    return <ScreenLoaderLogo />;
  }

  if (status === "error") {
    return <Navigate to="/menu" />;
  }

  if (till.type !== "kiosk" || !till.sessionId) {
    return <Navigate to="/menu" />;
  }

  if (pathname !== "/kiosk" && kioskSessionStatus !== "active") {
    return <Navigate to="/kiosk" replace />;
  }

  return (
    <KioskStoreProvider till={till}>
      <Outlet />
    </KioskStoreProvider>
  );
};

const KioskStoreProvider = ({
  till,
  children
}: PropsWithChildren<KioskStore>) => {
  // eslint-disable-next-line react/hook-use-state -- useState for one time initialisation (https://tkdodo.eu/blog/use-state-for-one-time-initializations)
  const [store] = useState(() =>
    createStore(() => ({
      till
    }))
  );

  return (
    <KioskStoreContext.Provider value={store}>
      {children}
    </KioskStoreContext.Provider>
  );
};

export const useKioskStore = <T,>(selector: (state: KioskStore) => T) => {
  const store = useContext(KioskStoreContext);
  if (!store) {
    throw new Error("Missing KioskStoreProvider");
  }
  return useStore(store, selector);
};
