import { type MouseEventHandler } from "react";

import { QuantityInputControl } from "../../ui/quantity-input-control/quantity-input-control";
import { ProductImage } from "../../ui/product-image/product-image";

type ProductItemLineProps = {
  imageUrl: string | null;
  name: string;
  description?: string;
  price: string;
  quantity: number;
  onQuantityIncrease: MouseEventHandler<HTMLButtonElement>;
  onQuantityDecrease: MouseEventHandler<HTMLButtonElement>;
};

export const ProductItemLine = ({
  imageUrl,
  name,
  description,
  price,
  quantity,
  onQuantityIncrease,
  onQuantityDecrease
}: ProductItemLineProps) => {
  return (
    <div className="flex items-center gap-6 text-kiosk-text-primary">
      <ProductImage image={imageUrl} className="!h-36 !w-52" />

      <div className="flex flex-1 flex-col">
        <span className="line-clamp-2 text-ellipsis text-4xl font-semibold">
          {name}
        </span>

        {description ? (
          <span className="line-clamp-1 text-ellipsis text-3xl font-normal italic">
            {description}
          </span>
        ) : null}
      </div>

      <QuantityInputControl
        quantity={quantity}
        onIncrementClick={onQuantityIncrease}
        onDecrementClick={onQuantityDecrease}
        variant="secondary"
      />

      <span className="block w-44 text-right text-4xl font-extrabold">
        {price}
      </span>
    </div>
  );
};
