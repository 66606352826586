import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";

import { T, useT } from "@repo/transifex";
import {
  Button,
  DialogError,
  DialogLoading,
  Field,
  LayoutError,
  LayoutLogo
} from "~/components";
import { useCurrentTill } from "~/api/tills/hooks/use-till";
import { useLogin } from "~/hooks/queries/auth";
import { useDeviceData } from "~/providers/app";
import { useAuth } from "~/providers/store/auth";
import { isApiError } from "@repo/system";

type LoginInputs = {
  email: string;
  password: string;
};

const LoginPage = () => {
  const t = useT();

  const { deviceId, tillId: deviceTillId } = useDeviceData();
  const { mutate: login, status, error, reset } = useLogin();
  const { status: authStatus, tillId } = useAuth();
  const {
    data: till,
    status: tillStatus,
    error: tillError,
    refetch: refetchTill
  } = useCurrentTill();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<LoginInputs>();

  // if the user is already authenticated wait to get the till data and don't redirect anywhere
  if (authStatus !== "none" && tillId && tillStatus === "pending") {
    return <DialogLoading title={t("Loading till")} />;
  }

  if (tillError) {
    return (
      <DialogError
        error={
          tillError?.message ?? t("There was an unknown error loading till")
        }
      >
        <Button
          onClick={() => {
            void refetchTill();
          }}
        >
          <T _str="Try again" />
        </Button>
      </DialogError>
    );
  }

  // the app has been hard-closed while ordering using a self-service till.
  // redirect to /pos path. The route component __app.pos will handle the redirect.
  if (authStatus !== "none" && till?.type === "self-service") {
    return <Navigate replace to="/pos" />;
  }

  if (authStatus !== "none") {
    return <Navigate replace to="/menu" />;
  }

  let errorMessage;
  if (status === "error") {
    if (isApiError(error)) {
      errorMessage = error.body.message;
    } else {
      errorMessage = t("Something went wrong");
    }
  } else {
    errorMessage = undefined;
  }

  if (status === "error" && isApiError(error) && error.status !== 401) {
    return (
      <LayoutError error={errorMessage}>
        <Button
          onClick={() => {
            reset();
          }}
        >
          <T _str="Go back" />
        </Button>
      </LayoutError>
    );
  }

  return (
    <LayoutLogo>
      <div className="flex w-full flex-col items-center justify-center">
        <h1 className="text-3xl">
          <T _str="Welcome back!" />
        </h1>

        <p className="text-text-secondary">
          <T _str="Log in to your account" />
        </p>

        <form
          autoComplete="on"
          className="my-8 w-full max-w-xs"
          onSubmit={handleSubmit(({ email, password }) => {
            if (!deviceId) {
              throw new Error("Device ID is missing");
            }

            login({
              email,
              password,
              deviceId,
              tillId: deviceTillId ?? undefined
            });
          })}
        >
          <Field
            error={errors.email?.message ?? errorMessage}
            label={t("Email")}
            placeholder={t("Email...")}
            {...register("email", { required: t("Email is required") })}
          />

          <Field
            error={errors.password?.message ?? errorMessage}
            label={t("Password")}
            placeholder={t("Password...")}
            type="password"
            {...register("password", { required: t("Password is required") })}
          />

          <Button
            className="mt-8 w-full"
            loading={status === "pending"}
            type="submit"
          >
            <T _str="Login" />
          </Button>
        </form>
      </div>
    </LayoutLogo>
  );
};

export { LoginPage };
