import { modalVariantsStore } from "./store";
import type { VariantStep, ModalVariantsStore } from "./store.types";

// Selectors
const amonutSelector = (
  state: ModalVariantsStore
): ModalVariantsStore["amount"] => state.amount;
const variantsSelector = (
  state: ModalVariantsStore
): ModalVariantsStore["variants"] => state.variants;
const currentVariantStepSelector = (
  state: ModalVariantsStore
): VariantStep | null => state.variants.find((step) => !step.completed) ?? null;
const nextVariantStepSelector = (
  state: ModalVariantsStore
): VariantStep | null => {
  const currentStepIndex = state.variants.findIndex((step) => !step.completed);
  if (currentStepIndex === -1) return null;

  const nextStepIndex = currentStepIndex + 1;
  return state.variants[nextStepIndex] ?? null;
};
const getSteps = (state: ModalVariantsStore) => {
  if (state.variants.length === 0) {
    return {
      steps: [],
      currentStep: null,
      nextStep: null
    };
  }

  const currentStepIndex = state.variants.findIndex((step) => !step.completed);
  if (currentStepIndex === -1) {
    return {
      steps: state.variants,
      currentStep: null,
      nextStep: null
    };
  }

  return {
    steps: state.variants,
    currentStep: state.variants[currentStepIndex],
    nextStep: state.variants[currentStepIndex + 1] ?? null
  };
};

function getAmount(): ModalVariantsStore["amount"] {
  return amonutSelector(modalVariantsStore.getState());
}

function getVariants(): ModalVariantsStore["variants"] {
  return variantsSelector(modalVariantsStore.getState());
}

function getCurrentVariantStep(): VariantStep | null {
  return currentVariantStepSelector(modalVariantsStore.getState());
}

function getNextVariantStep(): VariantStep | null {
  return nextVariantStepSelector(modalVariantsStore.getState());
}

export {
  getAmount,
  getVariants,
  getCurrentVariantStep,
  getNextVariantStep,
  getSteps
};
