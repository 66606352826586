import {
  faDeleteLeft,
  type IconDefinition
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";

import { priceFormatter } from "@repo/system";
import { useBasketProducts } from "~/providers/app";
import { useAuth } from "~/providers/store/auth";

import { calculateValue } from "./calculateValue";

const calculatorButtons: ("00" | number | IconDefinition)[] = [
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  "00",
  0,
  faDeleteLeft
];

export type NumPadProps = {
  variant?: "currency" | "percentage" | "amount";
  value: number;
  setValue: (value: number) => void;
  placeholder?: number;
  children?: React.ReactNode;
  label?: string;
};

export const NumPad = ({
  variant = "currency",
  value,
  setValue,
  placeholder = 0,
  children,
  label
}: NumPadProps) => {
  const { locale } = useAuth();
  const { meta } = useBasketProducts();

  const currency = meta.currency;

  return (
    <div className="flex w-full flex-col px-11 py-8">
      <div className="mb-8 flex w-full flex-col items-center justify-center">
        {label ? <p className="text-text-disabled">{label}</p> : null}
        <p
          className={clsx(
            "col-span-2 text-center text-5xl font-medium",
            value > 0 ? "text-text-primary" : "text-text-disabled"
          )}
        >
          {variant === "currency" &&
            priceFormatter({
              value: (value || placeholder) * 100,
              currency,
              locale,
              options: {
                showDecimals: true,
                hideCurrency: true
              }
            })}
          {variant === "percentage" && `${value}%`}
          {variant === "amount" && value}
        </p>

        {variant === "currency" && (
          <p className="text-3xl uppercase text-text-disabled">{currency}</p>
        )}
      </div>

      <div className="m-auto grid w-full grid-cols-3 grid-rows-4 gap-6">
        {calculatorButtons.map((input) => (
          <SquareButton
            className={clsx(input === "00" && "!text-text-disabled")}
            key={`key-${typeof input === "object" ? input.iconName : input}`}
            onClick={() => {
              const inputType = typeof input === "object" ? "cancel" : input;
              const newValue = calculateValue(value, inputType, variant);
              setValue(newValue);
            }}
          >
            {typeof input === "object" ? (
              <div className="flex size-full items-center justify-center">
                <FontAwesomeIcon icon={input} size="sm" />
              </div>
            ) : (
              input
            )}
          </SquareButton>
        ))}
        {children}
      </div>
    </div>
  );
};

const SquareButton = ({
  className,
  ...rest
}: JSX.IntrinsicElements["button"]) => {
  return (
    <button
      className={clsx(
        "flex h-24 items-center justify-center rounded-md border border-text-primary text-5xl text-text-primary",
        className
      )}
      type="button"
      {...rest}
    />
  );
};
