import {
  faUtensils,
  type IconDefinition
} from "@fortawesome/pro-solid-svg-icons";
import { clsx } from "clsx";
import { type PropsWithChildren } from "react";

type ProductImageProps = PropsWithChildren & {
  image: string | null;
  className?: string;
};

export const ProductImage = ({
  image,
  children,
  className
}: ProductImageProps) => {
  return (
    <div
      className={clsx(
        "flex h-56 w-full flex-col rounded-3xl bg-kiosk-background-secondary bg-cover bg-center",
        className
      )}
      style={
        !image
          ? {
              backgroundImage: `url(${getFallbackBgFrom(
                faUtensils,
                "#DEEBDE"
              )})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "30%"
            }
          : { backgroundImage: `url(${image})` }
      }
    >
      {children}
    </div>
  );
};

function getFallbackBgFrom(iconDefinition: IconDefinition, color: string) {
  const [width, height, , , svgPathData] = iconDefinition.icon;
  const svgIcon = `
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}" fill="${color}">
    <path d="${svgPathData}"/>
  </svg>
  `;
  const encodedSvg = encodeURIComponent(svgIcon);
  const fallbackBg = `data:image/svg+xml,${encodedSvg}`;

  return fallbackBg;
}
