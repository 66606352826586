import { type IconDefinition } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const ModalIcon = ({ icon }: { icon: IconDefinition }) => (
  <FontAwesomeIcon
    icon={icon}
    fontSize={240}
    className="text-kiosk-text-error"
  />
);
