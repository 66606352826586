import { useParams } from "react-router-dom";
import { useState } from "react";
import { atom, useAtom } from "jotai";

import { T } from "@repo/transifex";
import { getErrorMessage } from "@repo/system";
import { useCloseTillSession, useSession } from "~/hooks/queries/sessions";
import { useSessionOrders } from "~/hooks/queries/orders";
import { useAppContext } from "~/providers/app";
import { Button, Dialog, DialogError, DialogLoading } from "~/components";

export const closeCashBalanceAtom = atom(0);

export const HeaderButtonCloseSession = () => {
  const { sessionId, tillId } = useParams();

  const {
    hardware: { printer }
  } = useAppContext();

  const { data: session, status: sessionStatus } = useSession(sessionId);
  const {
    mutate: closeTillSession,
    status,
    error,
    reset
  } = useCloseTillSession();
  const { data: orders, status: ordersStatus } = useSessionOrders(sessionId);

  const [closeCashBalance, setCloseCashBalance] = useAtom(closeCashBalanceAtom);
  const [open, setOpen] = useState(false);

  if (ordersStatus !== "success" || sessionStatus !== "success") return null;

  const handleConfirm = () => {
    if (!sessionId || !tillId) return;

    const startedCashBalance =
      session.startedFlow === "manual"
        ? session.startedMeta.cashBalance?.[orders.currency] ?? 0
        : 0;
    const expectedUnit = orders.totalCashPriceUnit + startedCashBalance;

    closeTillSession({
      closeCashBalance,
      sessionId,
      tillId,
      expectedCashBalance: expectedUnit,
      printerId: printer?.identifier,
      currency: orders.currency
    });

    setCloseCashBalance(0);
  };

  return (
    <>
      <Button
        className="ml-auto"
        onClick={() => {
          setOpen(true);
        }}
        type="submit"
        variant="danger"
      >
        <T _str="Close register" />
      </Button>

      <Dialog
        onOutsideClick={() => {
          setOpen(false);
        }}
        open={open}
        size="md"
      >
        <div className="flex flex-col items-center p-8">
          <p className="mb-8 text-xs uppercase text-danger-dark">
            <T _str="Close register" />
          </p>

          <div className="flex flex-col items-center gap-y-2 text-center">
            <p className="text-xl">
              <T _str="Do you wish to continue?" />
            </p>

            <p className="w-5/6 text-xs">
              <T _str="If you're finished you can confirm and close register" />
            </p>
          </div>

          <div className="mt-12 flex justify-between gap-x-5">
            <Button
              onClick={() => {
                setOpen(false);
              }}
              variant="danger-light"
            >
              <T _str="Cancel" />
            </Button>

            <Button onClick={handleConfirm} variant="primary-light">
              <T _str="Confirm" />
            </Button>
          </div>
        </div>
      </Dialog>

      {status === "pending" && <DialogLoading />}
      {status === "error" && (
        <DialogError
          error={getErrorMessage(error)}
          onOutsideClick={() => {
            reset();
            setOpen(false);
          }}
        />
      )}
    </>
  );
};
