import { clsx } from "clsx";
import { useState } from "react";

import { T } from "@repo/transifex";
import { useProfiles } from "~/hooks/queries/profiles";
import { useAuth } from "~/providers/store/auth";

import { Button } from "./Button";
import { InitialsCircle } from "./InitialsCircle";
import { ProfilePin } from "./Pin";
import { LoadingSpinner } from "./LoadingSpinner";

type ProfileLogoutProps = {
  onPinSuccess?: () => void;
};

export const ProfileLogout = ({ onPinSuccess }: ProfileLogoutProps) => {
  const { profileId } = useAuth();
  const { data: profiles, status } = useProfiles();
  const [selectedProfileId, setSelectedProfileId] = useState<string | null>(
    profileId
  );

  if (status === "pending") {
    return (
      <div className="flex h-full items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  if (status === "error") {
    return <T _str="Could not load profiles" />;
  }

  return (
    <div className="flex">
      <div className="flex flex-1 flex-col gap-4 overflow-scroll bg-background-secondary p-6">
        <p className="text-lg font-semibold">
          <T _str="Choose user" />
        </p>

        {profiles
          .sort((a) => (a.id === profileId ? -1 : 1))
          .map(({ name, id }) => {
            return (
              <Button
                className={clsx({
                  "!bg-text-primary": selectedProfileId === id
                })}
                key={id}
                onClick={() => {
                  setSelectedProfileId(id);
                }}
                variant={selectedProfileId === id ? "primary" : "light"}
              >
                <div className="flex w-full items-center gap-4">
                  <InitialsCircle
                    className={clsx({
                      "!bg-text-primary !text-primary-contrast":
                        selectedProfileId !== id,
                      "!bg-primary-contrast !text-text-primary":
                        selectedProfileId === id
                    })}
                    name={name}
                  />
                  {name}
                </div>
              </Button>
            );
          })}
      </div>
      <div className="flex flex-1 flex-col items-center gap-4 p-6">
        <T _str="Enter pin" />

        {selectedProfileId ? (
          <ProfilePin
            profileId={selectedProfileId}
            onPinSuccess={onPinSuccess}
          />
        ) : null}
      </div>
    </div>
  );
};
