import type { ReactNode } from "react";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";

type ProfileCardProps = {
  name: string;
  className?: string;
  nameClassName?: string;
};

export const ProfileCard = ({
  name,
  nameClassName,
  className
}: ProfileCardProps) => {
  return (
    <ProfileCardLayout className={className}>
      <div className="flex cursor-pointer flex-col items-center gap-y-4">
        <FontAwesomeIcon fontSize={55} icon={faUser} />

        <h2 className={clsx("text-2xl font-bold", nameClassName)}>{name}</h2>
      </div>
    </ProfileCardLayout>
  );
};

export const ProfileCardSkeleton = () => (
  <ProfileCardLayout className="animate-pulse" />
);

const ProfileCardLayout = ({
  children,
  className
}: {
  children?: ReactNode;
  className?: string;
}) => (
  <div
    className={clsx(
      "relative flex min-h-[200px] min-w-[260px] flex-col items-center justify-center rounded-lg bg-gray-200 px-3 py-4",
      className
    )}
  >
    {children}
  </div>
);
