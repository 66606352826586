import type { useT } from "@repo/transifex";

export const calendarFormatter = (t: ReturnType<typeof useT>) => {
  return {
    sameDay: `[${t("today")}]`,
    nextDay: `[${t("tomorrow")}]`,
    nextWeek: "dddd",
    lastDay: `[${t("yesterday")}]`,
    lastWeek: `[${t("last")}] dddd`,
    sameElse: "dddd, D/M"
  };
};
