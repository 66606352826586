import type {
  HybridBillingDiscount,
  AllowanceDetail
} from "@kanpla/api-contract";

import type { ProductBasic } from "@repo/types";
import type { BasketStore } from "~/providers/store/basket";

type CalculateAllowanceArgs = {
  allowances: AllowanceDetail[];
  discounts: HybridBillingDiscount[];
  basket: BasketStore["products"];
  allProducts: ProductBasic[];
};

/** Function that recalculates the allowance based on the products in the basket
 * @returns {AllowanceUsage[]} the updated allowance
 */
export function calculateAllowance({
  allowances,
  discounts,
  basket,
  allProducts
}: CalculateAllowanceArgs): AllowanceDetail[] {
  if (!allowances.length) return [];

  return allowances.map((allowance) => {
    const discount = discounts.find((d) => d.id === allowance.discountId);

    if (!discount) return allowance;

    const productIds = allProducts
      .filter((product) => product.discount?.id === discount.id)
      .map((product) => product.id);

    if (!productIds.length) return allowance;

    const quantityInBasket = basket.reduce((acc, item) => {
      if (productIds.includes(item.productId)) {
        return acc + item.count;
      }

      return acc;
    }, 0);

    if (!quantityInBasket) return allowance;

    let remaining = 0;

    if (allowance.type === "unitPrice") {
      const appliedUnitPriceDiscount = basket.reduce((acc, item) => {
        if (productIds.includes(item.productId)) {
          const product = allProducts.find((p) => p.id === item.productId);
          if (!product) return acc;

          // only a type check, but it is not possible to have allowance with hybridBilling flat discount
          if (discount.amountType === "currency") return acc;

          const unitDiscountedPrice =
            (product.unitPrice * discount.amount) / 100;
          return acc + unitDiscountedPrice * item.count;
        }

        return acc;
      }, 0);

      remaining = allowance.remaining - appliedUnitPriceDiscount;
    }

    if (allowance.type === "numberOfProducts") {
      remaining = allowance.remaining - quantityInBasket;
    }

    return {
      ...allowance,
      isAllowanceOver: remaining <= 0,
      remaining: Math.max(0, remaining)
    };
  });
}
