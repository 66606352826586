import { T } from "@repo/transifex";
import {
  ScreenErrorText,
  ScreenErrorTitle,
  ScreenError
} from "~/components/screen/screen-error";
import { LoadingSpinner } from "~/components/LoadingSpinner";
import {
  ScreenSuccessTitle,
  ScreenSuccess,
  ScreenSuccessText
} from "~/components/screen/screen-success";
import { useCreateOrderSimple } from "~/api/orders/hooks/use-create-order-simple";
import { LayoutLock } from "~/components/layout/layout-lock";
import { useCardReader } from "~/hooks/useCardReader";
import { usePrintOrderReceipt } from "~/hooks/queries/orders";
import { useCurrentTill } from "~/api/tills/hooks/use-till";
import { logger } from "~/services/logger";
import { usePerformance } from "~/hooks/use-performance";

import { ScanCardSvg } from "../__app.employee-card/ScanCardSvg";

const SPANS = {
  CREATE_ORDER: "create_order",
  PRINT_RECEIPT: "print_receipt"
} as const;

export const SimpleCheckoutPage = () => {
  const { start, end } = usePerformance();
  const { data: till } = useCurrentTill();

  const { mutate: createOrder, status, reset } = useCreateOrderSimple();
  const { mutate: createReceipt } = usePrintOrderReceipt();

  const hasAutomaticReceiptPrint =
    till?.type === "simple-checkout" &&
    till.config.receiptPrintMode === "automatic";

  const operationName = hasAutomaticReceiptPrint
    ? "simple-checkout-automatic-receipt"
    : "simple-checkout";

  useCardReader({
    onScan: (parsedCode) => {
      start(operationName);
      start(SPANS.CREATE_ORDER);
      logger.info("scanned employee card", {
        operation_name: operationName,
        operation_status: "start",
        span_name: SPANS.CREATE_ORDER,
        span_status: "start"
      });

      if (status === "pending") {
        logger.debug("order creation is pending, skipping", {
          operation_name: operationName,
          span_name: SPANS.CREATE_ORDER,
          span_duration: end(SPANS.CREATE_ORDER),
          span_status: "cancelled",
          operation_status: "cancelled",
          operation_duration: end(operationName)
        });
        return;
      }

      logger.debug("call api to create simple order", {
        operation_name: operationName,
        span_name: SPANS.CREATE_ORDER
      });

      createOrder(parsedCode, {
        onSuccess: (order) => {
          logger.info("create order success", {
            order_id: order.id,
            operation_name: operationName,
            span_name: SPANS.CREATE_ORDER,
            span_duration: end(SPANS.CREATE_ORDER),
            span_status: "success"
          });

          if (
            till?.type === "simple-checkout" &&
            till.config.receiptPrintMode === "automatic"
          ) {
            start(SPANS.PRINT_RECEIPT);
            logger.debug("call api to print receipt", {
              operation_name: operationName,
              span_name: SPANS.PRINT_RECEIPT,
              span_status: "start"
            });
            createReceipt(
              {
                orderId: order.id,
                isTicket: till?.moduleConfig.hidePrices ?? false
              },
              {
                onSuccess: () => {
                  logger.info("receipt sent to printer", {
                    operation_name: operationName,
                    operation_status: "success",
                    operation_duration: end(operationName),
                    span_name: SPANS.PRINT_RECEIPT,
                    span_duration: end(SPANS.PRINT_RECEIPT),
                    span_status: "success"
                  });
                },
                onError: (error) => {
                  logger.error(error, "error printing receipt", {
                    operation_name: operationName,
                    operation_status: "error",
                    operation_duration: end(operationName),
                    span_name: SPANS.PRINT_RECEIPT,
                    span_duration: end(SPANS.PRINT_RECEIPT),
                    span_status: "error"
                  });
                }
              }
            );
          }
        },
        onError: (error) => {
          logger.error(error, "error creating order", {
            operation_name: operationName,
            operation_duration: end(operationName),
            operation_status: "error",
            span_name: SPANS.CREATE_ORDER,
            span_duration: end(SPANS.CREATE_ORDER),
            span_status: "error"
          });
        }
      });
    }
  });

  if (status === "error") {
    return (
      <ScreenError onClose={reset} withAnimation={false}>
        <ScreenErrorTitle>
          <T _str="Something went wrong" />
        </ScreenErrorTitle>

        <ScreenErrorText>
          <T _str="Please try again or ask staff for help" />
        </ScreenErrorText>
      </ScreenError>
    );
  }

  if (status === "success") {
    return (
      <ScreenSuccess onClose={reset}>
        <ScreenSuccessTitle>
          <T _str="Registration complete" />
        </ScreenSuccessTitle>

        <ScreenSuccessText>
          <T _str="Enjoy your meal!" />
        </ScreenSuccessText>
      </ScreenSuccess>
    );
  }

  return (
    <LayoutLock>
      {status === "pending" ? (
        <LoadingSpinner />
      ) : (
        <>
          <ScanCardSvg />

          <p className="text-center text-5xl font-bold">
            <T _str="Scan your card" />
          </p>
        </>
      )}
    </LayoutLock>
  );
};
