import { T } from "@repo/transifex";

const STEPS = [
  {
    id: 1,
    description: (
      <span>
        <T
          _str="Go to the device {settings} and open {bluetooth}"
          bluetooth={
            <span className="font-semibold">
              <T _str="Bluetooth" />
            </span>
          }
          settings={
            <span className="font-semibold">
              <T _str="Settings" />
            </span>
          }
        />
      </span>
    )
  },
  {
    id: 2,
    description: (
      <span>
        <T
          _str="Find the {device} and {connect}"
          connect={
            <span className="font-semibold">
              <T _str="connect" />
            </span>
          }
          device={
            <span className="font-semibold">
              <T _str="device name" />
            </span>
          }
        />
      </span>
    )
  },
  {
    id: 3,
    description: (
      <span>
        <T
          _str="Go to {pos} and open {device}"
          device={
            <span className="font-semibold">
              <T _str="Device Settings" />
            </span>
          }
          pos={<span className="font-semibold">Kanpla POS</span>}
        />
      </span>
    )
  },
  {
    id: 4,
    description: (
      <span>
        <T
          _str="Click {choose} and {connect}"
          choose={
            <span className="font-semibold">
              <T _str="Choose device" />
            </span>
          }
          connect={
            <span className="font-semibold">
              <T _str="connect" />
            </span>
          }
        />
      </span>
    )
  }
];

export const ConnectionGuide = () => {
  return (
    <div className="rounded-md bg-primary-main px-6 py-5 text-primary-contrast">
      <div className="mb-3">
        <p className="text-xs font-medium uppercase">
          <T _str="How to connect a cash drawer" />
        </p>
      </div>

      <div className="flex">
        {STEPS.map((step) => (
          <div className="group" key={`guide-step-${step.id}`}>
            <div className="flex items-center pb-2">
              <div className="aspect-square h-5 p-px">
                <div className="size-full rounded-full border-4 border-primary-contrast bg-primary-main" />
              </div>
              <div className="h-px w-full bg-primary-contrast group-last:hidden" />
            </div>
            <div className="pr-14">
              <p className="text-primary-contrast">{step.description}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
