import { type ClientInferRequest } from "@ts-rest/core";

import { client, getAuthHeader } from "../client";
import { type apiContract } from "../contract";

type CreateOrderSimpleRequest = ClientInferRequest<
  typeof apiContract.v1.pos.tills.createOrderSimple
>;

type CreateOrderSimpleArgs = CreateOrderSimpleRequest["params"] &
  CreateOrderSimpleRequest["body"];

export async function createOrderSimple({
  tillId,
  ...body
}: CreateOrderSimpleArgs) {
  const response = await client.v1.pos.tills.createOrderSimple({
    params: { tillId },
    body,
    headers: getAuthHeader()
  });

  if (response.status === 201) {
    return response.body;
  }

  throw response;
}
