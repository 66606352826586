import { useState } from "react";

import { T } from "@repo/transifex";
import { Button, KanplaLogo } from "~/components";
import { LicenseGuide } from "~/routes/license/LicenseGuide.tsx";
import { authActions } from "~/providers/store/auth";
import { useDeviceData } from "~/providers/app";
import { useCurrentTill } from "~/api/tills/hooks/use-till";

export const LicenseLayout = ({ children }: { children: React.ReactNode }) => {
  const [isShowingGuide, setIsShowingGuide] = useState(false);

  const { deviceId } = useDeviceData();
  const { data: till } = useCurrentTill();

  return (
    <main className="relative flex min-h-screen w-full flex-col items-center bg-primary-dark p-10">
      <nav className="flex h-16 w-full justify-center p-4" id="loginNav">
        <div className="w-40">
          <KanplaLogo className="fill-white" />
        </div>
      </nav>

      <div className="flex size-full flex-1 flex-col items-center justify-center gap-8">
        <div className="flex w-full max-w-lg  flex-col gap-5 rounded-lg bg-white p-10">
          {isShowingGuide ? <LicenseGuide /> : <>{children}</>}

          <div className="flex flex-col items-center gap-5 px-4 text-center">
            <div className="flex flex-col">
              {isShowingGuide ? null : (
                <p className="font-bold">
                  <T _str="Experiencing issues or need help?" />
                </p>
              )}

              <Button
                className="text-primary-dark"
                onClick={() => {
                  setIsShowingGuide((prev) => !prev);
                }}
                variant="transparent"
              >
                {isShowingGuide ? (
                  <T _str="Go back" />
                ) : (
                  <T _str="Contact Kanpla support" />
                )}
              </Button>

              {isShowingGuide ? (
                <Button
                  className="mt-4 text-danger-main"
                  onClick={authActions.logout}
                  variant="transparent"
                >
                  Logout
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      {isShowingGuide ? (
        <div className="absolute bottom-0">
          {till ? (
            <p className="text-center text-xs text-info-contrast">
              <T _str="License ID: {tillId}" tillId={till.id} />
            </p>
          ) : null}

          <p className="text-center text-xs text-info-contrast">
            <T _str="Device ID: {deviceId}" deviceId={deviceId} />
          </p>
        </div>
      ) : null}
    </main>
  );
};
