import { useNavigate } from "react-router-dom";
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery } from "@tanstack/react-query";
import type { ClientInferRequest } from "@ts-rest/core";

import { authActions, getAuth } from "~/providers/store/auth";
import type { apiContract } from "~/api/contract";
import { client, getAuthHeader } from "~/api/client";

export const profilesKeys = createQueryKeys("profiles", {
  all: {
    queryKey: null
  },
  guest: {
    queryKey: ["guest"]
  },
  detail: (profileId: string) => ({ queryKey: [profileId] })
});

export function useProfiles() {
  return useQuery({
    ...profilesKeys.all,
    queryFn: async () => {
      const auth = getAuth();

      if (auth.status !== "till" && auth.status !== "profile") {
        throw new Error("Till not assigned");
      }

      const { schoolId, moduleId } = auth;

      const res = await client.v1.pos.profiles.get({
        query: { moduleId, schoolId },
        headers: getAuthHeader()
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    }
  });
}

export function useProfile(profileId: string | null | undefined) {
  return useQuery({
    ...profilesKeys.detail(profileId || "empty"),
    queryFn: async () => {
      if (!profileId) throw new Error("Profile ID is not defined");

      const res = await client.v1.pos.profiles.getById({
        params: { profileId },
        headers: getAuthHeader()
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    enabled: Boolean(profileId)
  });
}

type AuthRequest = ClientInferRequest<typeof apiContract.v1.pos.profiles.auth>;

export function useProfileAuth() {
  const navigate = useNavigate();

  return useMutation({
    mutationFn: async (
      args: Pick<AuthRequest["body"], "pin"> & { profileId: string }
    ) => {
      const res = await client.v1.pos.profiles.auth({
        body: {
          pin: args.pin
        },
        params: { profileId: args.profileId },
        headers: getAuthHeader()
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    onSuccess: ({ childId, profileId }) => {
      authActions.loginProfile({ childId, profileId });
      navigate("/menu");
    }
  });
}
