import {
  type Variant,
  type Product,
  type Discount
} from "~/api/products/types";
import { calculateUnitGrossPrice } from "~/utils/calculate-unit-gross-price";
import { round } from "@repo/system/src/utils/utils";

import { type BasketItemVariant, type BasketItem } from "../../types";

import { type KioskDisplayProductBasket } from "./types";

type ParseDisplayProductBasketArgs = {
  product: Product;
  productInBasket: BasketItem;
  variants: Variant[];
  discounts: Discount[];
};

export function parseDisplayProductBasket({
  product,
  productInBasket,
  variants,
  discounts
}: ParseDisplayProductBasketArgs): KioskDisplayProductBasket {
  let unitGrossPrice = product.unitPrice;

  if (productInBasket.type === "variant") {
    unitGrossPrice += calculateVariantGrossPrice(
      productInBasket.choices,
      variants,
      product.vatRate
    );
  }

  unitGrossPrice *= productInBasket.quantity;

  // TO-DO: handle redeeming stamp cards [POS-1867]
  for (const productInBasketDiscount of productInBasket.discounts) {
    const discount = discounts.find(
      (discount) => discount.id === productInBasketDiscount.id
    );
    if (discount) {
      if (discount.amountType === "percentage") {
        unitGrossPrice -= round(unitGrossPrice * (discount.amount / 100), 2);
      } else {
        unitGrossPrice -= discount.amount;
      }
    }
  }

  return {
    product: {
      id: product.id,
      name: product.name,
      description: product.description ?? null,
      tags: product.tags ?? [],
      allergens: product.allergens ?? [],
      imageUrl: product.imageUrl,
      category: product.category,
      type: productInBasket.type
    },
    quantity: productInBasket.quantity,
    unitGrossPrice,
    basketItemId: productInBasket.id
  };
}

function calculateVariantGrossPrice(
  choices: BasketItemVariant["choices"],
  variants: Variant[],
  vatRate: number
): number {
  return choices.reduce((acc, productInBasketChoice) => {
    const variant = variants.find(
      (variant) => variant.id === productInBasketChoice.variantId
    );
    if (!variant) {
      return acc;
    }

    const choice = variant.choices.find(
      (choice) => choice.id === productInBasketChoice.id
    );
    if (!choice?.unitPrice) {
      return acc;
    }

    return (
      acc +
      calculateUnitGrossPrice(choice.unitPrice, vatRate) *
        productInBasketChoice.quantity
    );
  }, 0);
}
