import { clsx } from "clsx";
import { type PropsWithChildren } from "react";

type LandingGridProps = PropsWithChildren & {
  itemCount: number;
};

export const LandingGrid = ({ itemCount, children }: LandingGridProps) => {
  return (
    <div
      className={clsx("grid gap-6", {
        "grid-cols-1": itemCount === 1,
        "grid-cols-2 ": itemCount >= 2
      })}
    >
      {children}
    </div>
  );
};
