import { type AllowanceDetail } from "@kanpla/api-contract";
import { useContext, useMemo, type Dispatch } from "react";
import { useSearchParams } from "react-router-dom";

import { priceFormatter } from "@repo/system";
import { useT } from "@repo/transifex";
import type {
  Currency,
  PosProduct,
  StepVariant,
  StepVariantWeight,
  VariantGroup
} from "@repo/types";
import { useAuth } from "~/providers/store/auth";
import type { BasketStore } from "~/providers/store/basket";
import { useBasket } from "~/providers/store/basket";
import { calculateAllowance } from "~/utils/allowances";
import {
  applyDiscountsToProducts,
  calculateProportionalDiscount
} from "~/utils/basket";
import { calculateUnitPriceByUnitSystem } from "~/utils/calculateUnitPriceByUnitSystem";
import { calculateVariantTotal } from "~/utils/calculateVariantTotal";
import { getHybridBillingProductDiscount } from "~/utils/getHybridBillingProductDiscount";
import { useOrder } from "~/hooks/queries/orders";
import { useProductsPOS } from "~/api/products/hooks/use-products";

import type { AppAction, AppState } from "./types";
import { AppContext, AppDispatchContext } from "./app-provider";

/**
 * Hook to get the app state
 * @returns {AppState}
 */
export function useAppContext(): AppState {
  return useContext(AppContext);
}

/**
 * Hook to get the dispatch function
 * for sending actions to the app reducer
 * @returns {Dispatch<AppAction>}
 */
export function useAppDispatch(): Dispatch<AppAction> {
  return useContext(AppDispatchContext);
}

export function useCashDrawerStatus() {
  const { hardware } = useAppContext();

  return useMemo(() => {
    return hardware.cashDrawer.status;
  }, [hardware.cashDrawer.status]);
}

export function useDeviceData() {
  const { device } = useAppContext();

  return useMemo(() => {
    return device;
  }, [device]);
}

export function usePrinterStatus() {
  const { hardware } = useAppContext();

  return useMemo(() => {
    return hardware.printing.status;
  }, [hardware.printing.status]);
}

export function usePrinterError() {
  const t = useT();
  const { hardware } = useAppContext();

  switch (hardware.printerError) {
    case "connection_error":
      return t(
        "Printer has lost connection, please check printer and try reconnecting"
      );
    case "cutter_error":
      return t(
        "Cutter Error found on printer, please check printer and try again"
      );
    case "cover_open":
      return t("Printer cover is open, please close the cover and try again");
    case "paper_empty":
      return t("Printer paper is empty, please refill paper and try again");
    case "paper_jam_error":
      return t(
        "Paper Jam Error found on printer, please check printer and try again"
      );
    case "paper_separator_error":
      return t(
        "Paper Separator Error found on printer, please check printer and try again"
      );
    case "roll_position_error":
      return t(
        "Roll Position Error found on printer, please check printer and try again"
      );
    case "unknown_error":
      return t(
        "Unknown Error found on printer, please check printer and try again"
      );
    default:
      return null;
  }
}

export function useCountDownState() {
  const { countdown } = useAppContext();

  return useMemo(() => {
    return {
      enabled: countdown.enabled,
      count: countdown.count
    };
  }, [countdown.count, countdown.enabled]);
}

export type PosProductInBasket = (
  | {
      options?: StepVariant[];
      type: "variant";
      unitSystem: "piece";
    }
  | {
      options?: StepVariantWeight[];
      type: "variant-weight";
      unitSystem?: "1-gram" | "100-grams" | "kilogram";
    }
  | {
      options?: never;
      type: "custom" | "regular";
      unitSystem: "piece";
    }
) &
  Pick<
    PosProduct,
    | "id"
    | "category"
    | "quantity"
    | "name"
    | "unitPrice"
    | "totalDisplayPrice"
    | "limit"
    | "productLineId"
    | "taxRateId"
    | "taxRateName"
    | "discount"
  > & {
    totalOriginalDisplayPrice: string;
    basketId: string;
    discountUnitPrice?: number;
    discountPercentage?: number;
    totalUnitPrice: number;
    totalPrice: number;
    vatRate: number;
    exclVatUnitPrice: number;
    stampCardRuleId?: string;
  };

export type UseBasketProductsReturn = {
  basket: BasketStore["products"];
  variants: VariantGroup[];
  products: PosProduct[];
  productsInBasket: PosProductInBasket[];
  meta: {
    currency: Currency;
    offset: number;
  };
  isEmpty: boolean;
  totalUnit: number;
  totalUnitDiscounted: number;
  productsStatus: "loading" | "success" | "error" | "pending";
  allowances: AllowanceDetail[];
  defaultTaxData: {
    taxRate: number;
    taxRateName: string | null;
    taxRateId: string | null;
  };
};

const defaultMeta: UseBasketProductsReturn["meta"] = {
  currency: "DKK",
  offset: 0
};

/**
 * Hook to get the products in the basket
 * @returns {UseBasketProductsReturn}
 */
export function useBasketProducts(): UseBasketProductsReturn {
  const authData = useAuth();

  const { data, status: productsStatus } = useProductsPOS();

  const { products: basket, orderDiscount } = useBasket();

  return useMemo(() => {
    if (productsStatus !== "success") {
      return {
        basket,
        products: [],
        meta: defaultMeta,
        isEmpty: true,
        totalUnit: 0,
        totalUnitDiscounted: 0,
        productsStatus,
        productsInBasket: [],
        variants: [],
        allowances: [],
        defaultTaxData: {
          taxRate: 0.25,
          taxRateName: null,
          taxRateId: null
        }
      };
    }

    const {
      products: allProducts,
      meta,
      discounts,
      allowances,
      defaultTaxData
    } = data;

    const updatedAllowances = calculateAllowance({
      allowances,
      discounts,
      basket,
      allProducts
    });

    // products extended with allowance rules based on the current basket
    const products = allProducts.map<PosProduct>((product) => {
      const basketProduct = basket.find(
        (item) => item.productId === product.id
      );

      const quantity = basketProduct?.count || 0;

      const hybridBillingDiscount = getHybridBillingProductDiscount({
        product: { discount: product.discount, unitPrice: product.unitPrice },
        discounts,
        allowances: updatedAllowances
      });

      const shouldDisplayDiscountedPrice = Boolean(
        hybridBillingDiscount &&
          hybridBillingDiscount.type === "discount-hybridBilling" &&
          hybridBillingDiscount.allowanceType !== "unitPrice" &&
          !hybridBillingDiscount.isAllowanceOver
      );

      // Takes into account discounts applied to the product
      const displayPrice = priceFormatter({
        value:
          product.unitPrice -
          (shouldDisplayDiscountedPrice
            ? hybridBillingDiscount?.amount || 0
            : 0),
        currency: meta.currency,
        locale: authData.locale
      });

      // Original product price, if there are no discounts will be the same as displayPrice
      const displayPriceOriginal = priceFormatter({
        value: product.unitPrice,
        currency: meta.currency,
        locale: authData.locale
      });

      const prod: PosProduct = {
        ...product,
        quantity,
        totalDisplayPrice: priceFormatter({
          value: product.unitPrice * quantity,
          currency: meta.currency,
          locale: authData.locale
        }),
        limit: null, // TO-DO: implement product limits [POS-1857]
        displayPrice,
        displayPriceOriginal,
        discount: hybridBillingDiscount
      };

      return prod;
    });

    // products in basket extended with display prices and total price
    const productsInBasket = basket.flatMap<PosProductInBasket>((item) => {
      if (item.count === 0) {
        return [];
      }

      if (item.type === "custom") {
        const customVatRate = item.customVatRate;
        const vatRate = customVatRate ? customVatRate : defaultTaxData.taxRate;

        const exclVatUnitPrice =
          item.vatCalculation === "excl"
            ? item.rawUnitPrice
            : item.unitPrice / (1 + vatRate);

        const totalPrice =
          item.vatCalculation === "excl"
            ? item.rawUnitPrice * (1 + vatRate) * item.count
            : item.unitPrice * item.count;
        const totalDisplayPrice = priceFormatter({
          value: totalPrice,
          currency: meta.currency,
          locale: authData.locale
        });

        const customProduct: PosProductInBasket = {
          id: item.productId,
          name: item.name,
          quantity: item.count,
          unitPrice: item.unitPrice,
          category: item.category,
          basketId: item.id,
          totalUnitPrice: item.unitPrice,
          type: item.type,
          productLineId: item.id,
          unitSystem: "piece",
          limit: null,
          discount: null,
          totalOriginalDisplayPrice: totalDisplayPrice,
          taxRateName: customVatRate ? null : defaultTaxData.taxRateName,
          taxRateId: customVatRate ? null : defaultTaxData.taxRateId,
          totalDisplayPrice,
          totalPrice,
          exclVatUnitPrice,
          vatRate
        };

        return customProduct;
      }

      const product = products.find((p) => p.id === item.productId);
      if (!product) {
        return [];
      }

      let totalUnitPrice = product.unitPrice;
      let totalVariantPrice = 0;

      if (item.type === "variant") {
        totalVariantPrice = calculateVariantTotal(
          item.options,
          product.vatRate
        );
        totalUnitPrice += totalVariantPrice;
      }

      if (item.type === "variant-weight") {
        // for displaying the price incl. VAT in the basket and its total
        totalUnitPrice = calculateUnitPriceByUnitSystem({
          unitPrice: product.unitPrice,
          weight: item.options[0].choices[0].weight,
          unitSystem: product.unitSystem
        });
      }

      // item.isHybridBilled: only if allowance is not over
      const hybridBillingDiscount = item.isHybridBilled
        ? product.discount
        : null;

      let totalPrice = totalUnitPrice * item.count;
      let discountUnitPrice: number | undefined;
      let discountPercentage = 0;

      // Update product price only if there is no order discount
      if (!orderDiscount.amount) {
        // If product has a hybrid billing discount (Show full price discount)
        // - allowanceType !== "unitPrice": we handle this like a order discount
        const hasHybridBillingDiscount =
          hybridBillingDiscount?.type === "discount-hybridBilling" &&
          hybridBillingDiscount?.allowanceType !== "unitPrice";
        if (hasHybridBillingDiscount) {
          const discountAmount = product.discount?.percentage
            ? totalPrice * (product.discount.percentage / 100)
            : (product.discount?.amount ?? 0) * item.count;

          totalPrice -= discountAmount;
        }

        // If product has a POS discount
        if (item.discountUnitPrice) {
          discountUnitPrice = item.discountUnitPrice;
          totalPrice -= discountUnitPrice || 0;

          // Calculate the correct discount percentage to display, so include the hybrid billing discount if it exists
          if (hasHybridBillingDiscount) {
            discountPercentage =
              (discountUnitPrice /
                ((totalUnitPrice - (hybridBillingDiscount?.amount || 0)) *
                  item.count)) *
              100;
          } else {
            discountPercentage =
              (discountUnitPrice / (totalUnitPrice * item.count)) * 100;
          }
        }
      }

      const baseProduct = {
        id: product.id,
        productLineId: product.productLineId,
        name: product.name,
        unitPrice: product.unitPrice,
        category: product.category,
        quantity: item.count,
        totalDisplayPrice: priceFormatter({
          value: totalPrice,
          currency: meta.currency,
          locale: authData.locale
        }),
        totalOriginalDisplayPrice: priceFormatter({
          value: totalUnitPrice * item.count,
          currency: meta.currency,
          locale: authData.locale
        }),
        limit: product.limit,
        basketId: item.id,
        totalUnitPrice,
        totalPrice,
        vatRate: product.vatRate,
        exclVatUnitPrice: product.exclVatUnitPrice,
        taxRateName: product.taxRateName,
        taxRateId: product.taxRateId,
        stampCardRuleId: item.stampCardRuleId,
        // POS discount, created by the user
        discountUnitPrice,
        discountPercentage,
        // Hybrid billing discount
        discount: hybridBillingDiscount
      };

      if (item.type === "regular") {
        const prod: PosProductInBasket = {
          ...baseProduct,
          type: item.type,
          unitSystem: "piece"
        };

        return prod;
      }

      if (item.type === "variant") {
        const prod: PosProductInBasket = {
          ...baseProduct,
          options: item.options,
          type: item.type,
          unitSystem: "piece"
        };

        return prod;
      }

      // weighted product flow
      const prod: PosProductInBasket = {
        ...baseProduct,
        // hack to calculate the weight in the product price
        unitPrice: calculateUnitPriceByUnitSystem({
          unitPrice: product.unitPrice,
          weight: item.options[0].choices[0].weight,
          unitSystem: product.unitSystem
        }),
        // hack to calculate the weight in the product price
        exclVatUnitPrice: calculateUnitPriceByUnitSystem({
          unitPrice: product.exclVatUnitPrice,
          weight: item.options[0].choices[0].weight,
          unitSystem: product.unitSystem
        }),
        options: item.options,
        type: item.type,
        unitSystem: item.unitSystem
      };

      return prod;
    });

    const totalUnit = productsInBasket.reduce(
      (acc, product) => acc + product.totalPrice,
      0
    );

    let productsInBasketWithDiscount = [...productsInBasket];

    const unitPriceAllowances = allowances.filter(
      (allowance) =>
        allowance.type === "unitPrice" && !allowance.isAllowanceOver
    );

    // if there are unit price allowances, apply here the discounts
    unitPriceAllowances.forEach((allowance) => {
      const discount = discounts.find((d) => d.id === allowance.discountId);
      if (!discount) return;

      const allowanceProducts = productsInBasket.filter(
        (product) => product.discount?.id === allowance.discountId
      );

      const allowanceProductsTotalUnit = allowanceProducts.reduce(
        (acc, product) => acc + product.totalPrice,
        0
      );

      const calculatedDiscount =
        allowanceProductsTotalUnit * (discount.amount / 100);
      const totalDiscount = Math.min(allowance.remaining, calculatedDiscount);

      const productDiscounts = calculateProportionalDiscount(
        allowanceProducts,
        allowanceProductsTotalUnit,
        totalDiscount
      );
      productsInBasketWithDiscount = applyDiscountsToProducts(
        productsInBasket,
        productDiscounts,
        null,
        meta.currency,
        authData.locale
      );
    });

    // order discount
    if (orderDiscount.amount) {
      const totalDiscount =
        orderDiscount.type === "percentage"
          ? totalUnit * (orderDiscount.amount / 100)
          : orderDiscount.amount;

      const productDiscounts = calculateProportionalDiscount(
        productsInBasket.filter((p) => p.unitPrice > 0 && p.type !== "custom"),
        totalUnit,
        totalDiscount
      );

      productsInBasketWithDiscount = applyDiscountsToProducts(
        productsInBasket,
        productDiscounts,
        orderDiscount,
        meta.currency,
        authData.locale
      );
    }

    const totalUnitDiscounted = productsInBasketWithDiscount.reduce(
      (acc, product) => {
        return acc + product.totalPrice;
      },
      0
    );

    return {
      meta,
      basket,
      products,
      productsInBasket: productsInBasketWithDiscount,
      isEmpty: !Object.keys(basket).length,
      totalUnit,
      totalUnitDiscounted,
      productsStatus,
      variants: data.variants,
      allowances: updatedAllowances,
      defaultTaxData
    };
  }, [productsStatus, data, basket, orderDiscount, authData.locale]);
}

type SplitPaymentFlowType = {
  orderId: string | null;
  isActive: boolean;
  unitReceived: number;
  loading: boolean;
  reset: () => void;
};

/**
 * Hook to be used to find out information about the split payment,
 * e.g. whether it is active and how much cash has already been received
 */
export const useSplitPaymentFlow = (): SplitPaymentFlowType => {
  const [searchParams, setSearchParams] = useSearchParams();
  const orderIdFromParams = searchParams.get("orderId");

  const { data: order, status: orderStatus } = useOrder(orderIdFromParams);

  const reset = () => {
    setSearchParams((params) => {
      params.delete("orderId");
      return params;
    });
  };

  if (!orderIdFromParams) {
    return {
      orderId: null,
      isActive: false,
      unitReceived: 0,
      loading: false,
      reset
    };
  }

  if (orderStatus !== "success") {
    return {
      orderId: orderIdFromParams,
      isActive: false,
      unitReceived: 0,
      loading: true,
      reset
    };
  }

  const unitReceived =
    order.payments
      ?.filter((p) => p.paymentProvider !== "credit")
      .reduce((acc, payment) => {
        return acc + payment.unitPrice;
      }, 0) ?? 0;

  return {
    orderId: orderIdFromParams,
    isActive: order.status === "pending" && unitReceived !== 0,
    unitReceived,
    loading: false,
    reset
  };
};
