import { v4 as uuidv4 } from "uuid";

import { INITIAL_STATE } from "~/providers/store/basket/store";

import type {
  BasketDiscount,
  BasketItem,
  KioskSession,
  VariantChoice
} from "./types";
import { kioskSessionStore } from "./store";

function sessionCreate() {
  kioskSessionStore.setState(() => {
    return {
      id: uuidv4(),
      basket: [],
      userId: null,
      diningOptionId: null,
      language: "en",
      extra: {
        shopperName: null,
        comment: null
      },
      lastUpdatedAt: Date.now()
    };
  });
}

function sessionReset() {
  kioskSessionStore.setState(() => {
    return INITIAL_STATE;
  });
}

type AddToBasketArgs = {
  productId: string;
  type: BasketItem["type"];
  quantity?: number;
  discount?: BasketDiscount;
  choices?: VariantChoice[];
};

function basketAdd({
  productId,
  quantity,
  discount,
  type,
  choices
}: AddToBasketArgs) {
  kioskSessionStore.setState((session) => {
    if (!session) {
      return;
    }

    const index = session.basket.findIndex(
      (prod) =>
        prod.productId === productId &&
        prod.discounts.every(({ id }) => id !== discount?.id)
    );

    if (index !== -1) {
      session.basket[index].quantity += 1;
      return;
    }

    const discounts: BasketDiscount[] = [];
    if (discount) {
      discounts.push(discount);
    }

    let item: BasketItem = {
      id: uuidv4(),
      type: "regular",
      productId,
      quantity: quantity ?? 1,
      discounts
    };

    if (type === "variant") {
      item = {
        ...item,
        choices: choices ?? [],
        type: "variant"
      };
    }

    session.basket.push(item);
  });
}

function basketIncrement(itemId: string) {
  kioskSessionStore.setState((session) => {
    if (!session) {
      return;
    }

    const index = session.basket.findIndex((item) => item.id === itemId);
    if (index !== -1) {
      session.basket[index].quantity += 1;
    }
  });
}

function basketDecrement(itemId: string) {
  kioskSessionStore.setState((session) => {
    if (!session) {
      return;
    }

    const index = session.basket.findIndex((item) => item.id === itemId);
    if (index !== -1) {
      if (session.basket[index].quantity === 1) {
        session.basket.splice(index, 1);
      } else {
        session.basket[index].quantity -= 1;
      }
    }
  });
}

function basketRemove(itemId: string) {
  kioskSessionStore.setState((session) => {
    if (!session) {
      return;
    }

    const index = session.basket.findIndex((item) => item.id === itemId);
    if (index !== -1) {
      session.basket.splice(index, 1);
    }
  });
}

function basketReset() {
  kioskSessionStore.setState((session) => {
    if (!session) {
      return;
    }

    session.basket = [];
  });
}

function setDiningOption(diningOption: KioskSession["diningOption"]) {
  kioskSessionStore.setState((session) => {
    if (!session) {
      return;
    }

    session.diningOption = diningOption;
  });
}

function setShopperName(shopperName: KioskSession["extra"]["shopperName"]) {
  kioskSessionStore.setState((session) => {
    if (!session) {
      return;
    }

    session.extra.shopperName = shopperName;
  });
}

function setComment(comment: KioskSession["extra"]["comment"]) {
  kioskSessionStore.setState((session) => {
    if (!session) {
      return;
    }

    session.extra.comment = comment;
  });
}

function setLanguage(language: KioskSession["language"]) {
  kioskSessionStore.setState((session) => {
    if (!session) {
      return;
    }

    session.language = language;
  });
}

function setUser(userId: KioskSession["userId"]) {
  kioskSessionStore.setState((session) => {
    if (!session) {
      return;
    }

    session.userId = userId;
  });
}

export const kioskActions = {
  session: {
    start: sessionCreate,
    reset: sessionReset,
    setUser,
    setDiningOption,
    setShopperName,
    setComment,
    setLanguage
  },

  basket: {
    add: basketAdd,
    increment: basketIncrement,
    decrement: basketDecrement,
    remove: basketRemove,
    reset: basketReset
  }
};
