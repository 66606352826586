import { clsx } from "clsx";
import { type ReactNode } from "react";

import { ReactPortal } from "~/components/ReactPortal";

type VariantType = "error" | "success" | "idle";

export type ModalProps = {
  open?: boolean;
  children: ReactNode;
  className?: string;
  backdropVariant: VariantType;
  onOutsideClick?: () => void;
};

export const Modal = ({
  open = true,
  children,
  backdropVariant,
  onOutsideClick,
  className
}: ModalProps) => {
  const handleOutsideClick = (e: React.MouseEvent) => {
    if (e.target !== e.currentTarget) return;
    onOutsideClick?.();
  };

  return (
    <ReactPortal wrapperId="modal-portal">
      <div
        className={clsx(
          "absolute right-0 top-0 z-20 size-full",
          { hidden: !open },
          {
            "bg-kiosk-background-reversed/95":
              backdropVariant === "idle" || backdropVariant === "error",
            "bg-kiosk-background-success": backdropVariant === "success"
          }
        )}
      />
      <div
        className={clsx(
          "absolute right-0 top-0 z-30 flex  size-full items-center justify-center",
          { hidden: !open }
        )}
        onClick={handleOutsideClick}
      >
        <dialog
          open={open}
          className={clsx("relative overflow-auto bg-transparent", className)}
        >
          {children}
        </dialog>
      </div>
    </ReactPortal>
  );
};
