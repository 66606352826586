import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T } from "@repo/transifex";

type RegistrationCompleteProps = {
  onClick: () => void;
};

export const RegistrationComplete = ({
  onClick
}: RegistrationCompleteProps) => {
  return (
    <button className="text-center" onClick={onClick} type="button">
      <FontAwesomeIcon
        className="text-7xl text-primary-main"
        icon={faCheckCircle}
      />

      <h2 className="text-4xl">
        <T _str="Registration complete" />
      </h2>
      <p className="mb-8 text-sm">
        <T _str="You can find your receipts in the app" />
      </p>

      <p className="text-xl text-text-disabled">
        <T _str="Ready to scan →" />
      </p>
    </button>
  );
};
