import type { ReactNode } from "react";
import { faLock, faRepeat } from "@fortawesome/pro-solid-svg-icons";
import { useQueryClient } from "@tanstack/react-query";
import { Link, Outlet, useNavigate } from "react-router-dom";

import { T } from "@repo/transifex";
import { useProfiles } from "~/hooks/queries/profiles";
import { authActions, useAuth } from "~/providers/store/auth";
import {
  Button,
  ErrorMessage,
  ProfileCard,
  ProfileCardSkeleton
} from "~/components";

const ProfilesPage = () => {
  const { isKanplaAdmin } = useAuth();
  const { data: profiles, status } = useProfiles();

  if (status === "pending")
    return (
      <ProfileLayout isKanplaAdmin={isKanplaAdmin}>
        <ProfileCardSkeleton />
        <ProfileCardSkeleton />
        <ProfileCardSkeleton />
      </ProfileLayout>
    );

  if (status === "error")
    return (
      <ProfileLayout isKanplaAdmin={isKanplaAdmin}>
        <ErrorMessage error={<T _str="Could not load profiles" />} />
      </ProfileLayout>
    );

  return (
    <>
      <ProfileLayout isKanplaAdmin={isKanplaAdmin}>
        {profiles.map(({ name, id }) => {
          return (
            <Link key={id} to={id}>
              <ProfileCard key={id} name={name} />
            </Link>
          );
        })}
      </ProfileLayout>

      <Outlet />
    </>
  );
};

const ProfileLayout = ({
  isKanplaAdmin,
  children
}: {
  isKanplaAdmin: boolean | null;
  children?: ReactNode;
}) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return (
    <div className="size-full bg-slate-800 p-10">
      <div className="relative mb-11 flex justify-center">
        <h1 className="text-5xl font-bold text-white">
          <T _str="Select user" />
        </h1>

        <div className="absolute right-0 flex gap-2 font-normal">
          {isKanplaAdmin ? (
            <Button
              icon={faRepeat}
              onClick={() => {
                navigate("/admin");
              }}
              size="large"
              variant="primary-light"
            >
              <T _str="Switch till" />
            </Button>
          ) : null}

          <Button
            icon={faLock}
            onClick={() => {
              authActions.logout();
              queryClient.removeQueries();
            }}
            size="large"
            variant="dark-blue"
          >
            <T _str="Logout" />
          </Button>
        </div>
      </div>

      <div className="flex justify-center">
        <div className="grid grid-cols-2 gap-5 lg:grid-cols-3 xl:grid-cols-4">
          {children}
        </div>
      </div>
    </div>
  );
};

export { ProfilesPage };
