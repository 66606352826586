import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";

type CheckboxButtonProps = {
  checked: boolean;
  onClick: (value: boolean) => void;
  label?: string;
  className?: string;
};

export const CheckboxButton = ({
  checked,
  onClick,
  label,
  className
}: CheckboxButtonProps) => {
  return (
    <button
      className={clsx("flex items-center", className)}
      onClick={() => {
        onClick(!checked);
      }}
      type="button"
    >
      {checked ? (
        <div className="flex aspect-square w-8 items-center justify-center self-start rounded-md bg-primary-main md:rounded-full">
          <FontAwesomeIcon className="text-lg" color="white" icon={faCheck} />
        </div>
      ) : (
        <div className="aspect-square w-8 self-start rounded-md border border-divider-main md:rounded-full md:border-none md:bg-secondary-main" />
      )}
      {label ? <label className="ml-2">{label}</label> : null}
    </button>
  );
};
