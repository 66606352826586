import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { Link } from "react-router-dom";

import { priceFormatter } from "@repo/system";
import { T } from "@repo/transifex";
import {
  Basket,
  BasketBody,
  BasketFooter,
  BasketHeader,
  BasketProducts,
  BasketSumUp
} from "~/components/kiosk/basket";
import { ProductItemLine } from "~/components/kiosk/basket/product-item-line/product-item-line";
import {
  BottomActions,
  PayButton,
  SecondaryButton
} from "~/components/kiosk/bottom-actions/bottom-actions";
import { kioskActions } from "~/stores/kiosk-session/actions";
import { useKioskProducts } from "~/stores/kiosk-session/hooks/use-kiosk-products";
import { useKioskSessionLanguage } from "~/stores/kiosk-session/hooks/use-kiosk-session";

export const KioskBasketPage = () => {
  const language = useKioskSessionLanguage();
  const { productsBasket, unitGrossPriceTotal, currency } = useKioskProducts();

  const formattedTotalAmount = priceFormatter({
    value: unitGrossPriceTotal,
    currency,
    locale: language
  });

  return (
    <Basket>
      <BasketHeader>
        {/* TO-DO: authenticate user[POS-1870]        
        <UserInfo
          userName={user.name}
          balance={priceFormatter({
            value: user.saldo,
            currency,
            locale: language
          })}
        /> */}
      </BasketHeader>

      <BasketBody>
        <BasketProducts>
          {productsBasket.map(
            ({ product, unitGrossPrice, quantity, basketItemId }) => (
              <ProductItemLine
                key={product.id}
                imageUrl={product.imageUrl}
                name={product.name}
                price={priceFormatter({
                  value: unitGrossPrice,
                  currency,
                  locale: language
                })}
                quantity={quantity}
                onQuantityIncrease={() => {
                  kioskActions.basket.increment(basketItemId);
                }}
                onQuantityDecrease={() => {
                  kioskActions.basket.decrement(basketItemId);
                }}
              />
            )
          )}
        </BasketProducts>

        <BasketSumUp
          numberOfProducts={productsBasket.length}
          totalAmount={formattedTotalAmount}
        />
      </BasketBody>

      {/* TO-DO: implement instructions [POS-1872]
      <OrderInstructions>
        <OrderInstructionItem icon={faPencil} name="Name">
          <OrderInstructionInput placeholder={t("None")} />
        </OrderInstructionItem>
        <OrderInstructionItem icon={faPencil} name="Special requests">
          <OrderInstructionInput placeholder={t("None")} />
        </OrderInstructionItem>
      </OrderInstructions>
       */}

      <BasketFooter>
        <BottomActions
          secondaryButton={
            <Link to="/kiosk/products" className="flex flex-1">
              <SecondaryButton icon={faArrowLeft}>
                <T _str="Back to menu" />
              </SecondaryButton>
            </Link>
          }
          primaryButton={
            <PayButton
              totalAmount={formattedTotalAmount}
              onClick={() => {
                // TO-DO: pay order [POS-1835]
              }}
            >
              <T _str="Order & pay" />
            </PayButton>
          }
        />
      </BasketFooter>
    </Basket>
  );
};
