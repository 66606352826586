import { useParams } from "react-router-dom";

import { getErrorMessage } from "@repo/system";
import { useProfile } from "~/hooks/queries/profiles";
import { DialogError, DialogLoading, DialogPin } from "~/components";

const ProfilePinPage = () => {
  const params = useParams();

  const { data: profile, status, error } = useProfile(params.profileId);

  if (status === "pending") return <DialogLoading />;

  if (status === "error") {
    return <DialogError backUrl=".." error={getErrorMessage(error)} />;
  }

  return (
    <DialogPin backUrl=".." profile={{ id: profile.id, name: profile.name }} />
  );
};

export { ProfilePinPage };
