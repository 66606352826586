import { type Currency } from "~/api/products/types";
import { priceFormatter, round } from "@repo/system";
import { type PosProductInBasket } from "~/providers/app";

/**
 * Function that calculates the amount to pay with saldo and other payment methods
 * @param amountToPay: The amount to pay
 * @param saldo: The saldo
 * @returns: The amount to pay with saldo and other payment methods
 */
export function calculateAmountForSaldo(
  amountToPay: number,
  saldo: number | null
): {
  payWithSaldo: number;
  payWithOther: number;
} {
  //If the amount is not valid, return 0
  if (!amountToPay)
    return {
      payWithSaldo: 0,
      payWithOther: 0
    };

  //If there is no saldo, fill "other" with the amount
  if (!saldo || saldo < 0)
    return {
      payWithSaldo: 0,
      payWithOther: amountToPay
    };

  const difference = saldo - amountToPay;

  //If the amount to pay is higher than the saldo
  //Return the saldo as saldo and the difference as other
  if (difference < 0)
    return {
      payWithSaldo: saldo,
      payWithOther: amountToPay - saldo
    };

  //If the amount to pay is lower than pay with saldo
  const payWithSaldo = difference < saldo ? amountToPay : difference;
  return {
    payWithSaldo,
    payWithOther: 0
  };
}

/**
 * The function calculates each product's proportion of the total basket value and assigns a proportional discount.
 * To handle rounding issues, any residual difference is adjusted on the first product in the list.
 * @param products: The products in the basket
 * @param totalUnit: The basket total unit
 * @param totalDiscount: The total discount
 */
export function calculateProportionalDiscount(
  products: PosProductInBasket[],
  totalUnit: number,
  totalDiscount: number
) {
  let totalGivenDiscount = 0;
  const productDiscounts = products.map((product) => {
    const proportion = product.totalPrice / totalUnit;
    const amount = round(totalDiscount * proportion, 0);
    totalGivenDiscount += amount;
    return { basketId: product.basketId, amount };
  });

  if (productDiscounts.length > 0) {
    productDiscounts[0].amount += totalDiscount - totalGivenDiscount;
  }

  return productDiscounts;
}

/**
 * The function searches for a matching discount for each product based on `basketId` and deducts it from the total price.
 * If an `orderDiscount` is provided, it calculates and applies the appropriate discount type.
 * @param products: The products in the basket
 * @param productDiscounts: An array of discounts assigned to specific products, identified by `basketId`
 * @param orderDiscount: A global order discount
 * @param currency
 * @param locale
 */
export function applyDiscountsToProducts(
  products: PosProductInBasket[],
  productDiscounts: {
    basketId: string;
    amount: number;
  }[],
  orderDiscount: {
    amount: number;
    type: "percentage" | "currency";
  } | null,
  currency: Currency,
  locale: string | null
) {
  return products.map((product) => {
    const discount = productDiscounts.find(
      (p) => p.basketId === product.basketId
    );
    if (!discount) return product;

    const totalPrice = product.totalPrice - discount.amount;
    return {
      ...product,
      totalPrice,
      ...(orderDiscount
        ? {
            discountUnitPrice: discount.amount,
            discountPercentage:
              orderDiscount.type === "percentage"
                ? orderDiscount.amount
                : (discount.amount * 100) / product.totalPrice
          }
        : {
            totalDisplayPrice: priceFormatter({
              value: totalPrice,
              currency,
              locale
            }),
            discount: product.discount
              ? { ...product.discount, amount: discount.amount }
              : null
          })
    };
  });
}
