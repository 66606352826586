import { createStore } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import type { ModalVariantsStore } from "./store.types";

const INITIAL_STATE: ModalVariantsStore = {
  variants: [],
  amount: 1,
  productId: null
};

const modalVariantsStore = createStore<ModalVariantsStore>()(
  immer(devtools(() => INITIAL_STATE, { name: "modal-variants-store" }))
);

export { INITIAL_STATE, modalVariantsStore };
