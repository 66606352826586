import { faPlus } from "@fortawesome/pro-solid-svg-icons";

import { ProductImage } from "../ui/product-image/product-image";
import { RoundButton } from "../ui/round-button/round-button";
import { QuantityInputControl } from "../ui/quantity-input-control/quantity-input-control";

type ProductCardProps = {
  id: string;
  name: string;
  displayPrice: string;
  displayPriceOriginal?: string;
  tags: string[];
  image: string | null;
  quantity: number;
  onItemClick: (productId: string) => void;
  onIncrementClick: (productId: string) => void;
  onDecrementClick: (productId: string) => void;
};

export const ProductCard = ({
  id,
  name,
  displayPrice,
  displayPriceOriginal,
  //   tags,
  image,
  quantity,
  onItemClick,
  onIncrementClick,
  onDecrementClick
}: ProductCardProps) => {
  return (
    <div className="relative w-full">
      <button
        className="flex w-full flex-col gap-4"
        type="button"
        onClick={() => {
          onItemClick(id);
        }}
      >
        <ProductImage image={image} className="h-56">
          {/* TODO: tags [POS-1086] */}
          <div className="flex-1" />
        </ProductImage>

        <div className="flex flex-col gap-2 text-start text-2xl">
          <p className="line-clamp-2 w-full font-semibold text-kiosk-text-primary">
            {name}
          </p>

          <div className="flex items-center gap-3">
            <p className="font-bold text-kiosk-text-primary">{displayPrice}</p>

            {displayPriceOriginal ? (
              <p className="text-kiosk-text-secondary line-through">
                {displayPriceOriginal}
              </p>
            ) : null}
          </div>
        </div>
      </button>

      {/* Outside because buttons can't be descendants of other buttons */}
      <div className="absolute right-0 top-0 flex w-full items-center justify-end p-2">
        {quantity === 0 ? (
          <RoundButton
            icon={faPlus}
            onClick={() => {
              onIncrementClick(id);
            }}
          />
        ) : (
          <QuantityInputControl
            className="w-full"
            quantity={quantity}
            variant="primary"
            onIncrementClick={(e) => {
              e.stopPropagation();
              onIncrementClick(id);
            }}
            onDecrementClick={(e) => {
              e.stopPropagation();
              onDecrementClick(id);
            }}
          />
        )}
      </div>
    </div>
  );
};
