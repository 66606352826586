import type { Row } from "@tanstack/react-table";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  useReactTable
} from "@tanstack/react-table";
import { clsx } from "clsx";
import { Fragment } from "react";

/** Use this type due to type checking issues. any value it's not permitted in this definition ColumnDef<T, any>[] */
type ColumnDef<T> = Parameters<typeof useReactTable<T>>[0]["columns"];

type TableProps<T> = {
  data: T[];
  columns: ColumnDef<T>;
  className?: string;
  getRowCanExpand?: (row: Row<T>) => boolean;
  renderSubComponent?: (props: { row: Row<T> }) => React.ReactElement;
  wrapperClassName?: string;
};

export const Table = <T extends object>({
  data,
  columns,
  className,
  getRowCanExpand,
  renderSubComponent,
  wrapperClassName
}: TableProps<T>) => {
  const table = useReactTable<T>({
    data,
    columns,
    getRowCanExpand,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel()
  });

  return (
    <div
      className={clsx(
        "size-full overflow-hidden overflow-y-auto scrollbar-hide",
        wrapperClassName
      )}
    >
      <table
        className={clsx("w-full border-separate border-spacing-y-0", className)}
      >
        <thead className="sticky top-0 z-20 bg-slate-100 text-sm font-semibold text-slate-600">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  className="border-x border-x-white p-4 text-left"
                  key={header.id}
                >
                  {!header.isPlaceholder &&
                    flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody className="bg-slate-50">
          {table.getRowModel().rows.map((row, index) => (
            <Fragment key={row.id}>
              <tr className="m-2 rounded-md bg-white" key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    className={clsx("border-x border-x-white p-4 text-left", {
                      "border-y border-slate-200": index !== 0
                    })}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
              {row.getIsExpanded() && (
                <tr className="m-2 rounded-md bg-white">
                  <td colSpan={row.getVisibleCells().length}>
                    {renderSubComponent?.({ row })}
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const fakeColumnHelper = createColumnHelper<{ id: number }>();
const fakeColumns = [
  fakeColumnHelper.accessor("id", {
    cell: (info) => {
      const value = info.getValue();

      return (
        <div
          className={clsx("h-4 animate-pulse", {
            "bg-text-disabled": value % 2 === 0,
            "bg-text-secondary": value % 2 !== 0
          })}
        />
      );
    },
    header: () => <div className="h-4" />
  })
];

type TableSkeletonProps = {
  rows?: number;
};
export const TableSkeleton = ({ rows = 12 }: TableSkeletonProps) => {
  const skeletonArray = Array.from(Array(rows).keys()).map((_, index) => ({
    id: index
  }));

  return (
    <Table
      columns={fakeColumns}
      data={skeletonArray}
      wrapperClassName="!overflow-y-hidden"
    />
  );
};
