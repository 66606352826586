import { useStore } from "zustand";

import { type Language } from "~/api/tills/types";

import type { KioskSessionStore } from "../types";
import { kioskSessionStore } from "../store";

export function useKioskSession<T>(selector: (state: KioskSessionStore) => T) {
  return useStore(kioskSessionStore, selector);
}

export function useKioskSessionStatus(): "active" | "inactive" {
  const isActive = useKioskSession(
    (session) =>
      session !== null &&
      typeof session === "object" &&
      Object.keys(session).length > 0
  );

  return isActive ? "active" : "inactive";
}

export function useKioskSessionLanguage(): Language {
  return useKioskSession((session) => session?.language ?? "da");
}
