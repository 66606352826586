import { Navigate, Outlet } from "react-router-dom";

import { useCurrentTill } from "~/api/tills/hooks/use-till";
import { ScreenLoaderLogo } from "~/components/screen/screen-loader-logo";

export const TicketValidationLayoutPage = () => {
  const { data: till, status } = useCurrentTill();

  if (status === "pending") {
    return <ScreenLoaderLogo />;
  }

  if (status === "error") {
    return <Navigate to="/menu" />;
  }

  if (till.type !== "ticket-validation") {
    return <Navigate to="/menu" />;
  }

  return <Outlet />;
};
