import { T } from "@repo/transifex";

export const LoadingSpinner = () => {
  return (
    <div>
      <style>
        {`
              .loader {
                background: conic-gradient(from 102deg at 50% 50%, #1D78D3 0deg, rgba(107, 171, 235, 0.00) 360deg);
                animation: animate 1.5s linear infinite;
              }
    
              .loader::before {
                position: absolute;
                content: "";
                background: #fff;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 95%;
                height: 95%;
                border-radius: 10rem;
              }
    
              @keyframes animate {
                from {
                  transform: rotate(0deg);
                }
                to {
                  transform: rotate(360deg);
                }
              }
            `}
      </style>
      <div className="flex items-center justify-center py-10">
        <div className="relative w-fit">
          <span className="loader relative block size-60 rounded-full" />
          <p
            className="absolute inset-x-0 text-center text-4xl text-text-primary"
            style={{ top: "calc(50% - 21px)" }}
          >
            <T _str="Please wait.." />
          </p>
        </div>
      </div>
    </div>
  );
};
