import { round } from "@repo/system";

/**
 * Calculates the gross price by applying the VAT rate to the net price.
 *
 * @param unitNetPrice - The net price
 * @param vatRate - The VAT rate as a decimal (e.g., 0.21 for 21%)
 * @returns The calculated gross price rounded to 2 decimal places
 */
export function calculateUnitGrossPrice(
  unitNetPrice: number,
  vatRate: number
): number {
  return round(unitNetPrice * (1 + vatRate), 2);
}
