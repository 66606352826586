import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { Link } from "react-router-dom";

import { Button } from "~/components";
import { useCodeScanner } from "~/hooks";
import { T } from "@repo/transifex";
import { LoadingSpinner } from "~/components/LoadingSpinner";
import { useVerifyTicket } from "~/hooks/queries/tickets";
import {
  ScreenError,
  ScreenErrorTitle,
  ScreenErrorText
} from "~/components/screen/screen-error";
import {
  ScreenSuccessText,
  ScreenSuccessTitle,
  ScreenSuccess
} from "~/components/screen/screen-success";
import { LayoutLock } from "~/components/layout/layout-lock";

import { TicketScanSvg } from "./assets/TicketScanSvg";

export const TicketValidationPage = () => {
  const {
    data: verificationResponse,
    mutate: verifyTicket,
    status: verificationStatus,
    reset
  } = useVerifyTicket();

  useCodeScanner({
    onScan: (scannedCodePayload) => {
      if (verificationStatus !== "idle") {
        // Stop scanning when a modal is shown, or we are validating a ticket
        return;
      }

      verifyTicket(scannedCodePayload);
    }
  });

  // We received Ticket validation response and ticket is valid
  if (verificationStatus === "success" && verificationResponse.isValid) {
    return (
      <ScreenSuccess onClose={reset}>
        <ScreenSuccessTitle>
          <T _str="Ticket confirmed" />
        </ScreenSuccessTitle>

        <ScreenSuccessText>
          <T _str="Head over to collect your order. Enjoy your meal!" />
        </ScreenSuccessText>
      </ScreenSuccess>
    );
  }

  // We received ticket validation response and Ticket is invalid
  if (verificationStatus === "success" && !verificationResponse.isValid) {
    return (
      <ScreenError onClose={reset}>
        <ScreenErrorTitle>
          <T _str="Ticket expired" />
        </ScreenErrorTitle>

        <ScreenErrorText>
          <T _str="We couldn’t find your order. Please try again or ask staff for help" />
        </ScreenErrorText>
      </ScreenError>
    );
  }

  // Ticket validation response failed
  if (verificationStatus === "error") {
    return (
      <ScreenError onClose={reset}>
        <ScreenErrorTitle>
          <T _str="Something went wrong" />
        </ScreenErrorTitle>

        <ScreenErrorText>
          <T _str="Please try again or ask staff for help" />
        </ScreenErrorText>
      </ScreenError>
    );
  }

  return (
    <LayoutLock>
      {verificationStatus === "pending" ? (
        <LoadingSpinner />
      ) : (
        <>
          <TicketScanSvg />
          <p className="w-8/12 text-center text-5xl font-bold">
            <T _str="Scan your ticket or receipt QR code on the scanner" />
          </p>

          <Link to="help">
            <Button
              size="medium"
              className="bg-slate-50"
              textClassName="text-slate-900"
              icon={faInfoCircle}
            >
              <T _str="How you scan your ticket" />
            </Button>
          </Link>
        </>
      )}
    </LayoutLock>
  );
};
