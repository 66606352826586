import { type PropsWithChildren } from "react";

import { KanplaLogoNew } from "~/components/KanplaLogoNew";

export const LandingScreen = ({ children }: PropsWithChildren) => {
  return (
    <div className="size-full">
      <div className="flex h-[calc(100vh-112px)] flex-col justify-between bg-kiosk-background-reversed p-16 pt-32 text-center">
        <div className="flex h-16 justify-center">
          <KanplaLogoNew />
        </div>
        {children}
      </div>

      <div className="flex h-28 bg-white">
        <div className="flex gap-4 p-5">
          <img src="/images/payment-brand/visa.png" alt="visa" />
          <img src="/images/payment-brand/mastercard.png" alt="mastercard" />
          <img src="/images/payment-brand/diners-club.png" alt="diners-club" />
        </div>
      </div>
    </div>
  );
};
