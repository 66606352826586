/* eslint-disable @tanstack/query/exhaustive-deps -- doesn't work with the key factory*/
import { useQuery } from "@tanstack/react-query";

import { useAuth, getAuth, authActions } from "~/providers/store/auth";
import { useDeviceData } from "~/providers/app/hooks";
import { logger } from "~/services/logger";

import { tillsKeys } from "../keys";
import { getTill } from "../requests";

export function useTill(id: string | undefined) {
  return useQuery({
    queryKey: tillsKeys.detail(id ?? "unknown"),
    queryFn: () => {
      if (!id) {
        throw new Error("Till ID is required");
      }
      return getTill(id);
    },
    enabled: Boolean(id)
  });
}

export function useCurrentTill() {
  const { tillId } = useAuth();
  const { deviceId } = useDeviceData();

  return useQuery({
    queryKey: tillsKeys.detail(tillId ?? "unknown"),
    queryFn: async () => {
      if (!tillId) {
        throw new Error("User not authenticated");
      }

      const till = await getTill(tillId);

      const { isKanplaAdmin } = getAuth();
      if (isKanplaAdmin || till.deviceId === deviceId) {
        return till;
      }

      // TO-DO: CLEANUP -- Investigating a bug where deviceId is not a string
      if (typeof deviceId !== "string") {
        logger.warn("Device ID is not a string", {
          till_id: tillId,
          device_id: deviceId
        });
        return till;
      }

      const message = `Till ${tillId} is assigned to a different device than the current one - logging out till`;
      logger.warn(message, {
        till_id: tillId,
        device_id: deviceId
      });

      authActions.logoutTill();

      throw new Error(message);
    },
    enabled: Boolean(tillId)
  });
}
