import { type PropsWithChildren } from "react";

import { KanplaLogoNew } from "~/components/KanplaLogoNew";

type ProductPageHeaderProps = PropsWithChildren & {
  onLogoClick?: () => void;
};

export const ProductPageHeader = ({
  children,
  onLogoClick
}: ProductPageHeaderProps) => {
  return (
    <div className="flex max-h-36 justify-between gap-4 p-9">
      <button type="button" onClick={onLogoClick}>
        <KanplaLogoNew variant="dark" className="!h-16 !w-auto" />
      </button>

      <div className="flex items-center gap-4">{children}</div>
    </div>
  );
};
