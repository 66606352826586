import { useProfiles } from "~/hooks/queries/profiles";
import { useCurrentTill } from "~/api/tills/hooks/use-till";
import { useSession } from "~/hooks/queries/sessions";
import { useProductsPOS } from "~/api/products/hooks/use-products";

export function useLatestUpdates() {
  const { status: productStatus, dataUpdatedAt: productsUpdateAt = 0 } =
    useProductsPOS();

  const { status: profileStatus, dataUpdatedAt: profilesUpdatedAt = 0 } =
    useProfiles();

  const {
    data: currentTill,
    status: currentTillStatus,
    dataUpdatedAt: currentTillUpdatedAt = 0
  } = useCurrentTill();

  const { status: sessionStatus, dataUpdatedAt: sessionUpdatedAt = 0 } =
    useSession(currentTill?.sessionId);

  const last = Math.max(
    productsUpdateAt || 0,
    profilesUpdatedAt || 0,
    currentTillUpdatedAt || 0,
    sessionUpdatedAt || 0
  );

  return {
    last,
    queries: {
      products: { status: productStatus, last: productsUpdateAt },
      profiles: { status: profileStatus, last: profilesUpdatedAt },
      currentTill: { status: currentTillStatus, last: currentTillUpdatedAt },
      session: { status: sessionStatus, last: sessionUpdatedAt }
    }
  };
}
