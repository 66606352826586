import { faMinus, faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { cva, type VariantProps } from "class-variance-authority";
import { clsx } from "clsx";
import { type MouseEventHandler } from "react";

const quantityInputControlStyles = cva(
  "flex items-center justify-center text-center",
  {
    variants: {
      variant: {
        primary:
          "w-full rounded-full bg-kiosk-accent-primary text-2xl shadow-md",
        secondary: "rounded-lg bg-kiosk-background-secondary text-3xl"
      },
      size: {
        md: "h-16 w-44",
        lg: "h-24 w-48"
      }
    },
    defaultVariants: {
      variant: "primary",
      size: "md"
    }
  }
);

const quantityInputControlButtonStyles = cva(
  "aspect-square h-16 bg-transparent",
  {
    variants: {
      variant: {
        primary: "rounded-full",
        secondary: "rounded-lg"
      }
    }
  }
);

type QuantityInputControlProps = VariantProps<
  typeof quantityInputControlStyles
> & {
  quantity: number;
  onIncrementClick: MouseEventHandler<HTMLButtonElement>;
  onDecrementClick: MouseEventHandler<HTMLButtonElement>;
  className?: string;
};

export const QuantityInputControl = ({
  quantity,
  onIncrementClick,
  onDecrementClick,
  variant,
  className
}: QuantityInputControlProps) => {
  return (
    <div className={clsx(quantityInputControlStyles({ variant }), className)}>
      <button
        type="button"
        onClick={onDecrementClick}
        className={quantityInputControlButtonStyles({ variant })}
      >
        <FontAwesomeIcon icon={faMinus} />
      </button>
      <span className="flex-1">{quantity}</span>
      <button
        type="button"
        onClick={onIncrementClick}
        className={quantityInputControlButtonStyles({ variant })}
      >
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </div>
  );
};
