import { useNavigate, useSearchParams } from "react-router-dom";
import { faSpinner } from "@fortawesome/pro-solid-svg-icons";

import { T } from "@repo/transifex";
import { priceFormatter } from "@repo/system";
import { useOrder, useOrderCancel } from "~/hooks/queries/orders";
import { usePaymentCreate } from "~/hooks/queries/payments";
import { useAuth } from "~/providers/store/auth";
import { Button, Dialog, DialogLoading, DialogPayment } from "~/components";
import {
  useAppDispatch,
  useDeviceData,
  useSplitPaymentFlow
} from "~/providers/app";

import { MobilePayIcon } from "../../components/MobilePayIcon";

const PosMobilePay = () => {
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { locale } = useAuth();
  const { data: order, status } = useOrder(orderId);
  const { os, osVersion } = useDeviceData();
  const { mutate: payOrder, status: createPaymentStatus } = usePaymentCreate();
  const { mutate: cancelOrder, status: cancelOrderStatus } = useOrderCancel();
  const { isActive: isSplitPaymentActive, unitReceived } =
    useSplitPaymentFlow();

  const actionsLoading =
    createPaymentStatus === "pending" || cancelOrderStatus === "pending";

  if (status === "pending") {
    return <DialogLoading title={<T _str="Preparing payment" />} />;
  }

  if (
    status === "error" ||
    order.status === "failed" ||
    order.status === "cancelled"
  ) {
    return <DialogPayment status="error" />;
  }

  if (order.status === "paid") {
    return <DialogPayment orderId={order.id} status="success" />;
  }

  return (
    <Dialog className="max-w-sm" size="md">
      <div className="flex w-full flex-col items-center p-4">
        <p className="mb-4 mt-6 text-base">
          <T _str="Amount" />
        </p>

        <p className="mb-14 text-2xl font-bold">
          {priceFormatter({
            value:
              order.totalPriceUnit -
              unitReceived -
              (order.paidWithSaldoUnit ?? 0),
            currency: order.currency,
            locale
          })}
        </p>

        <div className="mb-6 flex w-44 flex-row items-center justify-center gap-2 rounded-2xl bg-info-backdrop p-5">
          <MobilePayIcon />
          <p className="text-lg font-bold text-info-dark">
            <T _str="MobilePay" />
          </p>
        </div>

        <p className="mb-14 text-base">
          <T _str="Make sure the customer has paid" />
        </p>

        <div className="flex w-full justify-center gap-3">
          <Button
            className="flex-1"
            disabled={actionsLoading}
            onClick={() => {
              if (orderId) {
                cancelOrder(orderId);

                if (isSplitPaymentActive) {
                  dispatch({ type: "CASH_DRAWER_OPEN_REQUEST" });
                }
                navigate("..");
              }
            }}
            variant="danger"
          >
            <T _str="Cancel" />
          </Button>

          <Button
            className="flex-[2]"
            disabled={actionsLoading}
            icon={createPaymentStatus === "pending" ? faSpinner : undefined}
            loading={actionsLoading}
            loadingText="Processing order"
            onClick={() => {
              if (orderId && os && osVersion)
                payOrder({
                  orderId,
                  paymentProvider: "mobilepay_own",
                  deviceInfo: {
                    os,
                    osVersion
                  },
                  unitCashReceived: unitReceived
                });
            }}
          >
            <T _str="Complete payment" />
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export { PosMobilePay };
