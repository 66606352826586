import { createContext, useEffect, useReducer, type Dispatch } from "react";

import { useSendWebEvent } from "~/hooks";
import type { NativeEvent } from "~/types/events";
import { WEB_DEVICE_ID, WEB_TILL_ID } from "~/utils/constants";
import { useCreateCashDrawerOpenPosLog } from "~/hooks/queries/logs";

import { authActions } from "../store/auth";

import { initialState, reducer } from "./reducer";
import type { AppAction, AppState } from "./types";

export const AppContext = createContext<AppState>(initialState);
export const AppDispatchContext = createContext<Dispatch<AppAction>>(
  () => null
);

type AppProviderProps = {
  children: React.ReactNode;
};
export const AppProvider = ({ children }: AppProviderProps) => {
  const { mutate: createPosLog } = useCreateCashDrawerOpenPosLog();

  const middleware = (action: AppAction) => {
    if (action.type === "CASH_DRAWER_OPEN_SUCCESS") {
      createPosLog();
    }

    if (action.type === "ONLINE_STATUS_SUCCESS") {
      authActions.updateOnlineStatus(action.payload);
    }
  };

  const [state, dispatch] = useReducerWithMiddleware(
    reducer,
    initialState,
    middleware
  );

  const sendWebEvent = useSendWebEvent();

  useNativeEvents(dispatch);

  useEffect(() => {
    sendWebEvent({ type: "DEVICE_DATA_GET_REQUEST" });

    // DEV HACK - for web license + emulate cash drawer / printer
    if (typeof window.ReactNativeWebView === "undefined") {
      dispatch({
        type: "DEVICE_DATA_GET_SUCCESS",
        payload: {
          deviceId: WEB_DEVICE_ID,
          tillId: WEB_TILL_ID,
          scale: null,
          os: "ios",
          osVersion: "16.3",
          printer: {
            model: "Virtual Cash Drawer (FAKE)",
            emulation: "virtual",
            identifier: "fake-cash-drawer",
            interfaceType: "emulator",
            hasCashDrawer: true
          }
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps -- we olny need it to run once
  }, []);

  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  );
};

/**
 * useReducer wrapper with middleware
 * @returns {[AppState, Dispatch<AppAction>]}
 */
const useReducerWithMiddleware = (
  reducer: (state: AppState, action: AppAction) => AppState,
  initialState: AppState,
  middlewareFn: (action: AppAction) => void
): [AppState, Dispatch<AppAction>] => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const dispatchWithMiddleware = (action: AppAction) => {
    middlewareFn(action);
    dispatch(action);
  };

  return [state, dispatchWithMiddleware];
};

/**
 * Checks if the event is native
 * @param event - intercepted post message
 * @returns true if the event is native
 */
const isNativeEvent = (event: unknown): event is NativeEvent => {
  return Boolean(event) && typeof (event as NativeEvent).type === "string";
};

/**
 * Handle communication from native -> web and listen for the events coming from the native wrapper
 */
export const useNativeEvents = (dispatch: Dispatch<AppAction>) => {
  useEffect(() => {
    if (typeof window === "undefined") return;

    /**
     * Handle the post messages coming from the native app
     * @param postMessage - intercepted post message
     */
    const handleMessage = ({ data }: MessageEvent) => {
      if (typeof data !== "string") return;

      const event = JSON.parse(data) as NativeEvent;
      if (!isNativeEvent(event)) return;

      dispatch(event);
    };

    window.addEventListener("message", handleMessage);
    document.addEventListener("message", handleMessage as EventListener);

    return () => {
      window.removeEventListener("message", handleMessage);
      document.removeEventListener("message", handleMessage as EventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we olny need it to run once
  }, []);
};
