import { type PropsWithChildren } from "react";

import { LandingButton, type LandingButtonProps } from "./landing-button";

type LandingOptionButtonProps = PropsWithChildren & LandingButtonProps;

export const LandingOptionButton = ({
  children,
  ...props
}: LandingOptionButtonProps) => {
  return (
    <LandingButton {...props}>
      <div className="flex flex-col justify-center gap-14 text-kiosk-background-reversed">
        <p className="text-7xl font-bold">{children}</p>
      </div>
    </LandingButton>
  );
};
