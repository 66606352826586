import { devtools, persist } from "zustand/middleware";
import { createStore } from "zustand/vanilla";

import type { EmployeeStore } from "./store.types";

const INITIAL_STATE: EmployeeStore = {
  groupName: null,
  displayName: null,
  employeeChildId: null,
  employeeUserId: null,
  saldo: null,
  stampCardDiscounts: []
};

const employeeStore = createStore<EmployeeStore>()(
  devtools(persist(() => INITIAL_STATE, { name: "employee-store" }))
);

export { INITIAL_STATE, employeeStore };
