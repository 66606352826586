import { useLocation, useNavigate } from "react-router-dom";

import { round } from "@repo/system";
import { T, useT } from "@repo/transifex";
import type { PosProduct, StepVariant, StepVariantWeight } from "@repo/types";
import { SwipeToDelete } from "~/components/SwipeToDelete";
import { useCurrentTill } from "~/api/tills/hooks/use-till";
import { basketActions, useOrderDiscount } from "~/providers/store/basket";

type BasketItemProps = {
  basketProductId: string;
  productName: string;
  quantity: number;
  options?: (StepVariant | StepVariantWeight)[];
  totalDisplayPrice: string;
  totalOriginalDisplayPrice: string;
  discountPercentage?: number;
  disabled: boolean;
  stampCardRuleId?: string;
  // hybrid billing
  discount: PosProduct["discount"];
};

type DisplayDiscount = {
  name: string;
  percentage: number;
};

export const BasketItem = ({
  basketProductId,
  productName,
  quantity,
  options,
  totalDisplayPrice,
  totalOriginalDisplayPrice,
  discountPercentage,
  disabled,
  stampCardRuleId,
  discount
}: BasketItemProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const t = useT();

  const { data: till, status } = useCurrentTill();

  const orderDiscount = useOrderDiscount();

  const hasOrderDiscount = orderDiscount.amount > 0;
  const hasVariants =
    typeof options !== "undefined" && options[0].name !== "scale_weight";

  const weightChoice =
    typeof options !== "undefined" &&
    "weight" in options[0].choices[0] &&
    options[0].choices[0];

  const gramsLabel = t("grams");
  const weightLabels = {
    "1-gram": gramsLabel,
    "100-grams": gramsLabel,
    kilogram: gramsLabel,
    piece: ""
  };

  if (status !== "success") return null;

  const displayDiscounts: DisplayDiscount[] = [];
  if (discount?.amount) {
    displayDiscounts.push({
      name: discount.name,
      percentage: discount.percentage
    });
  }
  if (
    discountPercentage &&
    !hasVariants &&
    !weightChoice &&
    !hasOrderDiscount
  ) {
    displayDiscounts.push({
      name: t("discount"),
      percentage: discountPercentage
    });
  }

  const shouldDisplayDiscounts =
    displayDiscounts.length > 0 || (discountPercentage && !hasOrderDiscount);

  return (
    <SwipeToDelete
      onDelete={() => {
        basketActions.remove(basketProductId);
      }}
    >
      <button
        className="flex w-full items-center justify-between gap-5 border-b border-divider-main bg-background-primary p-4"
        disabled={disabled}
        onClick={() => {
          if (stampCardRuleId) {
            navigate(`${pathname}/stampcard`);
            return;
          }

          navigate(`${pathname}/basket-product/${basketProductId}`);
        }}
        type="button"
      >
        <div className="flex items-center gap-5">
          <span className="text-lg font-semibold text-text-primary">
            {quantity}
          </span>

          <div className="flex flex-col gap-0.5 text-left">
            <span className="line-clamp-2 text-lg text-text-primary">
              {productName}
            </span>

            {hasVariants ? (
              <span className="text-sm text-text-secondary">
                {options
                  .flatMap((o) =>
                    o.choices.flatMap((c) => {
                      if (c.amount === 0) return [];
                      return `${c.amount}x ${c.name}`;
                    })
                  )
                  .join(", ")}
              </span>
            ) : null}

            {weightChoice !== false && weightChoice.name ? (
              <span className="text-sm text-text-secondary">
                {`${weightChoice.weight} ${weightLabels[weightChoice.name]}`}
              </span>
            ) : null}

            {shouldDisplayDiscounts
              ? displayDiscounts.map((displayDiscount) => (
                  <BasketItemDiscount
                    key={displayDiscount.name}
                    name={displayDiscount.name}
                    percentage={displayDiscount.percentage}
                  />
                ))
              : null}
          </div>
        </div>

        {till.moduleConfig.hidePrices ? null : (
          <div className="flex items-center gap-3">
            {shouldDisplayDiscounts ? (
              <span className="text-text-secondary line-through	">
                {totalOriginalDisplayPrice}
              </span>
            ) : null}

            <span className="font-semibold text-text-secondary">
              {totalDisplayPrice}
            </span>
          </div>
        )}
      </button>
    </SwipeToDelete>
  );
};

type BasketItemDiscountProps = DisplayDiscount;
const BasketItemDiscount = ({ name, percentage }: BasketItemDiscountProps) => {
  return (
    <span className="text-sm text-text-secondary">
      <T
        _str="{percentageDiscount}% {discountName}"
        percentageDiscount={round(percentage, 2)}
        discountName={name}
      />
    </span>
  );
};
