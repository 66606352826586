import { clsx } from "clsx";
import { type PropsWithChildren } from "react";

type ModalButtonGroupProps = PropsWithChildren<{
  className?: string;
  direction?: "horizontal" | "vertical";
}>;

export const ModalButtonGroup = ({
  children,
  className,
  direction = "horizontal"
}: ModalButtonGroupProps) => (
  <div
    className={clsx(
      "flex w-full justify-center gap-8",
      {
        "flex-row gap-x-5": direction === "horizontal",
        "flex-col": direction === "vertical"
      },
      className
    )}
  >
    {children}
  </div>
);
