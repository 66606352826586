import { type PropsWithChildren } from "react";
import { cva, type VariantProps } from "class-variance-authority";

const modalTitleStyles = cva("", {
  variants: {
    variant: {
      error: "text-kiosk-text-error",
      info: "text-kiosk-accent-primary",
      normal: "text-kiosk-text-primary"
    },
    size: {
      "7xl": "text-7xl",
      "8xl": "text-8xl",
      "9xl": "text-9xl"
    },
    weight: {
      normal: "font-normal",
      medium: "font-medium",
      semibold: "font-semibold",
      bold: "font-bold",
      extraBold: "font-extrabold",
      black: "font-black"
    }
  },
  defaultVariants: {
    variant: "info",
    size: "7xl",
    weight: "normal"
  }
});

type ModalTitleProps = VariantProps<typeof modalTitleStyles> & {
  className?: string;
};

export const ModalTitle = ({
  children,
  className,
  variant,
  size,
  weight
}: PropsWithChildren<ModalTitleProps>) => (
  <h1 className={modalTitleStyles({ variant, size, weight, className })}>
    {children}
  </h1>
);
