import { useMutation } from "@tanstack/react-query";

import { getAuth } from "~/providers/store/auth";
import { logger } from "~/services/logger";
import { client, getAuthHeader } from "~/api/client";

import { type CodeValidationResult } from "../useCodeScanner";

export const useVerifyTicket = () => {
  return useMutation({
    mutationFn: async (scannedCodePayload: CodeValidationResult) => {
      if (!scannedCodePayload || scannedCodePayload.codeType !== "TICKET") {
        logger.warn("Invalid ticket code scanned", { scannedCodePayload });
        throw new Error("Invalid ticket code scanned");
      }

      const { tillId, status } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const result = await client.v1.pos.tills.validateQr({
        headers: getAuthHeader(),
        params: {
          tillId
        },
        body: {
          code: scannedCodePayload.code
        }
      });

      if (result.status === 200) {
        return result.body;
      }

      throw result;
    },
    onError: (error) => {
      logger.error(error, "Ticket validation failed");
    }
  });
};
