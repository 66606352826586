import { useMemo } from "react";
import { useStore } from "zustand";
import { useShallow } from "zustand/react/shallow";

import type { StepVariant } from "@repo/types";
import { priceFormatter, round } from "@repo/system";

import { useBasketProducts } from "../../app";
import { useAuth } from "../auth";

import {
  getAmount,
  getCurrentVariantStep,
  getNextVariantStep,
  getSteps,
  getVariants
} from "./getters";
import { modalVariantsStore } from "./store";
import type { ModalVariantsStore, VariantStep } from "./store.types";

function useVariantAmount(): ModalVariantsStore["amount"] {
  return useStore(modalVariantsStore, getAmount);
}

function useVariants(): ModalVariantsStore["variants"] {
  return useStore(modalVariantsStore, useShallow(getVariants));
}

function useVariantModalSteps(productId: string | undefined) {
  const authData = useAuth();
  const { variants, products, meta } = useBasketProducts();

  const product = products.find((p) => p.id === productId);

  const {
    steps: storeSteps,
    currentStep: storeCurrentStep,
    nextStep: storeNextStep
  } = useStore(modalVariantsStore, useShallow(getSteps));

  return useMemo(() => {
    const steps: StepVariant[] = storeSteps.flatMap((variantStep) => {
      const variant = variants.find((variant) => variant.id === variantStep.id);
      if (!variant) return [];

      const choices = [
        ...variant.choices.map((choice) => {
          const unitPrice = choice.unitPrice ?? 0;
          const unitPriceWithVat = round(
            unitPrice * (1 + (product?.vatRate ?? 0.25)),
            2
          );
          return {
            ...choice,
            amount:
              variantStep.choices.find((c) => c.id === choice.id)?.amount ?? 0,
            displayPrice: priceFormatter({
              value: unitPriceWithVat,
              currency: meta.currency,
              locale: authData.locale
            }),
            unitPriceWithVat
          };
        })
      ];

      const totalChoiceAmount =
        choices.reduce((acc, c) => acc + c.amount, 0) ?? 0;

      return {
        ...variant,
        choices,
        isCompleted: Boolean(variantStep.completed),
        isNext: storeNextStep?.id === variant.id,
        isCurrent: storeCurrentStep?.id === variant.id,
        isValid: variant.minimum ? totalChoiceAmount >= variant.minimum : true
      };
    });

    const currentStep = steps.find((step) => step.id === storeCurrentStep?.id);
    const nextStep = steps.find((step) => step.id === storeNextStep?.id);
    const isSubmitDisabled = steps.some((step) => !step.isValid);

    return {
      steps,
      currentStep,
      nextStep,
      isSubmitDisabled
    };
  }, [
    storeSteps,
    variants,
    storeNextStep?.id,
    storeCurrentStep?.id,
    product?.vatRate,
    meta.currency,
    authData.locale
  ]);
}

function useCurrentVariantStep(): VariantStep | null {
  return useStore(modalVariantsStore, useShallow(getCurrentVariantStep));
}

function useNextVariantStep(): VariantStep | null {
  return useStore(modalVariantsStore, useShallow(getNextVariantStep));
}

export {
  useCurrentVariantStep,
  useNextVariantStep,
  useVariantAmount,
  useVariantModalSteps,
  useVariants
};
