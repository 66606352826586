import { clsx } from "clsx";
import { type AllowanceDetail } from "@kanpla/api-contract";

import { type Currency } from "~/api/products/types";
import { round } from "@repo/system";
import { T } from "@repo/transifex";

type AllowanceItemProps = {
  allowance: AllowanceDetail;
  currency: Currency;
};

export const AllowanceRow = ({ allowance, currency }: AllowanceItemProps) => {
  const remaining =
    allowance.type === "numberOfProducts"
      ? allowance.remaining
      : round(allowance.remaining / 100, 2);

  return (
    <div
      className={clsx(
        "flex w-full items-center justify-between rounded-md bg-background-secondary px-4 py-2 text-primary-dark",
        {
          "text-text-disabled": allowance.isAllowanceOver
        }
      )}
    >
      <div className="flex flex-col">
        <p className="font-semibold">
          <T _str="Allowance" />
        </p>

        <Period period={allowance.period} />
      </div>

      <p className="font-semibold">
        <RemainingUsage
          remaining={remaining}
          remainingType={allowance.type}
          currency={currency}
        />
      </p>
    </div>
  );
};

const Period = ({ period }: { period: AllowanceDetail["period"] }) => {
  switch (period) {
    case "DAILY__RRULE_STRING":
      return <T _str="left today" />;
    case "WEEKLY__RRULE_STRING":
      return <T _str="left this week" />;
    case "BIWEEKLY__RRULE_STRING":
      return <T _str="left this bi-week" />;
    case "MONTHLY__RRULE_STRING":
    case "MONTHLY_END_RRULE_STRING":
      return <T _str="left this month" />;
    default:
      return null;
  }
};

type RemainingUsageProps = {
  remaining: number;
  remainingType: AllowanceDetail["type"];
  currency: Currency;
};
const RemainingUsage = ({
  remaining,
  remainingType,
  currency
}: RemainingUsageProps) => {
  if (remainingType === "numberOfProducts") {
    if (remaining === 1) {
      return <T _str="1 product" />;
    }
    return <T _str="{remaining} products" remaining={remaining} />;
  }

  return `${remaining} ${currency}`;
};
