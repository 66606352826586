import { faBasketShopping } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T } from "@repo/transifex";

type AddToOrderButtonProps = {
  price: string;
} & JSX.IntrinsicElements["button"];

export const AddToOrderButton = ({
  price,
  ...props
}: AddToOrderButtonProps) => {
  return (
    <button
      type="button"
      className="flex h-24 w-full items-center justify-between rounded-xl bg-kiosk-background-reversed px-8 text-kiosk-accent-primary"
      {...props}
    >
      <div className="flex items-center gap-2 text-3xl font-bold">
        <FontAwesomeIcon icon={faBasketShopping} />
        <T _str="Add" />
      </div>

      <p className="text-5xl font-bold">{price}</p>
    </button>
  );
};
