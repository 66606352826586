import { useLocation } from "react-router-dom";

import { useT } from "@repo/transifex";
import { SettingsLayout, TableTransactions, Time } from "~/components";
import { useDayFilter } from "~/hooks";
import { useOrders } from "~/hooks/queries/orders";
import { useAuth } from "~/providers/store/auth";

type LocationState =
  | {
      showBackButton?: boolean;
    }
  | undefined;

const TransactionsPage = () => {
  const location = useLocation();
  const showBackButton =
    (location.state as LocationState)?.showBackButton ?? false;

  const t = useT();

  const { tillId } = useAuth();

  const { start, end, label, handleNext, handlePrev } = useDayFilter();

  const { data: orders, status } = useOrders({
    tillId: tillId ?? undefined,
    start,
    end
  });

  return (
    <SettingsLayout
      action={<Time label={label} onNext={handleNext} onPrev={handlePrev} />}
      title={t("Transactions")}
      {...(showBackButton && {
        backUrl: "/pos"
      })}
      className="pb-0"
    >
      <TableTransactions
        data={status === "success" ? orders.orders : undefined}
        status={status}
      />
    </SettingsLayout>
  );
};

export { TransactionsPage };
