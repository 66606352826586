import { faAngleDown } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";
import type { ChangeEvent } from "react";
import React, { useEffect, useState } from "react";

import { round } from "@repo/system";
import { useT } from "@repo/transifex";
import type { PriceExcludingVat, VatRate } from "@repo/types";

import { BaseInput } from "./BaseInput";

type InputProps = {
  className?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  placeholder: string;
  value: number | string;
  onVatCalculationClick: () => void;
  label: string;
};

const Input = ({
  className,
  onChange,
  placeholder,
  value,
  onVatCalculationClick,
  label
}: InputProps) => {
  return (
    <div className="flex h-12 items-center">
      <BaseInput
        className={clsx(
          className,
          "h-full w-16 rounded-r-none [appearance:textfield] [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
        )}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
      />

      <div
        className="flex h-full items-center gap-1 whitespace-nowrap rounded-r-md border border-l-0 border-divider-main bg-background-secondary p-2 text-center"
        onClick={onVatCalculationClick}
      >
        {label}
        <FontAwesomeIcon icon={faAngleDown} />
      </div>
    </div>
  );
};

type PriceInputProps = {
  value?: PriceExcludingVat;
  vatRate: VatRate;
  customVatRate?: VatRate;
  vatCalculation: "excl" | "incl";
  onVatCalculationChange: (value: "excl" | "incl") => void;
  onChange: (value: number) => void;
  className?: string;
  /** Should show prices inc. VAT by default? */
  defaultShowingVat?: boolean;
};

export const PriceInput = React.forwardRef<HTMLInputElement, PriceInputProps>(
  ({
    value,
    vatRate: _vatRate,
    customVatRate,
    onVatCalculationChange,
    onChange,
    className,
    vatCalculation
  }) => {
    /** Convert from øre to kroner (or from cents fo EUR) */
    const t = useT();
    const [displayValue, setDisplayValue] = useState<string>(
      value ? (value / 100).toString() : ""
    );
    const [price, setPrice] = useState(value ?? 0);

    const vatRate =
      (customVatRate ? customVatRate / 100 : undefined) ?? _vatRate;

    useEffect(() => {
      if (!onChange) return;

      onChange(
        (vatCalculation === "incl" ? price / (1 + vatRate) : price) * 100
      );
    }, [displayValue, vatRate, vatCalculation, price, onChange]);

    const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
      // Replace comma with dot
      const convertedString = event.target.value.replace(",", ".");

      // Check if there are more than two decimal places
      const decimalParts = convertedString.split(".");
      const decimalPlaces = decimalParts[1] ? decimalParts[1].length : 0;
      const truncatedString =
        decimalPlaces > 2
          ? `${decimalParts[0]}.${decimalParts[1].substring(0, 2)}`
          : convertedString;

      // Convert back to number
      const convertedNumber = Number(
        truncatedString.replace(/[.,]\d{3}/g, "").replace(/,/, ".")
      );

      setDisplayValue(truncatedString);
      setPrice(round(convertedNumber, 6));
    };

    return (
      <Input
        className={className}
        label={
          vatCalculation === "incl"
            ? t("{vatRate}% VAT", { vatRate: vatRate * 100 })
            : t("Excl. VAT")
        }
        onChange={handleInput}
        placeholder={
          vatCalculation === "incl"
            ? t("Enter price incl. VAT")
            : t("Enter price excl. VAT")
        }
        onVatCalculationClick={() => {
          setDisplayValue(price.toString());

          onVatCalculationChange(vatCalculation === "incl" ? "excl" : "incl");
        }}
        value={displayValue}
      />
    );
  }
);

PriceInput.displayName = "PriceInput";
