import { Navigate } from "react-router-dom";

import { T } from "@repo/transifex";
import { type Till } from "~/api/tills/types";
import { useKioskSessionStatus } from "~/stores/kiosk-session/hooks/use-kiosk-session";
import { kioskActions } from "~/stores/kiosk-session/actions";
import { LandingGrid } from "~/components/kiosk/landing/landing-grid";
import { LandingOptionButton } from "~/components/kiosk/landing/landing-option-button";
import { LandingBeginButton } from "~/components/kiosk/landing/landing-begin-button";
import { LandingScreen } from "~/components/kiosk/landing/landing-screen";

import { useKioskStore } from "../__app.kiosk/route";

export const KioskLandingPage = () => {
  const till = useKioskStore((state) => state.till);
  const status = useKioskSessionStatus();

  if (status === "active") {
    return <Navigate to="/kiosk/products" />;
  }

  const textInput = getDiningOptionsInput(till.preCheckoutTextInputs);

  if (textInput) {
    return (
      <LandingScreen>
        <div className="flex flex-col gap-8">
          <h1 className="text-[164px] font-bold leading-none text-kiosk-text-primary">
            <T _str="Ready to order?" />
          </h1>

          <div className="text-6xl text-[#A0B2A4]">
            <T _str="Where will you be eating?" />
          </div>

          <LandingGrid itemCount={textInput.diningOptions.length}>
            {textInput.diningOptions.map((option) => (
              <LandingOptionButton
                key={option}
                className={
                  textInput.diningOptions.length === 2
                    ? "!h-[560px]"
                    : undefined
                }
                onClick={() => {
                  kioskActions.session.setDiningOption({
                    inputId: textInput.inputId,
                    value: option
                  });
                  kioskActions.session.start();
                }}
              >
                {option}
              </LandingOptionButton>
            ))}
          </LandingGrid>
        </div>
      </LandingScreen>
    );
  }

  return (
    <LandingScreen>
      <h1 className="text-[164px] font-bold leading-none text-kiosk-text-primary">
        <T _str="Ready to order?" />
      </h1>

      <LandingBeginButton
        onClick={() => {
          kioskActions.session.start();
        }}
      />
    </LandingScreen>
  );
};

const getDiningOptionsInput = (
  preCheckoutTextInputs: Till["preCheckoutTextInputs"]
): { inputId: string; diningOptions: string[] } | undefined => {
  if (!preCheckoutTextInputs || preCheckoutTextInputs.length === 0) {
    return undefined;
  }

  const firstInput = preCheckoutTextInputs[0];
  const inputId = firstInput.key;

  if (!inputId || !firstInput.options) {
    return undefined;
  }

  const diningOptions = firstInput.options.slice(0, 4);

  if (diningOptions.length === 0) {
    return undefined;
  }

  return { inputId, diningOptions };
};
