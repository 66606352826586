import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { clsx } from "clsx";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { T } from "@repo/transifex";
import {
  Button,
  DialogError,
  DialogLoading,
  UnattendedLogoutButton
} from "~/components";
import { OrderCreateErrorDialog } from "~/components/OrderCreateErrorDialog";
import { useCardReader, useCountdown } from "~/hooks";
import { useOrderCreate, usePrintOrderReceipt } from "~/hooks/queries/orders";
import { useCurrentTill } from "~/api/tills/hooks/use-till";
import {
  useAppDispatch,
  useBasketProducts,
  useCountDownState
} from "~/providers/app";
import { employeeActions, getEmployee } from "~/providers/store/employee";
import { isApiError } from "@repo/system";
import { LoadingSpinner } from "~/components/LoadingSpinner";
import { logger } from "~/services/logger";
import { useAuthEmployeeCard } from "~/hooks/queries/auth";

import { ScanCardSvg } from "./ScanCardSvg";
import { ScanErrorSvg } from "./ScanErrorSvg";
import { RegistrationComplete } from "./RegistrationComplete";

export const EmployeeCardPage = () => {
  const navigate = useNavigate();

  const { count } = useCountDownState();
  useCountdown();

  const dispatch = useAppDispatch();

  const {
    products,
    meta: { currency }
  } = useBasketProducts();

  const { data: till, status: tillStatus } = useCurrentTill();

  const {
    mutate: submitCode,
    isPending: isLoading,
    error,
    reset
  } = useAuthEmployeeCard();

  const {
    mutate: createOrder,
    status: createOrderStatus,
    error: createOrderError,
    reset: createOrderReset,
    isSuccess: isCreateOrderSuccess
  } = useOrderCreate();

  const { mutate: createReceipt } = usePrintOrderReceipt();

  const handleReset = () => {
    employeeActions.reset();
    createOrderReset();
    reset();
  };

  useEffect(() => {
    dispatch({ type: "COUNTDOWN_STOP" });

    if (error) {
      dispatch({ type: "COUNTDOWN_RESET", payload: 5 });
      dispatch({ type: "COUNTDOWN_START", payload: 5 });
    }

    if (createOrderStatus === "success") {
      dispatch({ type: "COUNTDOWN_RESET", payload: 2 });
      dispatch({ type: "COUNTDOWN_START", payload: 2 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on scanning error or order creation success
  }, [error, isCreateOrderSuccess]);

  useEffect(() => {
    if (count === 0) {
      dispatch({ type: "COUNTDOWN_STOP" });
      handleReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on count
  }, [count]);

  function handleAutomaticFlow() {
    if (!till) {
      throw new Error("Till not found");
    }

    const paymentMethod = till.moduleConfig.paymentMethod;
    const noPaymentsMethodsAvailable = till.paymentProviders.length === 0;

    const isCreditWithNoPayments =
      paymentMethod === "credit" && noPaymentsMethodsAvailable;

    const isValidPaymentMethod =
      paymentMethod === "billing" || isCreditWithNoPayments;

    if (products.length !== 1 || !isValidPaymentMethod) {
      navigate("/pos");
      return;
    }

    const product = products[0];
    const hybridDiscountAmount =
      product.discount?.type === "discount-hybridBilling"
        ? product.discount?.amount
        : 0;

    const { groupName, employeeUserId, employeeChildId, saldo } = getEmployee();

    if (!employeeUserId || !employeeChildId) {
      throw new Error("Employee not found");
    }

    if (paymentMethod === "credit" && saldo === null) {
      throw new Error("Saldo not found");
    }

    const totalPrice = product.unitPrice - hybridDiscountAmount;
    const hasEnoughBalance =
      paymentMethod === "credit" ? saldo && saldo >= totalPrice : true;

    if (!hasEnoughBalance) {
      navigate("/employee-card/error");
      return;
    }

    // In the "credit" module, the unitPrice from the product API already includes the VAT rate.
    // In contrast, in the "billing" module, the unitPrice does not include the VAT rate.
    // The POST request for order creation requires the unit price without the VAT rate in both cases.
    // Therefore, for "credit" modules, the exclVatUnitPrice is used, while for "billing" modules, the unitPrice is used directly.
    const unitPrice =
      paymentMethod === "billing"
        ? product.unitPrice
        : product.exclVatUnitPrice;

    createOrder(
      {
        paymentMethod,
        basket: [
          {
            amount: 1,
            category: product.category,
            unitPrice,
            name: product.name,
            id: product.id,
            productLineId: product.productLineId,
            type: "regular",
            vatRate: product.vatRate,
            taxRateId: product.taxRateId,
            taxRateName: product.taxRateName,
            discount:
              product.discount?.type === "discount-hybridBilling"
                ? product.discount
                : undefined
          }
        ],
        groupName: groupName || undefined,
        employeeUserId,
        employeeChildId,
        paidWithSaldoUnit:
          paymentMethod === "credit"
            ? product.unitPrice - hybridDiscountAmount
            : 0
      },
      {
        onSuccess: (order) => {
          createReceipt({
            orderId: order.id,
            isTicket: till?.moduleConfig.hidePrices ?? false
          });
        }
      }
    );
  }

  useCardReader({
    onScan: (code) => {
      dispatch({ type: "COUNTDOWN_STOP" });
      submitCode(
        { cardHex: code, currency },
        {
          onSuccess: () => {
            handleAutomaticFlow();
          },
          onError: (error) => {
            logger.error(error, "Could not submit scanned code", {
              code,
              till_id: till?.id,
              module_config: till?.moduleConfig
            });
          }
        }
      );
    }
  });

  if (tillStatus === "pending") return <DialogLoading />;

  // Till not found or wrong route
  if (
    tillStatus === "error" ||
    till.type !== "self-service" ||
    !till.config.hasEmployeeCardReader
  )
    return (
      <DialogError childrenPosition="top" size="md">
        <T _str="Employee card scanning is not available" />
        <Link to="/pos">
          <Button variant="danger">
            <T _str="Back" />
          </Button>
        </Link>
      </DialogError>
    );

  // Billing order creation error
  if (createOrderStatus === "error") {
    const orderError = isApiError(createOrderError)
      ? createOrderError.body.message
      : "Could not create order";

    return (
      <OrderCreateErrorDialog error={orderError} open reset={handleReset} />
    );
  }

  // Employee card scanning error
  if (error) {
    const isChildNotFoundError =
      isApiError(error) &&
      error.status === 404 &&
      error.body.message === "Child not found";
    return (
      <EmployeeCardScanWrapper guestSelector={till.config.guestSelector}>
        <div className="flex flex-col items-center justify-center gap-2">
          <p className="text-center text-4xl text-text-primary">
            {isChildNotFoundError ? (
              <T _str="Sorry, we can't find your employee card" />
            ) : (
              <T _str="Oops, something went wrong. Please try again" />
            )}
          </p>

          {isChildNotFoundError ? (
            <div className="w-fit rounded-md bg-primary-backdrop px-4 py-2 text-primary-dark">
              <T _str="Please contact your administrator" />
            </div>
          ) : null}
        </div>

        <div className="absolute bottom-0">
          <ScanErrorSvg />
        </div>
      </EmployeeCardScanWrapper>
    );
  }

  if (isLoading || createOrderStatus === "pending") {
    return (
      <EmployeeCardScanWrapper
        className="opacity-30"
        guestSelector={till.config.guestSelector}
      >
        <LoadingSpinner />
      </EmployeeCardScanWrapper>
    );
  }

  //Order creation success
  if (createOrderStatus === "success") {
    return (
      <EmployeeCardScanWrapper
        guestSelector={till.config.guestSelector}
        variant="success"
      >
        <RegistrationComplete onClick={handleReset} />
      </EmployeeCardScanWrapper>
    );
  }

  return (
    <EmployeeCardScanWrapper guestSelector={till.config.guestSelector}>
      <div className="absolute bottom-0">
        <ScanCardSvg />
      </div>
    </EmployeeCardScanWrapper>
  );
};

type EmployeeCardScanWrapperProps = {
  children: React.ReactNode;
  className?: string;
  variant?: "success" | "default";
  guestSelector: string | null;
};

const EmployeeCardScanWrapper = ({
  guestSelector,
  children,
  className,
  variant = "default"
}: EmployeeCardScanWrapperProps) => {
  const navigate = useNavigate();

  return (
    <div
      className={clsx("size-full p-5", {
        "bg-slate-100": variant === "default",
        "bg-primary-main": variant === "success"
      })}
    >
      <div
        className={clsx("flex justify-end", {
          invisible: variant === "success"
        })}
      >
        <UnattendedLogoutButton />
      </div>
      <div className="flex w-full flex-col items-center justify-center">
        <div className="flex w-full max-w-screen-sm flex-col items-center">
          <div
            className={clsx(className, "flex flex-col items-center", {
              invisible: variant === "success"
            })}
          >
            <h2 className="text-lg uppercase text-text-primary">
              <T _str="Lunch registration" />
            </h2>
            <h1 className="mb-4 text-5xl font-medium text-text-primary">
              <T _str="Scan your card" />
            </h1>
          </div>

          <div className="relative flex h-[354px] w-full items-center justify-center rounded-md bg-background-primary">
            {children}
          </div>

          {guestSelector ? (
            <Button
              className={clsx("mt-4 w-full", className)}
              onClick={() => {
                employeeActions.setGroupName(guestSelector);
                navigate("/pos");
              }}
              size="xl"
              variant="info-inverted"
            >
              <T _str="Continue as" />
              <span className="ml-1">
                <b>
                  <T _str="Guest" />
                  <FontAwesomeIcon className="ml-2" icon={faArrowRight} />
                </b>
              </span>
            </Button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
