import { clsx } from "clsx";
import type { ReactNode } from "react";
import { useState } from "react";

import type { NumPadProps } from "../NumPad";
import { NumPadDialog } from "../NumPadDialog";

type InputWithNumPadProps = {
  amount: number | null;
  onChange: (value: number) => void;
  suffix?: ReactNode | string;
  className?: string;
} & Pick<NumPadProps, "variant" | "placeholder">;

export const InputWithNumPad = ({
  amount,
  onChange,
  suffix,
  className,
  placeholder,
  variant
}: InputWithNumPadProps) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div
        className={clsx(
          "relative flex w-full items-center justify-center rounded-md border border-divider-main px-2.5 py-2",
          className,
          amount === null ? "text-text-disabled" : "text-text-primary"
        )}
        onClick={() => {
          setOpen(true);
        }}
      >
        <span>{amount ?? placeholder}</span>
        {suffix ? (
          <span
            className={clsx(
              "absolute inset-y-2 right-2 flex items-center justify-center border-divider-main",
              {
                "rounded-md bg-secondary-backdrop px-3 font-semibold text-text-primary":
                  typeof suffix === "string"
              }
            )}
          >
            {suffix}
          </span>
        ) : null}
      </div>

      <NumPadDialog
        onChange={onChange}
        open={open}
        placeholder={placeholder}
        setOpen={setOpen}
        value={amount ?? 0}
        variant={variant}
      />
    </>
  );
};
