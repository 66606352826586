type ProductDescriptionProps = {
  description: string;
};

export const ProductDescription = ({
  description
}: ProductDescriptionProps) => {
  return (
    <div className="text-xl">
      <p>{description}</p>
    </div>
  );
};
