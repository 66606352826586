import { useCallback, useRef } from "react";

type PerformanceMeasurement = {
  /**
   * Start measuring performance
   * @param name Optional name for the measurement
   * @returns void
   */
  start: (name?: string) => void;
  /**
   * End measuring performance and get the duration in milliseconds
   * @param name Optional name for the measurement (must match the start name)
   * @returns duration in milliseconds
   */
  end: (name?: string) => number;
  /**
   * Get all measurements for a given name
   * @param name The name of the measurements to retrieve
   */
  getMeasurements: (name: string) => PerformanceEntry[];
  /**
   * Clear all measurements
   */
  clearMeasurements: () => void;
};

/**
 * Hook for measuring performance using the Web Performance API
 * @returns Object with performance measurement functions
 *
 * @example
 * ```tsx
 * const { start, end, getMeasurements } = usePerformance();
 *
 * const handleScan = async () => {
 *   start("scan");
 *   await someOperation();
 *   const duration = end("scan");
 *
 *   // Get all scan measurements
 *   const measurements = getMeasurements("scan");
 * };
 * ```
 */
export const usePerformance = (): PerformanceMeasurement => {
  const markPrefix = useRef(`perf-${Math.random().toString(36).slice(2)}`);

  const getMarkName = useCallback((type: "start" | "end", name = "default") => {
    return `${markPrefix.current}-${name}-${type}`;
  }, []);

  const start = useCallback(
    (name = "default") => {
      try {
        const markName = getMarkName("start", name);
        performance.mark(markName);
      } catch {
        // Silently fail if Performance API is not available
      }
    },
    [getMarkName]
  );

  const end = useCallback(
    (name = "default") => {
      try {
        const startMark = getMarkName("start", name);
        const endMark = getMarkName("end", name);
        const measureName = `${markPrefix.current}-${name}`;

        // Create end mark
        performance.mark(endMark);

        // Create measurement
        performance.measure(measureName, startMark, endMark);

        // Get the measurement
        const entries = performance.getEntriesByName(measureName);
        const lastEntry = entries[entries.length - 1];

        // Cleanup marks
        performance.clearMarks(startMark);
        performance.clearMarks(endMark);

        return lastEntry?.duration ?? 0;
      } catch {
        // Silently fail if Performance API is not available
        return 0;
      }
    },
    [getMarkName]
  );

  const getMeasurements = useCallback((name: string) => {
    try {
      const measureName = `${markPrefix.current}-${name}`;
      return performance.getEntriesByName(measureName);
    } catch {
      return [];
    }
  }, []);

  const clearMeasurements = useCallback(() => {
    try {
      performance
        .getEntriesByType("mark")
        .filter((entry) => entry.name.startsWith(markPrefix.current))
        .forEach((entry) => {
          performance.clearMarks(entry.name);
        });

      performance
        .getEntriesByType("measure")
        .filter((entry) => entry.name.startsWith(markPrefix.current))
        .forEach((entry) => {
          performance.clearMeasures(entry.name);
        });
    } catch {
      // Silently fail if Performance API is not available
    }
  }, []);

  return { start, end, getMeasurements, clearMeasurements };
};
