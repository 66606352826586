import { type IconDefinition } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type RoundButtonProps = {
  icon: IconDefinition;
} & JSX.IntrinsicElements["button"];

export const RoundButton = ({ icon, ...props }: RoundButtonProps) => {
  return (
    <button
      className="aspect-square h-16 rounded-full bg-white/85 text-2xl shadow-md"
      type="button"
      {...props}
    >
      <FontAwesomeIcon icon={icon} />
    </button>
  );
};
