import { clsx } from "clsx";
import type { ReactNode } from "react";
import { forwardRef, useRef } from "react";

type BaseInputProps = {
  className?: string;
  suffix?: ReactNode | string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
} & React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  ({ className, suffix, ...rest }, ref) => {
    const suffixRef = useRef<HTMLElement>(null);

    if (suffix) {
      const suffixWidth = suffixRef.current?.clientWidth ?? 0;
      return (
        <div className="relative w-full">
          <input
            className={clsx(
              className,
              "w-full rounded-md border border-divider-main px-2.5 py-2"
            )}
            style={{
              paddingRight: `${suffixWidth + 16}px`
            }}
            ref={ref}
            {...rest}
          />

          <span
            className={clsx(
              "absolute inset-y-2 right-2 flex items-center justify-center border-divider-main",
              {
                "rounded-md bg-secondary-backdrop px-3 font-semibold text-text-primary":
                  typeof suffix === "string"
              }
            )}
            ref={suffixRef}
          >
            {suffix}
          </span>
        </div>
      );
    }

    return (
      <input
        className={clsx(
          className,
          "rounded-md border border-divider-main px-2.5 py-2"
        )}
        ref={ref}
        {...rest}
      />
    );
  }
);

BaseInput.displayName = "BaseInput";
