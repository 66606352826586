import { type IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { animated, useSpring } from "@react-spring/web";
import { cva, type VariantProps } from "class-variance-authority";
import { type PropsWithChildren } from "react";

const modalButtonStyles = cva(
  "flex h-32 w-full items-center justify-center gap-x-4 rounded-3xl px-8 py-6 backdrop-blur-sm",
  {
    variants: {
      variant: {
        success:
          "bg-kiosk-background-success-alt/50 font-normal text-kiosk-text-primary",
        idle: "bg-white/15 font-semibold text-white",
        transparent: "border border-white font-semibold text-white"
      },
      size: {
        medium: "text-4xl",
        large: "text-5xl"
      }
    },
    defaultVariants: {
      variant: "success",
      size: "large"
    }
  }
);

const iconStyles = cva("", {
  variants: {
    variant: {
      success: "text-kiosk-background-reversed",
      idle: "text-white",
      transparent: "text-white"
    }
  },
  defaultVariants: {
    variant: "success"
  }
});

const overlayStyles = cva("absolute inset-y-0 left-0 h-full rounded-3xl", {
  variants: {
    variant: {
      success: "bg-kiosk-background-success-alt",
      idle: "bg-white/15",
      transparent: "bg-white/15"
    }
  }
});

type ModalButtonProps = VariantProps<typeof modalButtonStyles> &
  PropsWithChildren<{
    onClick: () => void;
    icon?: IconDefinition;
    className?: string;
    timeoutDuration?: number;
  }>;

/**
 * Properties for the ModalButton component.
 * @typedef {Object} ModalButtonProps
 * @property {() => void} onClick - Callback function to be invoked when the button is clicked.
 * @property {IconDefinition} [icon] - Optional FontAwesome icon to be displayed within the button.
 * @property {string} [className] - Optional additional class names to be applied to the button.
 * @property {number} [timeoutDuration] - Optional duration for the animation in milliseconds. The onClick function will be called when the animation ends.
 */
export const ModalButton = ({
  children,
  onClick,
  variant,
  timeoutDuration,
  icon,
  size,
  className
}: ModalButtonProps) => {
  const animationProps = useSpring(
    timeoutDuration
      ? {
          from: { width: "0%" },
          to: { width: "100%" },
          config: {
            duration: timeoutDuration,
            friction: 120,
            tension: 120
          },
          onRest: onClick
        }
      : {}
  );

  return (
    <button
      type="button"
      className={modalButtonStyles({ variant, size, className })}
      onClick={onClick}
    >
      {timeoutDuration ? (
        <animated.div
          className={overlayStyles({ variant })}
          style={{ ...animationProps }}
        />
      ) : null}
      <div className="z-10 flex items-center justify-center gap-x-4">
        {icon ? (
          <FontAwesomeIcon
            icon={icon}
            fontSize={36}
            className={iconStyles({ variant })}
          />
        ) : null}
        {children}
      </div>
    </button>
  );
};
