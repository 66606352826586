import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { faCircleCheck, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T, useT } from "@repo/transifex";
import { useCurrentTill } from "~/api/tills/hooks/use-till";
import { useOrderEmail } from "~/hooks/queries/orders";
import { useAppDispatch, useCountDownState } from "~/providers/app";
import {
  Button,
  Dialog,
  DialogError,
  DialogLoading,
  DialogSuccess
} from "~/components";
import { basketActions } from "~/providers/store/basket";
import { employeeActions } from "~/providers/store/employee";

const PosEmailReceiptPage = () => {
  const t = useT();
  const [email, setEmail] = useState("");

  const dispatch = useAppDispatch();
  const { count } = useCountDownState();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const { mutate: sendEmail, status: emailStatus, reset } = useOrderEmail();

  const { data: till } = useCurrentTill();
  const isAttended = till?.type === "attended";

  const handleClose = () => {
    basketActions.reset();
    employeeActions.reset();
    navigate("/pos");
  };

  useEffect(() => {
    if (count === 0) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- only on count change
  }, [count]);

  if (emailStatus === "pending")
    return (
      <DialogLoading
        backdropVariant="success"
        size="md"
        title={
          <span className="font-semibold">
            <T _str="Please wait" />
          </span>
        }
      >
        <T _str="The email is being sent..." />
      </DialogLoading>
    );

  if (emailStatus === "error" || !orderId)
    return (
      <DialogError
        backdropVariant="success"
        error={
          <span className="font-semibold">
            <T _str="Something went wrong" />
          </span>
        }
        hideSupport
        size="md"
      >
        <p className="text-center">
          <T _str="We couldn't sent a receipt to the email." />
        </p>

        <div className="flex w-full flex-col gap-y-5 pt-5">
          <Button
            className="w-full"
            onClick={() => {
              reset();
              dispatch({ type: "COUNTDOWN_RESET" });
            }}
            variant="primary"
          >
            <T _str="Try again" />
          </Button>
          <Button className="w-full" onClick={handleClose} variant="secondary">
            {isAttended ? (
              <T _str="Close" />
            ) : (
              <T _str="Close ({count})" count={count} />
            )}
          </Button>
        </div>
      </DialogError>
    );

  if (emailStatus === "success")
    return (
      <DialogSuccess backdropVariant="success" size="md">
        <p className="text-center text-xl font-semibold text-primary-main">
          <T _str="Email has been sent" />
        </p>

        <div className="w-full pt-5">
          <Button className="w-full" onClick={handleClose} variant="primary">
            {isAttended ? (
              <T _str="Close" />
            ) : (
              <T _str="Close ({count})" count={count} />
            )}
          </Button>
        </div>
      </DialogSuccess>
    );

  return (
    <Dialog backdropVariant="success" size="md">
      <div className="flex flex-col gap-y-5">
        <div className="flex flex-col gap-y-14 px-5 pt-8">
          <div className="flex items-center justify-center gap-x-5 text-primary-main">
            <span className="text-3xl font-semibold">
              <T _str="Payment completed" />
            </span>

            <FontAwesomeIcon className="h-11" icon={faCircleCheck} />
          </div>

          <div className="flex flex-col items-center justify-center gap-y-10">
            <p>
              <T _str="Send receipt on email" />
            </p>

            <div className="relative flex w-full flex-col rounded-md border border-gray-300 px-4 py-5 text-left">
              <input
                className="border-none text-base text-text-secondary outline-none"
                name="email"
                onChange={(e) => {
                  setEmail(e.target.value);
                  dispatch({ type: "COUNTDOWN_RESET" });
                }}
                placeholder={t("Type email")}
                type="text"
                value={email}
              />

              <FontAwesomeIcon
                className="absolute right-4 top-1/3"
                icon={faEnvelope}
              />
            </div>
          </div>
        </div>

        <div className="px-5 pb-5">
          <div className="flex gap-x-2">
            <Button
              className="w-2/6 text-lg"
              onClick={handleClose}
              size="large"
              variant="secondary"
            >
              <T _str="Close" />
            </Button>

            <Button
              className="w-2/3 text-lg"
              disabled={!email.length}
              onClick={() => {
                dispatch({ type: "COUNTDOWN_RESET" });
                sendEmail({ orderId, email });
              }}
              size="large"
              variant="primary"
            >
              <T _str="Send receipt" />
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export { PosEmailReceiptPage };
