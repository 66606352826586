import { useState } from "react";
import { clsx } from "clsx";

type VariantType = "primary";

type SizeType = "medium";

type SwitchProps = {
  variant?: VariantType;
  size?: SizeType;
  className?: string;
  checked?: boolean;
  handleChange: (value: boolean) => void;
};

export const Switch = ({
  variant = "primary",
  size = "medium",
  className = "",
  checked = false,
  handleChange
}: SwitchProps) => {
  const [enabled, setEnabled] = useState(checked);

  return (
    <label className="relative inline-flex cursor-pointer items-center">
      <input
        checked={enabled}
        className="peer sr-only"
        onChange={(event) => {
          const { checked } = event.target;

          handleChange(checked);
          setEnabled(checked);
        }}
        type="checkbox"
        value=""
      />
      <div
        className={clsx(
          "peer rounded-full peer-focus:outline-none",
          {
            "peer-checked:bg-primary-main": enabled && variant === "primary",
            "bg-secondary-main": !enabled,
            "h-8 w-16": size === "medium"
          },
          {
            // CALCULATION: w-16 - w-6 - translate-x-1 = translate-x-9
            "peer-checked:after:translate-x-9": enabled && size === "medium",
            "after:translate-x-1": !enabled,
            "after:bg-background-primary": variant === "primary"
          },
          "after:absolute after:top-2/4 after:size-6 after:-translate-y-2/4 after:rounded-full after:shadow-sm after:transition-all after:content-['']",
          className
        )}
      />
    </label>
  );
};
