import { useNavigate } from "react-router-dom";

import { useCurrentTill } from "~/api/tills/hooks/use-till";
import { useAuth } from "~/providers/store/auth";
import { DialogError, DialogLoading } from "~/components";
import { useT } from "@repo/transifex";
import { useProfile } from "~/hooks/queries/profiles";

import { TicketValidationMenu } from "./TicketValidationMenu";
import { DefaultPosMenu } from "./DefaultPosMenu";

export const MenuPage = () => {
  const auth = useAuth();
  const { data: till } = useCurrentTill();
  const { status: profileStatus } = useProfile(auth.profileId);

  const t = useT();
  const navigate = useNavigate();

  if (auth.status === "none" || profileStatus === "pending") {
    return <DialogLoading />;
  }

  if (profileStatus === "error") {
    return (
      <DialogError
        error={t("Profile not found")}
        onOutsideClick={() => {
          navigate("/profiles");
        }}
      />
    );
  }

  if ((till?.type as string) === "ticket-validation") {
    return <TicketValidationMenu />;
  }

  return <DefaultPosMenu />;
};
