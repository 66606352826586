import {
  faArrowDownToLine,
  faWeightScale,
  faWarning
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import { useT, T } from "@repo/transifex";
import { priceFormatter } from "@repo/system";
import { Button, Dialog } from "~/components";
import { useAppContext, useBasketProducts } from "~/providers/app";
import { getUnitOfMeasure } from "~/utils/getUnitOfMeasure";
import { basketActions } from "~/providers/store/basket";
import { useSendWebEvent } from "~/hooks";
import { calculateUnitPriceByUnitSystem } from "~/utils/calculateUnitPriceByUnitSystem.ts";
import { useAuth } from "~/providers/store/auth";

export const WeightPage = () => {
  const t = useT();
  const { productId } = useParams();
  const navigate = useNavigate();
  const sendWebEvent = useSendWebEvent();
  const { locale } = useAuth();

  const {
    hardware: {
      scales: { scale, weight: scaleInfo }
    }
  } = useAppContext();

  const { products, meta } = useBasketProducts();
  const { currency } = meta;

  const product = products.find((p) => p.id === productId);

  if (!product) return <Navigate replace to="/pos" />;

  const currentWeight = (scaleInfo?.weight ?? 0) - product.containerWeight;

  const unitOfMeasure = getUnitOfMeasure(product.unitSystem);
  const unitPrice = calculateUnitPriceByUnitSystem({
    unitPrice: product.unitPrice,
    weight: currentWeight,
    unitSystem: product.unitSystem
  });

  const onClose = () => {
    navigate("..");
    sendWebEvent({ type: "SCALE_DISCONNECT_REQUEST" });
  };

  if (!scale) {
    return (
      <Dialog onOutsideClick={onClose} open size="lg">
        <div>
          <div className="flex w-full flex-col gap-6 p-10 text-center">
            <FontAwesomeIcon
              className="h-16 text-danger-main"
              icon={faWarning}
            />
            <div>
              <p className="text-5xl font-bold text-danger-main">
                <T _str="No scale connected" />
              </p>
              <p className="font-bold text-text-secondary">
                <T _str="This product requires a scale to be connected to be ordered" />
              </p>
            </div>
          </div>
          <div className="flex flex-col bg-background-secondary p-8">
            <div className="flex gap-6">
              <Button
                className="w-full flex-1"
                onClick={onClose}
                size="xl"
                variant="danger"
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }

  return (
    <Dialog onOutsideClick={onClose} open size="lg">
      <div>
        <div className="flex w-full flex-col gap-6 p-10 text-center">
          <h3 className="text-3xl font-bold">{product.name}</h3>
          <FontAwesomeIcon
            className="h-16 text-primary-main"
            icon={
              scaleInfo?.status === "stable" && currentWeight > 0
                ? faWeightScale
                : faArrowDownToLine
            }
          />
          <p className="text-5xl font-bold text-primary-main">
            {scaleInfo?.status === "stable" && currentWeight > 0
              ? `${currentWeight} ${t(unitOfMeasure)}`
              : t("Place item on scale")}
          </p>
        </div>
        <div className="flex flex-col bg-background-secondary p-8">
          {scaleInfo?.status === "stable" && currentWeight > 0 ? (
            <div className="mb-6 flex items-center">
              <p className="flex-1 text-lg">{t("Price")}</p>
              <p className="text-4xl font-semibold">
                {priceFormatter({
                  value: unitPrice,
                  currency,
                  locale
                })}
              </p>
            </div>
          ) : null}
          <div className="flex gap-6">
            <Button
              className="w-full flex-1"
              onClick={onClose}
              size="xl"
              variant="danger"
            >
              {t("Cancel")}
            </Button>
            {scaleInfo?.status === "stable" && currentWeight > 0 ? (
              <Button
                className="flex-[2]"
                onClick={() => {
                  basketActions.addVariantWeight(
                    product.id,
                    [
                      {
                        name: "scale_weight",
                        choices: [
                          {
                            amount: 1,
                            weight: currentWeight,
                            id: uuidv4(),
                            name: product.unitSystem
                          }
                        ]
                      }
                    ],
                    product.unitSystem,
                    !product.discount?.isAllowanceOver
                  );
                  onClose();
                }}
                size="xl"
                variant="primary"
              >
                {t("Add to basket")}
              </Button>
            ) : null}
          </div>
        </div>
      </div>
    </Dialog>
  );
};
