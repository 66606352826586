import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef, type PropsWithChildren } from "react";

type BottomActionsProps = {
  primaryButton: React.ReactNode;
  secondaryButton: React.ReactNode;
};

export const BottomActions = ({
  primaryButton,
  secondaryButton
}: BottomActionsProps) => {
  return (
    <div className="flex flex-1 gap-5 rounded-t-3xl bg-kiosk-background-reversed p-5">
      <div className="flex w-1/3">{secondaryButton}</div>
      <div className="flex w-2/3">{primaryButton}</div>
    </div>
  );
};

type PayButtonProps = PropsWithChildren<{
  onClick: () => void;
  totalAmount: string;
}>;

export const PayButton = ({
  onClick,
  totalAmount,
  children
}: PayButtonProps) => (
  <button
    type="button"
    className="flex w-full items-center justify-between rounded-3xl bg-kiosk-accent-primary px-10 py-9"
    onClick={onClick}
  >
    <span className="text-4xl font-extrabold">{children}</span>
    <span className="text-5xl font-extrabold">{totalAmount}</span>
  </button>
);

export const SecondaryButton = forwardRef<
  HTMLButtonElement,
  {
    onClick?: () => void;
    icon: IconProp;
    children: React.ReactNode;
  }
>(({ onClick, icon, children }, ref) => (
  <button
    ref={ref}
    type="button"
    className="flex w-full items-center justify-between gap-3 rounded-3xl bg-kiosk-border-secondary/20 px-14"
    onClick={onClick}
  >
    <FontAwesomeIcon icon={icon} color="white" fontSize={48} />
    <span className="text-3xl font-semibold text-white">{children}</span>
  </button>
));

SecondaryButton.displayName = "SecondaryButton";

export const NavigationButton = ({
  onClick,
  totalAmount,
  numberOfProducts,
  navigationText
}: {
  onClick: () => void;
  totalAmount: string;
  numberOfProducts: string;
  navigationText: string;
}) => (
  <button
    type="button"
    className="flex w-full items-center justify-between rounded-3xl bg-kiosk-accent-primary px-10 py-9 text-kiosk-text-primary"
    onClick={onClick}
  >
    <div className="flex flex-col text-left">
      <span className="text-4xl font-extrabold">{totalAmount}</span>
      <span className="text-3xl font-normal">{numberOfProducts}</span>
    </div>
    <div />
    <div className="flex gap-5">
      <span className="text-4xl font-extrabold">{navigationText}</span>
      <FontAwesomeIcon icon={faArrowRight} fontSize={48} />
    </div>
  </button>
);
