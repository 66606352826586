import { useMutation } from "@tanstack/react-query";

import { getAuth } from "~/providers/store/auth/getters";
import { useCurrentTill } from "~/api/tills/hooks/use-till";

import { createOrderSimple } from "../requests";

export function useCreateOrderSimple() {
  const { data: till } = useCurrentTill();
  const sessionId = till?.sessionId;

  return useMutation({
    mutationFn: async (parsedCode: string) => {
      const { moduleId, schoolId, profileId, status, partnerId } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      if (!till) {
        throw new Error("Till is not connected");
      }

      if (!sessionId) {
        throw new Error("No session ID on the current till");
      }

      return createOrderSimple({
        tillId: till.id,
        cardHex: parsedCode,
        moduleId,
        schoolId,
        profileId,
        partnerId,
        sessionId
      });
    }
  });
}
