import { type PropsWithChildren } from "react";

import { UnattendedLogoutButton } from "../UnattendedLogoutButton";

export const LayoutLock = ({ children }: PropsWithChildren) => {
  return (
    <div className="flex h-full flex-col bg-white p-5">
      <div className="flex w-full flex-1  flex-col items-center justify-center space-y-8">
        {children}
      </div>

      <div className="flex w-full justify-start">
        <UnattendedLogoutButton />
      </div>
    </div>
  );
};
