import { faUserCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type UserInfoProps = {
  userName: string;
  balance: string;
};

export const UserInfo = ({ userName, balance }: UserInfoProps) => {
  return (
    <div className="flex items-center justify-center gap-3 rounded-full bg-kiosk-background-secondary px-6 py-4 text-4xl font-bold">
      <FontAwesomeIcon icon={faUserCircle} />
      <p>{userName}</p>
      <p>{balance}</p>
    </div>
  );
};
