import { useSearchParams } from "react-router-dom";
import { useState } from "react";

import { useT } from "@repo/transifex";
import { useOrder } from "~/hooks/queries/orders";
import { useBasketProducts } from "~/providers/app";
import { Dialog, DialogLoading, DialogPayment, NumPad } from "~/components";
import { useEmployee } from "~/providers/store/employee/hooks";

import { CalculatorButtons } from "./CalculatorButtons";

const PosCashPage = () => {
  const t = useT();
  const { productsInBasket } = useBasketProducts();
  const { saldo } = useEmployee();

  const [searchParams] = useSearchParams();
  const orderId = searchParams.get("orderId");

  const { data: order, status } = useOrder(orderId);

  const [received, setReceived] = useState(0);

  const totalPriceFromBasket = productsInBasket.reduce(
    (acc, product) => acc + product.totalPrice,
    0
  );

  let totalPrice = (totalPriceFromBasket - (saldo ?? 0)) / 100;

  if (order) {
    totalPrice = (order.totalPriceUnit - (order.paidWithSaldoUnit ?? 0)) / 100;
  }

  const change = (received || (order?.totalPriceUnit || 0) / 100) - totalPrice;
  const formattedChange = Math.round(change * 100) / 100;

  if (order?.status === "paid") {
    const cashChange = received - order.totalPriceUnit / 100;
    return (
      <DialogPayment change={cashChange} orderId={order.id} status="success" />
    );
  }

  if (order?.status === "failed") return <DialogPayment status="error" />;

  // In the reimbursement flow, skip the calculator display
  if (totalPrice <= 0) return <DialogLoading />;

  return (
    <Dialog size="md">
      <div className="flex w-full">
        <NumPad
          label={received > 0 ? t("Received") : t("To pay")}
          placeholder={totalPrice}
          setValue={setReceived}
          value={received}
        >
          <CalculatorButtons
            change={formattedChange}
            loadingOrder={status === "pending"}
            orderId={orderId}
            received={received}
          />
        </NumPad>
      </div>
    </Dialog>
  );
};

export { PosCashPage };
