import { clsx } from "clsx";

export const Badge = ({
  text,
  className
}: {
  text: string;
  className?: string;
}) => (
  <div
    className={clsx(
      "z-10 flex min-h-6 items-center rounded-xl bg-black px-2 py-0.5 text-center text-sm text-white",
      className
    )}
  >
    {text}
  </div>
);
