import { faExclamationTriangle } from "@fortawesome/pro-light-svg-icons";

import { T } from "@repo/transifex";

import { Modal } from "../shared/modal";
import { ModalIcon } from "../shared/modal-icon";
import { ModalTitle } from "../shared/modal-title";
import { ModalButton } from "../shared/modal-button";
import { ModalButtonGroup } from "../shared/modal-button-group";

type ModalErrorGenericProps = {
  onClose: () => void;
};

export const ModalErrorGeneric = ({ onClose }: ModalErrorGenericProps) => {
  return (
    <Modal backdropVariant="error">
      <div className="flex flex-col gap-16 px-32 text-center">
        <ModalIcon icon={faExclamationTriangle} />

        <ModalTitle size="9xl" variant="error" weight="black">
          <T _str="Something went wrong" />
        </ModalTitle>

        <p className="text-6xl text-white">
          <T _str="Please contact staff" />
        </p>

        <ModalButtonGroup className="mt-16">
          <ModalButton variant="idle" onClick={onClose}>
            <T _str="Close" />
          </ModalButton>
        </ModalButtonGroup>
      </div>
    </Modal>
  );
};
