import { useNavigate } from "react-router-dom";

import { ItemLayout } from "~/components/ItemLayout";
import { basketActions } from "~/providers/store/basket";

type DiningOptionItemProps = { value: string; inputId: string };

export const DiningOptionItem = ({ value, inputId }: DiningOptionItemProps) => {
  const navigate = useNavigate();

  return (
    <ItemLayout
      className="flex size-full flex-1 flex-col items-center justify-center bg-background-secondary p-4 text-left text-2xl text-text-primary"
      onClick={() => {
        basketActions.setDiningOption({
          inputId,
          value
        });
        navigate("/pos");
      }}
    >
      <span className="line-clamp-2">{value}</span>
    </ItemLayout>
  );
};
