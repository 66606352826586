import { INITIAL_STATE, employeeStore } from "./store";

import type { EmployeeState } from ".";

/** Sets the selected group name */
function setGroupName(groupName: EmployeeState["groupName"]) {
  employeeStore.setState((state) => ({ ...state, groupName }));
}

/** Sets current authenticated employee. Either via card or via qr code */
function setAuthEmployee(employee: EmployeeState) {
  employeeStore.setState(employee);
}

/** Resets the employee */
function reset() {
  employeeStore.setState(INITIAL_STATE);
}

const employeeActions = {
  setGroupName,
  setAuthEmployee,
  reset
};

export { employeeActions };
