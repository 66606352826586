import {
  type Variant,
  type Allowance,
  type Discount,
  type Product
} from "~/api/products/types";
import { getHybridBillingProductDiscount } from "~/utils/getHybridBillingProductDiscount";
import { calculateUnitGrossPrice } from "~/utils/calculate-unit-gross-price";

import {
  type KioskDisplayVariant,
  type KioskDisplayProductList
} from "./types";

type ParseDisplayProductListArgs = {
  product: Product;
  discounts: Discount[]; // only hybrid billing discounts are applied
  allowances: Allowance[];
  variants: Variant[];
};

export function parseDisplayProductList({
  product,
  discounts,
  allowances,
  variants
}: ParseDisplayProductListArgs): KioskDisplayProductList {
  const hybridBillingDiscount = getHybridBillingProductDiscount({
    product: { discount: product.discount, unitPrice: product.unitPrice },
    discounts,
    allowances
  });

  const productDiscounts: KioskDisplayProductList["discounts"] = [];

  let unitGrossPrice = product.unitPrice;
  if (hybridBillingDiscount) {
    productDiscounts.push({
      id: hybridBillingDiscount.id,
      type: "hybrid-billing"
    });

    if (
      hybridBillingDiscount.type === "discount-hybridBilling" &&
      hybridBillingDiscount.allowanceType !== "unitPrice" &&
      !hybridBillingDiscount.isAllowanceOver
    ) {
      unitGrossPrice = product.unitPrice - hybridBillingDiscount.amount;
    }
  }

  const partialBaseProduct: Omit<KioskDisplayProductList["product"], "type"> = {
    name: product.name,
    description: product.description,
    id: product.id,
    tags: product.tags ?? [],
    allergens: product.allergens ?? [],
    imageUrl: product.imageUrl,
    category: product.category
  };

  let baseProduct: KioskDisplayProductList["product"] | null = null;

  if (product.options && product.options.length > 0) {
    const productVariants = variants.filter((variant) =>
      product.options.includes(variant.id)
    );

    baseProduct = {
      ...partialBaseProduct,
      type: "variant",
      variants: productVariants.map((variant) =>
        parseDisplayVariant({ variant, product })
      )
    };
  } else {
    baseProduct = {
      ...partialBaseProduct,
      type: "regular"
    };
  }

  if (!baseProduct) {
    throw new Error("Base product not found");
  }

  return {
    product: baseProduct,
    discounts: productDiscounts,
    unitGrossPrice,
    unitListPrice:
      unitGrossPrice !== product.unitPrice ? product.unitPrice : null
  };
}

type ParseDisplayVariantArgs = {
  variant: Variant;
  product: Product;
};

function parseDisplayVariant({
  variant,
  product
}: ParseDisplayVariantArgs): KioskDisplayVariant {
  return {
    id: variant.id,
    name: variant.name,
    minimum: variant.minimum,
    choices: variant.choices.map((choice) => {
      let unitGrossPrice: number | null = null;
      if (choice.unitPrice) {
        unitGrossPrice = calculateUnitGrossPrice(
          choice.unitPrice,
          product.vatRate
        );
      }

      return {
        id: choice.id,
        name: choice.name,
        unitGrossPrice,
        maximum: choice.max ?? null
      };
    })
  };
}
