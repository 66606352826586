import { useNavigate } from "react-router-dom";
import { clsx } from "clsx";

import { ReactPortal } from "./ReactPortal";

type DialogSize = "sm" | "md" | "lg";

export type DialogProps = {
  open?: boolean;
  size: DialogSize;
  children: React.ReactNode;
  className?: string;
  backUrl?: string;
  backdropVariant?: "transparent" | "error" | "success";
  onOutsideClick?: () => void;
};

export const Dialog = ({
  open = true,
  size,
  children,
  className,
  backUrl,
  backdropVariant = "transparent",
  onOutsideClick
}: DialogProps) => {
  const navigate = useNavigate();

  const handleOutsideClick = (e: React.MouseEvent) => {
    if (e.target !== e.currentTarget) return;
    onOutsideClick?.();
    backUrl && navigate(backUrl);
  };

  return (
    <ReactPortal wrapperId="dialog-portal">
      <div
        className={clsx(
          "absolute right-0 top-0 z-20 flex size-full items-center justify-center",
          {
            hidden: !open
          },
          {
            "bg-primary-main": backdropVariant === "success",
            "bg-danger-light": backdropVariant === "error",
            "bg-black/60": backdropVariant === "transparent"
          }
        )}
        onClick={handleOutsideClick}
      >
        <dialog
          className={clsx(
            "relative m-auto max-h-full overflow-auto rounded-lg bg-background-primary",
            size === "sm" && "w-1/5",
            size === "md" && "w-2/5",
            size === "lg" && "w-4/5",
            className
          )}
          open={open}
        >
          {children}
        </dialog>
      </div>
    </ReactPortal>
  );
};
