import { useSpring, animated } from "@react-spring/web";
import { useDrag } from "@use-gesture/react";

import { T } from "@repo/transifex";

const MAX_SWIPE_LEFT = -192;
const MAX_SWIPE_RIGHT = 0;

type SwipeToDeleteProps = {
  children: React.ReactNode;
  onDelete: () => void;
};

export const SwipeToDelete = ({ children, onDelete }: SwipeToDeleteProps) => {
  const [{ x }, api] = useSpring(() => ({
    x: 0,
    config: { tension: 300, friction: 30 }
  }));

  const bind = useDrag(
    ({ down, movement: [mx] }) => {
      // Limits left-hand movement to 192px, the equivalent of class w-48 i.e. the width of the delete button,
      // and right-hand movement to 0px
      const clampedX = Math.min(MAX_SWIPE_RIGHT, Math.max(MAX_SWIPE_LEFT, mx));

      // Automatic swipe completion threshold, set at 120px
      const autoComplete = clampedX <= -120;

      if (down) return api.start({ x: clampedX });

      return api.start({
        x: autoComplete ? MAX_SWIPE_LEFT : 0
      });
    },
    { filterTaps: true, axis: "x" }
  );

  return (
    <div className="relative">
      <button
        className="absolute right-0 top-0 flex size-full w-48 items-center justify-center bg-danger-main text-danger-contrast"
        onClick={onDelete}
        type="button"
      >
        <T _str="Delete" />
      </button>

      <animated.div
        {...bind()}
        className="relative"
        style={{ x, touchAction: "pan-y" }}
      >
        {children}
      </animated.div>
    </div>
  );
};
