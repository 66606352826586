type TillsKeysListArgs = {
  partnerId: string;
  filters?: {
    assigned?: boolean;
  };
};

export const tillsKeys = {
  all: ["tills"] as const,
  lists: () => [...tillsKeys.all, "list"] as const,
  list: ({ filters, partnerId }: TillsKeysListArgs) =>
    [...tillsKeys.lists(), partnerId, filters] as const,
  details: () => [...tillsKeys.all, "detail"] as const,
  detail: (id: string) => [...tillsKeys.details(), id] as const
};
