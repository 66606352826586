import {
  faLoader,
  faWarning,
  faXmarkCircle
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T } from "@repo/transifex";
import { getSupportInfoFromCountryCode } from "@repo/system";
import { useAuth } from "~/providers/store/auth";

const icon = {
  pending: faLoader,
  empty: faWarning,
  error: faXmarkCircle
};

export const ReportStatus = ({
  status
}: {
  status: "pending" | "empty" | "error";
}) => {
  const { country } = useAuth();
  const { email, phone } = getSupportInfoFromCountryCode(country);

  return (
    <div className="flex size-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-2.5 text-text-disabled">
        <FontAwesomeIcon icon={icon[status]} size="lg" />
        <p className="text-2xl font-medium">
          {status === "pending" && <T _str="Loading data" />}

          {status === "empty" && <T _str="No data" />}

          {status === "error" && (
            <div className="flex flex-col items-center gap-y-5 text-center">
              <T _str="Something went wrong" />
              <p className="text-xs">
                <T _str="Please try again or contact Kanpla Support" />
              </p>

              <div className="flex flex-col gap-y-2.5">
                <a className="text-xs text-primary-dark" href={`tel:${phone}`}>
                  {phone}
                </a>
                <a
                  className="text-xs text-primary-dark"
                  href={`mailto:${email}`}
                >
                  {email}
                </a>
              </div>
            </div>
          )}
        </p>
      </div>
    </div>
  );
};
