import { NavLink } from "react-router-dom";

import { useT } from "@repo/transifex";
import { useTills } from "~/api/tills/hooks/use-tills";
import { Button } from "~/components";

export const TillSelector = () => {
  const t = useT();

  const { data: tills, status } = useTills();

  if (status !== "success" || !tills) return null;

  return (
    <div className="flex w-2/3 gap-1 overflow-auto scrollbar-hide">
      {tills.length > 1 ? (
        <NavLink key="combined" to="combined">
          {({ isActive }) => (
            <Button
              className="w-full !rounded-full"
              variant={isActive ? "primary" : "secondary"}
            >
              {t("All tills")}
            </Button>
          )}
        </NavLink>
      ) : null}

      {tills.map((till) => {
        return (
          <NavLink key={till.id} to={till.id}>
            {({ isActive }) => (
              <Button
                className="w-full !rounded-full"
                variant={isActive ? "primary" : "secondary"}
              >
                {till.name}
              </Button>
            )}
          </NavLink>
        );
      })}
    </div>
  );
};
