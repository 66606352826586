import type { AppAction, AppState } from "./types";

const DEFAULT_COUNTDOWN_SECONDS = 30;

export const initialState: AppState = {
  basket: {
    products: {}
  },
  hardware: {
    cashDrawer: {
      status: "closed"
    },
    discovery: {
      printers: [],
      status: "idle"
    },
    scales: {
      discovery: {
        devices: [],
        message: null,
        status: "idle"
      },
      weight: {
        status: "unstable",
        weight: 0
      },
      scale: null,
      error: null
    },
    printer: null,
    printerError: null,
    printing: {
      status: "idle",
      message: null
    },
    barcode: null
  },
  device: {
    deviceId: null,
    tillId: null,
    os: null,
    osVersion: null
  },
  countdown: {
    enabled: false,
    count: DEFAULT_COUNTDOWN_SECONDS
  }
};

export function reducer(state: AppState, action: AppAction): AppState {
  switch (action.type) {
    case "CASH_DRAWER_STATUS_INIT":
    case "CASH_DRAWER_OPEN_INIT": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          cashDrawer: {
            status: "loading"
          }
        }
      };
    }

    case "CASH_DRAWER_STATUS_SUCCESS": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          cashDrawer: {
            status: action.payload ? "open" : "closed"
          }
        }
      };
    }

    case "CASH_DRAWER_OPEN_SUCCESS": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          cashDrawer: {
            status: "open"
          }
        }
      };
    }

    case "CASH_DRAWER_STATUS_ERROR":
    case "CASH_DRAWER_OPEN_ERROR": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          cashDrawer: {
            status: "closed"
          },
          printerError: action.payload
        }
      };
    }

    case "PRINTER_DISCOVER_INIT": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          discovery: {
            ...state.hardware.discovery,
            status: "loading"
          }
        }
      };
    }

    case "PRINTER_DISCOVER_SUCCESS": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          discovery: {
            printers: action.payload,
            status: "success"
          }
        }
      };
    }

    case "PRINTER_DISCOVER_ERROR": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          discovery: {
            ...state.hardware.discovery,
            status: "error"
          }
        }
      };
    }

    case "PRINTER_DISCOVER_RESET": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          discovery: {
            ...state.hardware.discovery,
            printers: [],
            status: "idle"
          }
        }
      };
    }

    case "SCALES_DISCOVER_RESET": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          scales: {
            ...state.hardware.scales,
            discovery: {
              devices: [],
              message: null,
              status: "idle"
            }
          }
        }
      };
    }

    case "PRINTER_SELECT_SUCCESS": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          printer: action.payload
        }
      };
    }

    case "PRINTER_DISCONNECT_SUCCESS":
    case "PRINTER_SELECT_ERROR": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          printer: null
        }
      };
    }

    case "DEVICE_DATA_GET_SUCCESS": {
      const { deviceId, tillId, os, osVersion, printer, scale } =
        action.payload;

      return {
        ...state,
        device: {
          ...state.device,
          deviceId,
          tillId,
          os: os ?? "unknown",
          osVersion: osVersion ?? "unknown"
        },
        hardware: {
          ...state.hardware,
          printer,
          scales: {
            ...state.hardware.scales,
            error: null,
            scale
          }
        }
      };
    }

    case "PRINTER_ERROR": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          printerError: action.payload
        }
      };
    }

    case "PRINTER_COMMUNICATION_ERROR": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          printer: null,
          printerError: action.payload
        }
      };
    }

    case "CLEAR_PRINTER_ERROR": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          printerError: null
        }
      };
    }

    case "DEVICE_ASSIGN_TILL": {
      return {
        ...state,
        device: {
          ...state.device,
          tillId: action.payload
        }
      };
    }

    case "COUNTDOWN_START": {
      return {
        ...state,
        countdown: {
          count: action.payload || DEFAULT_COUNTDOWN_SECONDS,
          enabled: true
        }
      };
    }

    case "COUNTDOWN_STOP": {
      return {
        ...state,
        countdown: {
          ...state.countdown,
          enabled: false
        }
      };
    }

    case "COUNTDOWN_TICK": {
      const newCount = state.countdown.count - 1;
      if (newCount < 0) {
        return {
          ...state,
          countdown: {
            ...state.countdown,
            enabled: false
          }
        };
      }

      return {
        ...state,
        countdown: {
          ...state.countdown,
          count: state.countdown.count - 1
        }
      };
    }

    case "COUNTDOWN_RESET": {
      return {
        ...state,
        countdown: {
          ...state.countdown,
          count: action.payload || DEFAULT_COUNTDOWN_SECONDS
        }
      };
    }

    case "PRINTER_PRINT_RECEIPT_INIT": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          printing: {
            status: "loading",
            message: null
          }
        }
      };
    }

    case "PRINTER_PRINT_RECEIPT_SUCCESS": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          printing: {
            status: "success",
            message: null
          }
        }
      };
    }

    case "PRINTER_PRINT_RECEIPT_ERROR": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          printing: {
            status: "error",
            message: action.payload
          },
          printerError: action.payload
        }
      };
    }

    case "PRINTER_PRINT_RECEIPT_RESET": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          printing: {
            status: "idle",
            message: null
          }
        }
      };
    }

    case "BARCODE_SCANNED": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          barcode: action.payload
        }
      };
    }

    case "CLEAR_BARCODE_SCANNED": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          barcode: null
        }
      };
    }

    case "SCALE_DISCOVER_ERROR": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          scales: {
            ...state.hardware.scales,
            discovery: {
              devices: [],
              message: action.payload,
              status: "error"
            }
          }
        }
      };
    }

    case "SCALE_DISCOVER_SUCCESS": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          scales: {
            ...state.hardware.scales,
            discovery: {
              devices: action.payload,
              message: null,
              status: "success"
            }
          }
        }
      };
    }

    case "SCALE_SELECT_SUCCESS": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          scales: {
            ...state.hardware.scales,
            scale: action.payload,
            error: null
          }
        }
      };
    }

    case "SCALE_REMOVE_SUCCESS": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          scales: {
            ...state.hardware.scales,
            scale: null,
            error: null
          }
        }
      };
    }

    case "SCALE_SELECT_ERROR": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          scales: {
            ...state.hardware.scales,
            scale: null,
            error: action.payload
          }
        }
      };
    }

    case "SCALE_WEIGHT_UPDATE": {
      return {
        ...state,
        hardware: {
          ...state.hardware,
          scales: {
            ...state.hardware.scales,
            weight: action.payload
          }
        }
      };
    }

    default: {
      return state;
    }
  }
}
