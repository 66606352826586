import {
  createStore,
  type StateCreator,
  type StoreMutatorIdentifier
} from "zustand";
import { devtools, persist } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import type { KioskSessionStore } from "./types";

const INITIAL_STATE: KioskSessionStore = null;

type TimeLogger = <
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = []
>(
  f: StateCreator<T, Mps, Mcs>,
  name?: string
) => StateCreator<T, Mps, Mcs>;

type LoggerImpl = <T>(f: StateCreator<T>, name?: string) => StateCreator<T>;

const loggerImpl: LoggerImpl = (f) => (set, get, store) => {
  const loggedSet: typeof set = (...a) => {
    set((state) => {
      if (state) {
        return {
          ...state,
          lastUpdatedAt: Date.now()
        };
      }
      return state;
    });
    set(...a);
  };

  const setState = store.setState;
  store.setState = (...a) => {
    if (store.getState()) {
      setState((state) => ({
        ...state,
        lastUpdatedAt: Date.now()
      }));
    }
    setState(...a);
  };

  return f(loggedSet, get, store);
};

export const timeLogger = loggerImpl as unknown as TimeLogger;

export const kioskSessionStore = createStore<KioskSessionStore>()(
  immer(
    devtools(
      timeLogger(persist((_) => INITIAL_STATE, { name: "kiosk-session-store" }))
    )
  )
);
