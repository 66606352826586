import { Link, useParams } from "react-router-dom";
import { faUser } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment-timezone";

import { T, useT } from "@repo/transifex";
import { type TillSession, useSession } from "~/hooks/queries/sessions";
import { useTill } from "~/api/tills/hooks/use-till";
import { type Till } from "~/api/tills/types";
import { TillCashReport } from "~/routes/__app.settings.tills.$tillId_.$sessionId/TillCashReport";
import {
  Button,
  DialogLoading,
  HeaderSeparator,
  ReportLayout,
  SettingsLayout,
  TillStatusButton
} from "~/components";

type LayoutProps = {
  children: React.ReactNode;
  subtitle?: string;
  status?: "open" | "closed";
  tillId?: Till["id"];
  startedAt?: TillSession["startedAt"];
  endedAt?: TillSession["endedAt"];
};

const Layout = ({
  children,
  subtitle,
  status,
  tillId,
  startedAt,
  endedAt
}: LayoutProps) => {
  const t = useT();

  return (
    <SettingsLayout
      backUrl={`/settings/tills/${tillId}`}
      headerChildren={
        <>
          {status ? <TillStatusButton status={status} /> : null}
          <HeaderSeparator />
          <p className="text-xs">
            {startedAt
              ? moment.unix(startedAt).format("D MMMM YY h:mm a")
              : null}
            {endedAt ? moment.unix(endedAt).format("-D MMMM YY h:mm a") : null}
          </p>
        </>
      }
      subtitle={subtitle}
      title={t("Register Management")}
    >
      {children}
    </SettingsLayout>
  );
};

const TillSessionPage = () => {
  const { sessionId, tillId } = useParams();

  const { data: session, status } = useSession(sessionId, {
    expand: "profiles"
  });
  const { data: till, status: tillStatus } = useTill(tillId);

  if (status === "pending" || tillStatus === "pending")
    return (
      <Layout>
        <DialogLoading />
      </Layout>
    );
  if (status === "error" || tillStatus === "error")
    return (
      <Layout>
        <div className="flex w-full flex-col items-center justify-center gap-2">
          <h2 className="text-2xl text-danger-dark">
            <T _str="No sessions found" />
          </h2>
          <Link to="..">
            <Button>
              <T _str="Back to tills" />
            </Button>
          </Link>
        </div>
      </Layout>
    );

  const {
    startedAt,
    endedAt,
    startedProfile,
    endedProfile,
    startedMeta,
    endedMeta,
    endedFlow,
    startedFlow
  } = session;

  return (
    <Layout
      endedAt={endedAt}
      startedAt={startedAt}
      status={sessionId ? "open" : "closed"}
      subtitle={till?.name}
      tillId={tillId}
    >
      <div className="flex flex-col gap-4">
        <ReportLayout title="Overview">
          <div>
            <div className="flex flex-col gap-3">
              <div className="flex flex-wrap gap-x-10 gap-y-5">
                <div className="flex items-center gap-5">
                  <p className="text-text-secondary">
                    <T _str="Opened" />
                  </p>
                  <p>{formatDate(startedAt)}</p>
                </div>
                <div className="flex items-center font-medium text-text-secondary">
                  <FontAwesomeIcon className="mr-2" icon={faUser} />
                  <p>{startedProfile?.name}</p>
                </div>
              </div>

              {endedAt ? (
                <div className="flex flex-wrap gap-x-10 gap-y-5">
                  <div className="flex items-center gap-5">
                    <p className="text-text-secondary">
                      <T _str="Closed" />
                    </p>
                    <p>{formatDate(endedAt)}</p>
                  </div>
                  <div className="flex items-center font-medium text-text-secondary">
                    <FontAwesomeIcon className="mr-2" icon={faUser} />
                    <p>{endedProfile?.name}</p>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </ReportLayout>

        {startedFlow === "manual" && endedFlow === "manual" && (
          <TillCashReport endedMeta={endedMeta} startedMeta={startedMeta} />
        )}

        <Link to="transactions">
          <Button>
            <T _str="Transactions" />
          </Button>
        </Link>
      </div>
    </Layout>
  );
};

const formatDate = (dateSeconds: number) =>
  moment.unix(dateSeconds).format("LLL");

export { TillSessionPage };
