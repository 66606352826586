import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import moment from "moment-timezone";

import { useCurrentTill } from "~/api/tills/hooks/use-till";
import { useSessions } from "~/hooks/queries/sessions";
import { useDayFilter } from "~/hooks";
import { DialogError, ReportStatus, Time } from "~/components";

import { ReportsWrapper, WrapperGradient } from "./ReportWrapper";
import { ReportSessionItem } from "./ReportSessionItem";
import { SendReportButton } from "./SendReport";
import { PrintReportButton } from "./PrintReport";
import { SendReportCombinedButton } from "./SendReportCombined";

const SessionTillsPage = () => {
  const navigate = useNavigate();
  const { tillId: tillIdFromParams, sessionId: sessionIdFromParams } =
    useParams();

  const { data: till, status: tillStatus } = useCurrentTill();
  const { label, start, end, handleNext, handlePrev } = useDayFilter(
    till?.timezone
  );

  const { data: sessions, status: sessionStatus } = useSessions({
    tillId:
      !tillIdFromParams || tillIdFromParams === "combined"
        ? null
        : tillIdFromParams,
    start,
    end
  });

  const isCombined = tillIdFromParams === "combined";

  useEffect(() => {
    if (sessionIdFromParams) return;

    if (tillIdFromParams === "combined") {
      navigate(`/settings/reports/combined/${start}`);
    }

    if (sessionStatus === "success" && sessions.length) {
      //If the sessions are loaded
      const sessionId = sessions[0].id;

      // If there is no sessionId in the params
      // It means it's the first landing on the page
      if (!sessionIdFromParams)
        navigate(`/settings/reports/${tillIdFromParams}/${sessionId}`);
    }
  }, [
    navigate,
    tillIdFromParams,
    sessionIdFromParams,
    sessionStatus,
    sessions,
    start
  ]);

  if (sessionStatus === "error" || tillStatus === "error") {
    return <DialogError backUrl=".." />;
  }

  if (tillStatus === "pending") {
    return <ReportStatus status="pending" />;
  }

  return (
    <div className="flex size-full gap-5 overflow-hidden scrollbar-hide">
      <ReportsWrapper>
        <div className="flex h-full flex-col justify-between">
          <div
            className="flex flex-col gap-5"
            style={{ height: "calc(100% - 150px)" }}
          >
            <Time
              className="bg-white"
              label={label}
              onNext={() => {
                if (moment().startOf("day").unix() > start) {
                  if (sessionIdFromParams) {
                    navigate(`/settings/reports/${tillIdFromParams}`);
                  }
                  handleNext();
                }
              }}
              onPrev={() => {
                if (sessionIdFromParams) {
                  navigate(`/settings/reports/${tillIdFromParams}`);
                }
                handlePrev();
              }}
            />
            <div className="flex flex-col gap-2.5 overflow-auto pb-2.5">
              {sessions?.map(({ startedAt, endedAt, id }) => (
                <Link key={id} to={id}>
                  <ReportSessionItem
                    endedAt={endedAt}
                    isActive={sessionIdFromParams === id}
                    startedAt={startedAt}
                    timezone={till?.timezone}
                  />
                </Link>
              ))}
            </div>
          </div>

          <div className="flex flex-col gap-2.5 border-t-2 border-divider-main pt-5">
            {/* Till report */}
            {sessionIdFromParams &&
            Boolean(sessions?.length) &&
            tillIdFromParams !== "combined" ? (
              <>
                <SendReportButton sessionId={sessionIdFromParams} />
                <PrintReportButton sessionId={sessionIdFromParams} />
              </>
            ) : null}
            {/* Combined report */}
            {sessionIdFromParams && tillIdFromParams === "combined" ? (
              <SendReportCombinedButton dateSeconds={sessionIdFromParams} />
            ) : null}
          </div>
        </div>
      </ReportsWrapper>

      <div className="relative size-full">
        <ReportsWrapper className="w-full overflow-y-auto scrollbar-hide">
          {sessionStatus === "pending" && !isCombined ? (
            <ReportStatus status="pending" />
          ) : null}

          {(sessionStatus === "success" &&
            sessions?.length &&
            sessions.some((session) => session.id === sessionIdFromParams)) ||
          isCombined ? (
            <Outlet />
          ) : (
            <ReportStatus status="empty" />
          )}
        </ReportsWrapper>

        <WrapperGradient />
      </div>
    </div>
  );
};

export { SessionTillsPage };
