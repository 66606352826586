type ProductsKeysListArgs = {
  tillId: string;
  user?: {
    childId: string | null;
    groupName: string | null;
  };
  filters?: {
    diningOption: string | null;
  };
};

export const productsKeys = {
  all: ["products"] as const,
  lists: () => [...productsKeys.all, "list"] as const,
  listsByTill: (tillId: string) => [...productsKeys.lists(), tillId] as const,
  list: ({ tillId, user, filters }: ProductsKeysListArgs) =>
    [
      ...productsKeys.listsByTill(tillId),
      user,
      ...(filters ? [filters] : [])
    ] as const,
  details: () => [...productsKeys.all, "detail"] as const,
  detail: (id: string) => [...productsKeys.details(), id] as const
};
