import { faArrowRight } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { T } from "@repo/transifex";

import { type LandingButtonProps, LandingButton } from "./landing-button";

type LandingBeginButtonProps = Omit<LandingButtonProps, "children">;

export const LandingBeginButton = (props: LandingBeginButtonProps) => {
  return (
    <LandingButton {...props}>
      <div className="flex justify-center gap-8 text-7xl text-kiosk-background-reversed">
        <p className="font-bold ">
          <T _str="Tap to begin" />
        </p>
        <FontAwesomeIcon icon={faArrowRight} />
      </div>
    </LandingButton>
  );
};
