import { createStore } from "zustand";
import { devtools } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";

import type { BasketStore } from "./store.types";

const INITIAL_STATE: BasketStore = {
  products: [],
  orderDiscount: { amount: 0, type: "percentage" },
  diningOption: null,
  isCardPaymentInProgress: false,
  shopperName: null
};

const basketStore = createStore<BasketStore>()(
  immer(devtools(() => INITIAL_STATE, { name: "basket-store-immer" }))
);

export { INITIAL_STATE, basketStore };
