import { initClient } from "@ts-rest/core";
import * as Sentry from "@sentry/react";

import { getAuthToken } from "~/providers/store/auth";
import { KANPLA_API_URL } from "~/utils/constants";

import { apiContract } from "./contract";

export const client = initClient(apiContract, {
  baseUrl: KANPLA_API_URL,
  baseHeaders: {},
  jsonQuery: true
});

export function getAuthHeader() {
  const activeSpan = Sentry.getActiveSpan();

  const rootSpan: Sentry.Span | undefined = activeSpan
    ? Sentry.getRootSpan(activeSpan)
    : undefined;

  const sentryTraceHeader: string | undefined = rootSpan
    ? Sentry.spanToTraceHeader(rootSpan)
    : undefined;

  const sentryBaggageHeader: string | undefined = rootSpan
    ? Sentry.spanToBaggageHeader(rootSpan)
    : undefined;

  const token = getAuthToken();

  if (!token) {
    throw new Error("No auth token");
  }

  const baseHeaders: {
    authorization: string;
    "ngrok-skip-browser-warning": string;
    "sentry-trace"?: string;
    baggage?: string;
  } = {
    authorization: `Bearer ${token}`,
    "ngrok-skip-browser-warning": "true"
  };

  if (sentryTraceHeader) {
    baseHeaders["sentry-trace"] = sentryTraceHeader;
  }

  if (sentryBaggageHeader) {
    baseHeaders.baggage = sentryBaggageHeader;
  }

  return baseHeaders;
}
