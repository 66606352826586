/* eslint-disable @typescript-eslint/prefer-promise-reject-errors -- isApiError fails otherwise */
import { createQueryKeys } from "@lukemorales/query-key-factory";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import type { ClientInferRequest } from "@ts-rest/core";
import { useSearchParams } from "react-router-dom";
import ShortUniqueId from "short-unique-id";

import type { Order } from "@repo/types";
import { useSendWebEvent } from "~/hooks";
import { useAppContext, useDeviceData } from "~/providers/app";
import { getAuth } from "~/providers/store/auth";
import { getIsCardPaymentInProgress } from "~/providers/store/basket";
import { useEmployee } from "~/providers/store/employee";
import { TimeoutError, timeout } from "~/utils/timeout";
import type { apiContract } from "~/api/contract";
import { client, getAuthHeader } from "~/api/client";
import { useCurrentTill } from "~/api/tills/hooks/use-till";
import { productsKeys } from "~/api/products/keys";

type GetRequest = ClientInferRequest<typeof apiContract.v1.pos.orders.get>;

export const ordersKeys = createQueryKeys("orders", {
  list: ({
    tillId,
    start,
    end,
    sessionId
  }: Pick<GetRequest["query"], "tillId" | "start" | "end" | "sessionId">) => ({
    queryKey: [{ tillId, start, end, sessionId }]
  }),
  detail: (id: string) => ({ queryKey: [id] })
});

function useOrders({
  tillId,
  start,
  end
}: Pick<GetRequest["query"], "tillId" | "start" | "end">) {
  return useQuery({
    ...ordersKeys.list({ tillId, start, end }),
    queryFn: async () => {
      const res = await client.v1.pos.orders.get({
        query: {
          tillId: tillId ?? undefined,
          start,
          end
        },
        headers: getAuthHeader()
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    enabled: Boolean(tillId),
    staleTime: 1000 * 30 // 30 seconds
  });
}

function useSessionOrders(sessionId: GetRequest["query"]["sessionId"]) {
  return useQuery({
    ...ordersKeys.list({ sessionId }),
    queryFn: async () => {
      const res = await client.v1.pos.orders.get({
        query: {
          sessionId
        },
        headers: getAuthHeader()
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    enabled: Boolean(sessionId)
  });
}

type CreateRequest = ClientInferRequest<
  typeof apiContract.v1.pos.orders.create
>;

function useOrderCreate() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      employeeUserId,
      employeeChildId,
      paidWithSaldoUnit,
      ...body
    }: Omit<
      CreateRequest["body"],
      "tillId" | "moduleId" | "schoolId" | "userId" | "childId" | "profileId"
    > & {
      employeeUserId: string | null;
      employeeChildId: string | null;
      paidWithSaldoUnit: number | null;
    }) => {
      const { tillId, moduleId, schoolId, childId, profileId, userId, status } =
        getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const res = await client.v1.pos.orders.create({
        body: {
          ...body,
          tillId,
          moduleId,
          schoolId,
          profileId,
          childId: employeeChildId ?? childId,
          userId: employeeUserId ?? userId,
          paidWithSaldoUnit
        },
        headers: getAuthHeader()
      });

      if (res.status === 201) {
        return res.body;
      }

      throw res;
    },
    onSuccess: (order, variables) => {
      void queryClient.setQueryData(
        ordersKeys.detail(order.id).queryKey,
        order
      );

      if (variables.employeeChildId) {
        const { tillId } = getAuth();

        void queryClient.invalidateQueries({
          queryKey: productsKeys.list({
            tillId: tillId ?? "unknown",
            user: {
              groupName: variables.groupName ?? null,
              childId: variables.employeeChildId ?? null
            }
          }),
          exact: false
        });
      }
    }
  });
}

function useOrder(id: string | null) {
  return useQuery({
    ...ordersKeys.detail(id || "empty"),
    queryFn: async () => {
      if (!id) {
        throw new Error("No order id provided");
      }

      const res = await client.v1.pos.orders.getById({
        params: { orderId: id },
        headers: getAuthHeader()
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    /** TEMPORARY-FIX: ensures we retry fetching the order (for the status) while there is an active card payment  */
    retry: (count) => {
      const isCardPaymentInProgress = getIsCardPaymentInProgress();

      if (isCardPaymentInProgress) {
        return true;
      }

      return count <= 5;
    },
    enabled: Boolean(id)
  });
}

function useOrderCancel() {
  return useMutation({
    mutationFn: async (id: string) => {
      const res = await client.v1.pos.orders.cancel({
        params: { orderId: id },
        headers: getAuthHeader()
      });

      if (res.status === 204) {
        return res.body;
      }

      throw res;
    }
  });
}

function useOrderEmail() {
  const { employeeUserId } = useEmployee();

  return useMutation({
    mutationFn: async ({
      orderId,
      email
    }: {
      orderId: string;
      email: string;
    }) => {
      const res = await client.v2.pos.orders.email({
        params: { orderId },
        body: { email, employeeUserId },
        headers: getAuthHeader()
      });

      if (res.status === 204) {
        return res.body;
      }

      throw res;
    }
  });
}

type UseOrderRefundProps = {
  orderId: string;
  refundMethod: "card" | "cash" | "billing" | "swish" | "credit";
  shouldGenerateServiceId?: boolean;
  refundOrderLines: OrderLinesToRefund[];
};

export type OrderLinesToRefund = {
  orderLineId: string;
  amount: number;
  unitPrice: number;
};

function useOrderRefund() {
  const queryClient = useQueryClient();
  const { os, osVersion } = useDeviceData();
  const [, setSearchParams] = useSearchParams();

  return useMutation({
    mutationFn: async ({
      orderId,
      refundMethod,
      shouldGenerateServiceId = false,
      refundOrderLines
    }: UseOrderRefundProps) => {
      const { tillId, profileId, status } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      if (!os || !osVersion) throw new Error("OS or OS Version not provided");

      let serviceId = "";
      if (refundMethod === "card" && shouldGenerateServiceId) {
        const uid = new ShortUniqueId({ length: 10 });
        serviceId = uid.rnd();
        setSearchParams({ serviceId });
      }

      const res = await client.v1.pos.orders.refund({
        params: { orderId },
        body: {
          tillId,
          profileId,
          refundMethod,
          deviceInfo: { os, osVersion },
          serviceId,
          refundOrderLines
        },
        headers: getAuthHeader()
      });

      if (res.status === 204) {
        return res.body;
      }

      throw res;
    },
    onSuccess: async (_, { orderId }) => {
      // invalidate list queries
      await queryClient.invalidateQueries({
        queryKey: ordersKeys.list._def
      });

      // invalidate detail query
      await queryClient.invalidateQueries({
        queryKey: ordersKeys.detail(orderId).queryKey
      });
    }
  });
}

function usePrintOrderReceipt() {
  const sendWebEvent = useSendWebEvent();
  const { employeeUserId } = useEmployee();
  const {
    hardware: { printer }
  } = useAppContext();

  return useMutation({
    mutationFn: async ({
      orderId
    }: {
      orderId: string;
      isTicket?: boolean;
    }) => {
      const { tillId, status } = getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      if (!printer) {
        throw new Error("Printer is not connected");
      }

      const res = await client.v2.pos.orders.receipt({
        params: { orderId },
        body: { employeeUserId, tillId },
        headers: getAuthHeader()
      });

      if (res.status === 201) {
        return res.body;
      }

      throw res;
    },
    onSuccess(receipt, { isTicket }) {
      sendWebEvent({
        type: isTicket
          ? "PRINTER_PRINT_TICKET_REQUEST"
          : "PRINTER_PRINT_RECEIPT_REQUEST",
        payload: receipt
      });
    }
  });
}

type CreateCardReimbursementRequest = ClientInferRequest<
  typeof apiContract.v1.pos.orders.reimbursement
>;

type UseOrderCardReimbursementCreateProps = {
  /**
   * If the timeout is reached, the transaction will be aborted.
   * @param args args needed to the abort mutation and cancel a transaction
   */
  abortTransactionCallback: (args: {
    orderId: string;
    serviceId: string;
    terminalId: string;
  }) => void;
};

function useOrderCardReimbursementCreate({
  abortTransactionCallback
}: UseOrderCardReimbursementCreateProps) {
  const queryClient = useQueryClient();
  const [, setSearchParams] = useSearchParams();

  const { data: till } = useCurrentTill();

  return useMutation({
    mutationFn: async ({
      ...body
    }: Omit<
      CreateCardReimbursementRequest["body"],
      | "tillId"
      | "moduleId"
      | "schoolId"
      | "userId"
      | "childId"
      | "profileId"
      | "serviceId"
    >) => {
      const { tillId, moduleId, schoolId, childId, profileId, userId, status } =
        getAuth();

      if (status !== "profile") {
        throw new Error("Profile is not selected");
      }

      const uid = new ShortUniqueId({ length: 10 });
      const serviceId = uid.rnd();

      // wait for the order to be created
      setTimeout(() => {
        setSearchParams({ serviceId, orderId: body.orderId });
      }, 1000);

      try {
        return await timeout(
          new Promise((resolve, reject) => {
            void client.v1.pos.orders
              .reimbursement({
                body: {
                  ...body,
                  tillId,
                  moduleId,
                  schoolId,
                  profileId,
                  childId,
                  userId,
                  serviceId
                },
                headers: getAuthHeader()
              })
              .then((res) => {
                if (res.status === 201) {
                  resolve(res.body);
                } else {
                  reject(res);
                }
              });
          }),
          { ms: 300000 } // 5 minutes
        );
      } catch (error) {
        if (error instanceof TimeoutError) {
          // if the timeout (5 minutes) is reached, abort the current transaction
          till?.terminalId &&
            abortTransactionCallback({
              serviceId,
              orderId: body.orderId,
              terminalId: till.terminalId
            });
        }
        throw error;
      }
    },
    onSuccess: (_, { orderId }) => {
      void queryClient.setQueryData(
        ordersKeys.detail(orderId).queryKey,
        (old: Order) => ({
          ...old,
          status: "paid"
        })
      );
    },
    onError: async (_, { orderId }) => {
      await queryClient.invalidateQueries({
        queryKey: ordersKeys.detail(orderId).queryKey
      });
    }
  });
}

export {
  useOrder,
  useOrderCancel,
  useOrderCardReimbursementCreate,
  useOrderCreate,
  useOrderEmail,
  useOrderRefund,
  useOrders,
  usePrintOrderReceipt,
  useSessionOrders
};
