import { clsx } from "clsx";

import { T } from "@repo/transifex";
import type { VariantGroupChoice, VariantGroup } from "@repo/types";

type VariantHeaderProps = {
  name: VariantGroup["name"];
  minimum: VariantGroup["minimum"];
  choices?: (VariantGroupChoice & { amount: number })[];
  isValid: boolean;
};

export const VariantHeader = ({
  name,
  minimum,
  isValid,
  choices = []
}: VariantHeaderProps) => {
  const variantName = name || "";

  const selectedChoices = choices.filter((choice) => choice.amount > 0);

  return (
    <div>
      {isValid && selectedChoices.length > 0 ? (
        <p className="py-5 text-xl text-text-primary">
          <T
            _str="Choose {variantName}: {variantValues}"
            variantName={variantName}
            variantValues={
              <span className="font-bold text-primary-main">
                {selectedChoices.length > 1
                  ? selectedChoices
                      .map((choice) => `${choice.amount} x ${choice.name}`)
                      .join(", ")
                  : `${
                      selectedChoices[0].amount > 1
                        ? `${selectedChoices[0].amount} x `
                        : ""
                    }${selectedChoices[0].name}`}
              </span>
            }
          />
        </p>
      ) : (
        <div className="flex items-center gap-3 py-5">
          <p
            className={clsx(
              {
                "before:content-['*'] before:text-danger-main":
                  minimum && minimum > 0
              },
              "text-xl text-text-primary"
            )}
          >
            <T _str="Choose {variantName}" variantName={variantName} />
          </p>

          {minimum ? (
            <p className="text-sm text-text-disabled">
              <T _str="Required, choose {minimum}" minimum={minimum} />
            </p>
          ) : (
            <p className="text-sm text-text-disabled">
              <T _str="optional" />
            </p>
          )}
        </div>
      )}
    </div>
  );
};
