import { clsx } from "clsx";

import { ProductPageHeader } from "./product-page-header";

export const Skeleton = () => {
  return (
    <div className="w-full bg-white">
      <ProductPageHeader />
      <SkeletonCategoryBreadcrumbs count={4} />
      <SkeletonProductsGrid count={3} className="mt-12" />
    </div>
  );
};

const SkeletonCategoryBreadcrumbs = ({ count }: { count: number }) => {
  return (
    <div className="flex w-full gap-8 border-b border-gray-200 px-8">
      {Array.from({ length: count }).map((_, index) => (
        <div
          // eslint-disable-next-line react/no-array-index-key -- just skeleton
          key={index}
          className="h-7 w-full animate-pulse rounded-full bg-[#D9D9D9]"
        />
      ))}
    </div>
  );
};

const SkeletonProductCard = () => {
  return (
    <div className="flex flex-col">
      <div className="mb-8 h-56 w-full animate-pulse rounded-3xl bg-[#D9D9D9]" />
      <div className="mb-4 h-7 w-full animate-pulse rounded-full bg-[#D9D9D9]" />
      <div className="h-7 w-1/3 animate-pulse rounded-full bg-[#D9D9D9]" />
    </div>
  );
};

const SkeletonProductsGrid = ({
  count,
  className
}: {
  count: number;
  className: string;
}) => {
  return (
    <div className={clsx("flex flex-col gap-8 px-8", className)}>
      {Array.from({ length: count }).map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key -- just skeleton
        <div key={index} className="mb-4">
          <div className="mb-4 h-7 w-1/3 animate-pulse rounded-full bg-[#D9D9D9]" />

          <div className="grid grid-cols-3 gap-8">
            <SkeletonProductCard />
            <SkeletonProductCard />
            <SkeletonProductCard />
          </div>
        </div>
      ))}
    </div>
  );
};
