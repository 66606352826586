import { clsx } from "clsx";
import { type PropsWithChildren } from "react";

export type LandingButtonProps = PropsWithChildren & {
  onClick: () => void;
  className?: string;
};

export const LandingButton = ({
  children,
  onClick,
  className
}: LandingButtonProps) => {
  return (
    <button
      type="button"
      className={clsx(
        "size-full h-96 rounded-3xl bg-kiosk-accent-primary px-16 text-white",
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};
