import { clsx } from "clsx";

type ReportsWrapperProps = {
  children: React.ReactNode;
  className?: string;
};

export const ReportsWrapper = ({
  children,
  className
}: ReportsWrapperProps) => {
  return (
    <div
      className={clsx(
        "h-full rounded-md bg-background-secondary p-5",
        className
      )}
    >
      {children}
    </div>
  );
};

export const WrapperGradient = () => (
  <div className="absolute bottom-0 right-0 h-fit w-full">
    <div
      className="absolute bottom-0 h-[60px] w-full rounded-b-md"
      style={{
        background:
          "linear-gradient(180deg, rgba(242, 245, 249, 0.00) 0%, #F2F5F9 100%)"
      }}
    />
  </div>
);
