import type {
  AllowanceDetail,
  HybridBillingDiscount,
  ProductDiscount
} from "@kanpla/api-contract";

import { round } from "@repo/system";
import { type PosProduct } from "@repo/types";

type BasicProduct = {
  discount: ProductDiscount | null;
  unitPrice: PosProduct["unitPrice"];
};

type GetHybridBillingProductDiscountArgs = {
  product: BasicProduct;
  discounts: HybridBillingDiscount[];
  allowances: AllowanceDetail[];
};

export const getHybridBillingProductDiscount = ({
  product,
  discounts,
  allowances
}: GetHybridBillingProductDiscountArgs): PosProduct["discount"] => {
  const { discount: productDiscount, unitPrice } = product;
  if (!productDiscount?.id) return null;

  // hybridBilling [ALWAYS HIDDEN]
  if (productDiscount.type === "hybridBilling") {
    return {
      id: productDiscount.id,
      name: "",
      percentage: 0,
      amount: 0,
      type: "hybridBilling",
      allowanceType: null,
      isAllowanceOver: false
    };
  }

  const eligibleDiscount = discounts.find(
    ({ id }) => id === productDiscount.id
  );
  if (!eligibleDiscount) return null;

  const allowance = productDiscount.allowanceType
    ? allowances.find(({ discountId }) => discountId === productDiscount.id)
    : null;

  /**
   * discount-hybridBilling with no allowance [ALWAYS VISIBLE]
   * discount-hybridBilling with number of products allowance [ALWAYS VISIBLE]
   * discount-hybridBilling with unitPrice allowance [HIDDEN in PRODUCT, VISIBLE in BASKET]
   */
  return {
    id: eligibleDiscount.id,
    name: eligibleDiscount.name,
    percentage: eligibleDiscount.amount,
    amount: round((unitPrice * eligibleDiscount.amount) / 100, 0),
    type: "discount-hybridBilling",
    allowanceType: productDiscount.allowanceType ?? null,
    isAllowanceOver: allowance?.isAllowanceOver ?? false
  };
};
