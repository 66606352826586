import { Link } from "react-router-dom";
import { faCircleQuestion } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { KanplaLogo } from "~/components";
import { T } from "@repo/transifex";

export const MenuFooter = () => {
  return (
    <div className="flex h-20 w-full items-center justify-between border-t border-divider-main px-5 text-white">
      <div className="w-24">
        <KanplaLogo className="fill-white" />
      </div>

      <Link to="/support">
        <button className="flex items-center gap-2.5" type="button">
          <FontAwesomeIcon icon={faCircleQuestion} size="xl" />
          <T _str="Need help?" />
        </button>
      </Link>

      <p className="text-xs">
        <T _str="All rights reserved ®" />
      </p>
    </div>
  );
};
